import React, { ReactElement, useEffect, useState, useRef } from 'react';

import {
    Container,
    Input
} from "./input.styles"
import { Label, ErrorTag } from "../shared.styles";
import {
    handleInputChange,
    validateInput
} from "../form-validation"
import {
    Form
} from '@declarations';

type Props = {
    id: string
    name: Form.Field;
    type: string
    value?: string | number | undefined;
    minValue?: number
    maxValue?: number
    step?: string
    placeholder: string;
    validations?: Form.Validation[];
    onValueChange: (name: Form.Field, value: string | number | undefined) => void;
    onValidChange?: (valid: boolean) => void;
    onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    containerStyle?: Record<string, unknown>;
    inputStyle?: Record<string, unknown>;
    disabled?: boolean;
    onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ValidatedInput = (props: Props): ReactElement => {

    const { id, name, value, minValue, maxValue, step, placeholder, validations, onValueChange, onKeyUp, onValidChange, containerStyle, inputStyle, disabled, onSearchChange, type } = props;

    const addressSearchField = useRef<HTMLInputElement>(null)

    const [data, setData] = useState<Form.Input>({
        name: name,
        value: value,
        validations: validations,
        errors: [],
        dirty: false
    });

    // if value updates from outside source, e.g., Google addresses, then update local state
    useEffect(() => {
        setData(data => ({
            ...data,
            value: value,
        }))
    }, [value])

    const [valid, setValid] = useState<boolean>(false);

    useEffect(() => {
        onValueChange(data.name, data.value)
        if (data.validations) {
            const validation = validateInput(data);
            if (validation !== valid) setValid(validation);
        }
    }, [data])

    useEffect(() => {
        if (validations && validations.length && onValidChange) {
            onValidChange(valid);
        }
    }, [valid])

    // check if the input box is empty, if so, make sure appropriate error message is shown when blurred
    useEffect(() => {
        if (data.dirty === true) {
           const errors: string[] = [];
           const value = data.value || '';
           if (validations) {
                for (const v of validations) {
                    const valid = v.func(value);
                    if (!valid) errors.push(v.message);
                }
            }
           setData(data => ({
               ...data,
               errors: errors
           }))
        } 
   }, [data.dirty, data.value])

    const [focus, setFocus] = useState<boolean>(false);

    const handleDirty = () => {
        setData((data: Form.Input) => ({
            ...data,
            dirty: true
        }))
        setFocus(false);
    }

    const handleFocus = () => {
        setData((data: Form.Input) => ({
            ...data,
            dirty: false
        }))
        setFocus(true);
    };

    return (
        <Container
            style={containerStyle}
        >
            <Input
                id={id}
                name={data.name}
                onChange={(e) => { handleInputChange(data, setData, e); onSearchChange && onSearchChange(e) }}
                onKeyUp={onKeyUp}
                onFocus={handleFocus}
                onBlur={handleDirty}
                value={data.value}
                errors={data.errors}
                dirty={data.dirty}
                focus={focus}
                style={inputStyle}
                disabled={disabled}
                ref={addressSearchField}
                type={type}
                step={step}
                min={minValue}
                max={maxValue}
            />
            <Label
                value={data.value}
                focus={focus}
                htmlFor={id}
            >
                {placeholder}
            </Label>
            {/* display list of all errors */}
            {/* {data.dirty && data.errors.map((e: string, index: number) => <ErrorTag key={`${name}-error-${index}`}>{e}</ErrorTag>)} */}

            {/* display first error */}
            {data.dirty && data.errors?.length ? <ErrorTag>{data.errors[0]}</ErrorTag> : null}

        </Container>
    )
}

export default ValidatedInput;