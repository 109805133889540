import styled from "styled-components";

export const Bar = styled.div`

    height: 8px;
    background: ${({ theme }) => theme.colors.progressBarIncomplete};
    position: relative;

`

type PbarProps = {
    width: string
}

export const Pbar = styled.div<PbarProps>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ width }) => width};
    background: ${({ theme }) => theme.colors.progressBarComplete};

`