export const getXDaysAgo = (date: Date, ago: number): Date => {
    const d = new Date().setDate(date.getDate() - ago)
    return new Date(d)
}

export const formatDate = (d: Date): string => {

    const day = d.getDate()

    const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'short' })
    const month = monthFormatter.format(d)

    const year = d.getFullYear()

    return `${month}. ${day}, ${year}`

}

export const formatDateMonthDate = (d: number): string => {
    const date = new Date(d)

    const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'short' })
    const month = monthFormatter.format(d)

    const day = date.getDate()

    return `${month} ${day}`
}

export const formatTimeHHMMSS = (seconds: number): string => {
    return new Date(1000 * seconds).toISOString().substr(11, 8)
}

export const formatTimestamp = (date: string | Date) => {

    const dateFormat = 'en-US'

    const options = { hour: 'numeric', minute: 'numeric' } as const;
    const d = new Date(date).toLocaleString(dateFormat, options);
    return d;
}

