import { AdSchema, AppState, ArPlacement, CustomArButton, Form, Model, PageProps, RotateDirection } from "@declarations"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { store } from "Store/store"
import { setAdBackground, setAdDimensions, setAdModel, setAdAdjustments } from "Store/Actions/AdFormActions"
import React, { useState } from "react"
import AdFormPageWrapper from "Components/AdForm/Page/ad-form-page-wrapper.component"
import AdFormHeader from "Components/AdForm/Header/ad-form-header.component"
import AdFormFooter from "Components/AdForm/Footer/ad-form-footer.component"
import StepButton from "Components/AdForm/StepButton/step-button.component"
import { AdForm, Links, URLs } from "Values/Constants"
import AdFormBody from "Components/AdForm/Body/ad-form-body.component"
import { FieldWrapper } from "Styles/library/forms"
import ValidatedInput from "Components/Forms/Input/input.component"
import { required, FormValidator, minValue, maxLength } from "Components/Forms/form-validation"
import DimensionTile from 'Components/AdForm/DimensionTile/dimension-tile.component';
import { PresetWrapper } from "Components/AdForm/DimensionTile/dimension-tile.styles"
import { FileInputDivider } from "Components/Forms/FileInput/file-input.styles"
import { useAuth0 } from "@auth0/auth0-react"
import { updateAd } from "Api/Api/ads"
import { makeAction, Actions } from "Store/Actions/ActionTypes"
import { dimensionPresets as presets } from "Data/dimension-presets"
import Modal from "Components/Modals/Modal/modal.component"
import { centerModelCoordinates, getDefaultModelDimensions } from "Utils/dimensions"
import Progress from "../../Components/AdForm/Progress/progress.component"
import { DefaultValues } from "Values/Constants";

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    previousStep: string
    nextStep: string
}

const CURRENT_STEP = 2

const AdFormDimensions = (props: Props) => {

    const { adForm, updateDimensions, updateBackground, updateModel, updateAdjustments, previousStep, nextStep, clearAdForm } = props

    const [dimensions, setDimensions] = useState<Form.Data>({
        width: adForm.width,
        height: adForm.height
    })

    // Form helpers
    const handleValueChange = (name: Form.Field, value: string | number | undefined) => {
        if (value) {
            setDimensions(data => ({
                ...data,
                [name]: value
            }))
        }
    }

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (disabled) return;
        if (e.key === "Enter") submitProgress()
    }

    const [disabled, setDisabled] = useState<boolean>(true);

    const handleValidChange = FormValidator(setDisabled);
    // end of form helpers

    const history = useHistory();

    const { getAccessTokenSilently } = useAuth0();

    const goToAd = () => {

        if (adForm._id) {
            clearAdForm()
            history.push(Links.ad.constructor(adForm._id))
        }
    }

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [resultAd, setResultAd] = useState<AdSchema>()

    const saveProgress = async () => {
        setDisabled(true)
        try {
            setShowErrorModal(false)
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const update: Partial<Record<keyof AdSchema, any>> = {
                adWidth: dimensions.width,
                adHeight: dimensions.height,
            }

            // if resetting dimensions, then reset bg
            if (dimensions.width !== adForm.width || dimensions.height !== adForm.height) {
                update["creativeBg"] = ""
                update["expwidth"] = getDefaultModelDimensions(dimensions.width as number, dimensions.height as number)
                update["expheight"] = getDefaultModelDimensions(dimensions.width as number, dimensions.height as number)
                update["exptop"] = centerModelCoordinates(dimensions.width as number, dimensions.height as number).y
                update["expleft"] = centerModelCoordinates(dimensions.width as number, adForm.height as number).x
            }

            if (!adForm._id) return
            const res = await updateAd(adForm._id, update, accessToken)
            console.log("SUCCESSFUL UPDATE ", res)
            setResultAd(res.data.data as AdSchema)
            setShowSuccessModal(true)
        } catch (e) {
            console.error("Error updating banner", e)
            setShowErrorModal(true)
        } finally {
            setDisabled(false)
        }
    }

    const submitProgress = async () => {

        // if resetting dimensions, then reset bg and model size and locations
        if (dimensions.width !== adForm.width || dimensions.height !== adForm.height) {
            await updateBackground("", false)
            const modelW = getDefaultModelDimensions(dimensions.width as number, dimensions.height as number)
            const modelH = getDefaultModelDimensions(dimensions.width as number, dimensions.height as number)
            const modelX = centerModelCoordinates(dimensions.width as number, dimensions.height as number).x
            const modelY = centerModelCoordinates(dimensions.width as number, dimensions.height as number).y

            await updateModel(
                modelW,
                modelH,
                adForm.model,
                modelX,
                modelY
            )

            await updateAdjustments(
                0,
                0,
                0,
                0,
                105,
                90,
                0,
                false,
                true,
                1,
                1,
                true,
                5,
                "left",
                false,
                0,
                0,
                "image",
                DefaultValues.customButtonLabel,
                "On the floor",
                true,
                "Model",
                DefaultValues.customBannerSubTitle,
                DefaultValues.customBannerCtaLabel
            )
        }

        await updateDimensions(dimensions.width as number, dimensions.height as number)

        history.push(nextStep)
    }

    const goBack = async () => {
        history.push(previousStep)
    }

    const handlePresetSelect = (width: number, height: number) => {
        const stringWidth = width.toString();
        const stringHeight = height.toString();
        setDimensions({ width: stringWidth, height: stringHeight })
    }

    /*const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        const split = value.split("x")
        const width = split[0]
        const height = split[1]

        if (width && height) {
            setDimensions({ width: Number(width), height: Number(height) })
        } else {
            setDimensions({ width: 0, height: 0 })
        }
    }*/

    return (
        <AdFormPageWrapper>
            <div className="headerScroll">
                <AdFormHeader
                    currentStep={CURRENT_STEP}
                    totalSteps={AdForm.totalSteps}
                    title="Select your banner size"
                    updateTitle="Update your banner size"
                    subtitle="Set the height and width of your banner"
                    nextTitle="Adding your background image"
                    stepOneCheck={2}
                    stepTwoCheck={1}
                    updating={adForm.updating}
                    id={adForm._id}
                />
                <Progress currentStep={CURRENT_STEP} totalSteps={AdForm.totalSteps} updating={adForm.updating} />
            </div>
            <AdFormBody>
                <h4>Enter a custom banner dimension</h4>
                <FieldWrapper>
                    <ValidatedInput
                        id="width"
                        name="width"
                        placeholder="Width"
                        value={dimensions.width && dimensions.width >= 0 ? dimensions.width : ""}
                        minValue={0}
                        validations={[required("Width"), minValue(0, "Width"), maxLength(255)]}
                        onValueChange={handleValueChange}
                        onValidChange={handleValidChange}
                        onKeyUp={onKeyUp}
                        type="number"
                    />
                    <ValidatedInput
                        id="height"
                        name="height"
                        placeholder="Height"
                        value={dimensions.height && dimensions.height >= 0 ? dimensions.height : ""}
                        minValue={0}
                        validations={[required("Height"), minValue(0, "Height"), maxLength(255)]}
                        onValueChange={handleValueChange}
                        onValidChange={handleValidChange}
                        onKeyUp={onKeyUp}
                        type="number"
                    />
                </FieldWrapper>
                <br />
                <FileInputDivider style={{ maxWidth: "450px", marginTop: "10px", marginBottom: "10px" }}>
                    <hr />
                    <p>or</p>
                    <hr />
                </FileInputDivider>
                <br />
                <h4>Select one of the following presets:</h4>
                <PresetWrapper>
                    {presets.map(p => (
                        <DimensionTile
                            key={p.title}
                            img={p.img}
                            title={p.title}
                            width={p.width}
                            height={p.height}
                            handleSelectDimensions={handlePresetSelect}
                            selectedDimensions={{
                                width: dimensions.width as number,
                                height: dimensions.height as number
                            }}
                        />
                    ))}
                </PresetWrapper>
            </AdFormBody>
            <div className="footerScroll">
                <AdFormFooter>
                    {!adForm.updating ? <StepButton
                        text="Back"
                        callback={goBack}
                        variant="back"
                    /> : <div></div>}
                    <StepButton
                        text={adForm.updating ? "Update" : "Continue"}
                        callback={adForm.updating ? saveProgress : submitProgress}
                        variant="primary"
                        disabled={disabled}
                    />
                </AdFormFooter>
            </div>
            {showSuccessModal
                && <Modal
                    title="Success! You've updated your banner."
                    text={`You can access ${resultAd?.adName} in My Banners. If you have updated the dimensions for your banner, then you will need to select a new background image and update the embed code in your DSP. Also your model size and positioning will be updated to center your model within the new banner dimensions.`}
                    callback={goToAd}
                    callbackText={"Go to My Banner"}
                    handleClose={goToAd}
                    ad={resultAd}
                />}
            {showErrorModal && <Modal
                title="Sorry! Something went wrong."
                text={`Something has gone wrong along the way, and your banner hasn’t been updated. Please try again.`}
                callback={saveProgress}
                callbackText={"Try Again"}
                handleClose={() => setShowErrorModal(false)}
                showGoBack={true}
            />}
        </AdFormPageWrapper>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        adForm: state.adForm
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        updateDimensions: async (width: number, height: number) => dispatch(setAdDimensions(width, height)),
        updateBackground: async (background: string, transparent: boolean) => dispatch(setAdBackground(background, transparent)),
        updateModel: async (width: number, height: number, model: Model, modelX: number, modelY: number) => dispatch(setAdModel(width, height, model, modelX, modelY)),
        updateAdjustments: async (
            modelWidth: number,
            modelHeight: number,
            xCoordinate: number,
            yCoordinate: number,
            cameraDistance: number,
            cameraVerticalAngle: number,
            cameraHorizontalAngle: number,
            zooming: boolean,
            shadows: boolean,
            shadowIntensity: number,
            shadowEdgeSoftness: number,
            autoRotate: boolean,
            autoRotateSpeed: number,
            autoRotateDirection: RotateDirection,
            viewInArButton: boolean,
            viewInArButtonXCoordinate: number,
            viewInArButtonYCoordinate: number,
            customButton: CustomArButton,
            customButtonLabel: string,
            arPlacement: ArPlacement,
            customBanner: boolean,
            customBannerTitle: string,
            customBannerSubtitle: string,
            customBannerCtaLabel: string

        ) => dispatch(setAdAdjustments(
            modelWidth,
            modelHeight,
            xCoordinate,
            yCoordinate,
            cameraDistance,
            cameraVerticalAngle,
            cameraHorizontalAngle,
            zooming,
            shadows,
            shadowIntensity,
            shadowEdgeSoftness,
            autoRotate,
            autoRotateSpeed,
            autoRotateDirection,
            viewInArButton,
            viewInArButtonXCoordinate,
            viewInArButtonYCoordinate,
            customButton,
            customButtonLabel,
            arPlacement,
            customBanner,
            customBannerTitle,
            customBannerSubtitle,
            customBannerCtaLabel
        )),
        clearAdForm: async () => dispatch(makeAction(Actions.CLEAR_AD_FORM)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdFormDimensions)