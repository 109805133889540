import toolTipQ from "Assets/images/icons/tooltip-q.svg"
import { useState } from "react"
import "./Tooltip.css"

type Props = {
    text?: string
    right?: boolean
    center?: boolean
    under?: boolean
}

const Tooltip = (props: Props) => {

    const { text, right, center, under } = props

    const [hovering, setHovering] = useState<boolean>(false)

    if (center) {
        return (
            <div className="my-tooltip-wrapper-normal">
                <img
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    src={toolTipQ} alt="tooltip" />
                {hovering &&
                    <div className="my-tooltip-normal">
                        <p>{text}</p>
                    </div>
                }
            </div>
        )
    }

    if (right) {
        return (
            <div className="my-tooltip-wrapper-right">
                <img
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    src={toolTipQ} alt="tooltip" />
                {hovering &&
                    <div className="my-tooltip-right">
                        <p>{text}</p>
                    </div>
                }
            </div>
        )
    }

    if (under) {
        return (
            <div className="my-tooltip-wrapper-under">
                <img
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    src={toolTipQ} alt="tooltip" />
                {hovering &&
                    <div className="my-tooltip-under">
                        <p>{text}</p>
                    </div>
                }
            </div>
        )
    }

    return (
        <div className="my-tooltip-wrapper">
            <img
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                src={toolTipQ} alt="tooltip" />
            {hovering &&
                <div className="my-tooltip">
                    <p>{text}</p>
                </div>
            }
        </div>
    )

}

export default Tooltip