import styled from "styled-components";

export const Page = styled.div`
    min-height: 100vh;
    display: grid;
    grid-template-areas: 
        "header"
        "body"
        "footer";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;

    .headerScroll {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 100;
    }

    .footerScroll {
        position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        z-index: 100;
        background: white;
    }
`