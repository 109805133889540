import styled from "styled-components";

export const Layout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @media screen and (max-width: 336px) {
        align-items: stretch;
        flex-wrap: wrap;
    }
`

export const LayoutBody = styled.div`
    flex: 1;
`
export const ContentBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 24px 108px;

    @media screen and (max-width: 1000px) {
        padding: 24px 24px;
    }
    & > * {
        margin-bottom: 32px;
    }
`

export const ContentHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
    }

    .actions {
        display: flex;
        align-items: center;

        & > * {
            margin-left: 22px;
        }
    }
`