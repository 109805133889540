import styled, { css } from "styled-components";

export const FlexWrappedPage = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    & > * {
        margin: 24px;
    }
    padding: 24px;
`

type TwoColumnPageProps = {
    split: "50/50" | "20/80"
}

export const TwoColumnPage = styled.div<TwoColumnPageProps>`
    width: 100%;
    display: grid;
    grid-gap: 24px;
    grid-template-rows: 1fr;
    ${({ split }) => {
        switch (split) {
            case "50/50":
                return css`
                    grid-template-columsn: 1fr 1fr;
            `
            case "20/80":
                return css`
                    grid-template-columns: auto 1fr;        
            `
        }
    }}
    padding: 108px;


    @media screen and (max-width: 1000px) {
        padding: 24px;
    }
`