import { InputsContainer, Slider, SliderContainer, SliderTitle, TextInput } from "./slider-input.styles"
import Tooltip from "Components/Tooltip/Tooltip"
import "./slider-input-component.css"

type Props = {
    min: number
    max: number
    step: string
    title: string
    tooltip?: string
    right?: boolean
    center?: boolean
    value: number
    sendValue: (val: number) => void
    containerStyle?: Record<string, string | number>
}

const SliderInput = (props: Props) => {

    const { min, max, step, title, tooltip, right, center, value, sendValue, containerStyle } = props

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = Number(e.currentTarget.value)
        sendValue(val)
    }

    const bg = (value + Math.abs(min)) / (max + Math.abs(min)) * 100

    return (
        <SliderContainer style={containerStyle}>
            <SliderTitle>{title}</SliderTitle>
            <InputsContainer>
                <Slider
                    type="range"
                    step={step}
                    min={min}
                    max={max}
                    onChange={handleValueChange}
                    value={value}
                    style={{ backgroundSize: `${bg}% 100%` }}
                />
                <div className="title-wrapper">
                    <TextInput
                        type="number"
                        step={step}
                        min={min}
                        max={max}
                        value={value}
                        onChange={handleValueChange}
                    />
                    {tooltip && <Tooltip text={tooltip} right={right} center={center} />}
                </div>
            </InputsContainer>
        </SliderContainer>
    )
}

export default SliderInput