import styled from 'styled-components';

type InputProps = {
    errors?: string[];
    matchErrors?: string[];
    dirty: boolean | undefined;
    focus: boolean;
    value: string | number | boolean | undefined;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position:relative;
    margin: 0.5rem 0;
    min-height: 3.875rem;
`;

export const Input = styled.input<InputProps>`
    color: ${({ errors, dirty, theme }) => errors && errors.length && dirty ? theme.colors.inputTextError : theme.colors.inputTextBase};
    border: ${({ errors, matchErrors, dirty, focus, theme }) => {
        if (focus) return theme.colors.inputBorderFocus;
        else if (((errors && errors.length) || (matchErrors && matchErrors.length)) && dirty) return theme.colors.inputBorderError;
        else return theme.colors.inputBorderBase;
    }};
    background: ${({ focus, value, theme }) => focus || value ? theme.colors.inputBackgroundActive : theme.colors.inputBackgroundInactive};
    border-radius: ${({ theme }) => theme.shapes.borderRadiusPrimary};
    padding: ${({ focus, value }) => focus || value ? '1.25rem 1rem 0rem' : '1rem'};

    font: ${({ theme }) => theme.fonts.body1Medium};
    height: 3.875rem;

    &:focus {
        outline: none;
        border: ${({ theme }) => theme.colors.inputBorderFocus};
        background: ${({ theme }) => theme.colors.inputBackgroundDirty};
    }

    // hide arrows if number type
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    // /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
`;
