import React from "react"
import { Button } from "Styles/library/button"
import { BottomActions, ModalBox, ModalInstructions, ModalTitle, ModalWrapper } from "Styles/library/modal"
import PhoneFrame from 'Assets/images/forms/phone-frame.svg'
import { Banner, PhoneCanvas } from "./banner-modal.styles"

type Props = {
    callback: () => void
    handleClose: () => void
    title: string
    subtitle: string
    ctaLabel: string
    buttonEnabled: boolean
}

const BannerModal = (props: Props) => {

    const { callback, handleClose, title, subtitle, ctaLabel, buttonEnabled } = props

    const handleOutsideClose = (e: React.MouseEvent<HTMLElement>) => {
        const id = e.currentTarget.id
        if (id === "modal-wrapper") {
            handleClose()
        }
    }

    const infoText = "The banner below will appear when users are viewing your model in Augmented Reality"

    return (
        <ModalWrapper id="modal-wrapper" onClick={handleOutsideClose}>
            <ModalBox onClick={e => e.stopPropagation()}>
                <ModalTitle>
                    Banner
                </ModalTitle>
                <ModalInstructions style={{ maxWidth: '400px', textAlign: 'center' }}>
                    {infoText}
                </ModalInstructions>
                <br />
                <PhoneCanvas>
                    <img src={PhoneFrame} alt="phone" />
                    <Banner>
                        <h5>{title}</h5>
                        <p>{subtitle}</p>
                        {buttonEnabled && <button>{ctaLabel}</button>}
                    </Banner>
                </PhoneCanvas>
                <BottomActions style={{ marginTop: '12px' }}>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="primary" onClick={callback}>Confirm</Button>
                </BottomActions>
            </ModalBox>
        </ModalWrapper>
    )
}

export default BannerModal