import { AdSchema, AppState, AuthToken, Model, PageProps } from "@declarations"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { store } from "Store/store"
import { setAdModel, } from "Store/Actions/AdFormActions"
import React, { useCallback, useEffect, useState } from "react"
import AdFormPageWrapper from "Components/AdForm/Page/ad-form-page-wrapper.component"
import AdFormHeader from "Components/AdForm/Header/ad-form-header.component"
import AdFormFooter from "Components/AdForm/Footer/ad-form-footer.component"
import StepButton from "Components/AdForm/StepButton/step-button.component"
import { AdForm, Links, URLs } from "Values/Constants"
import AdFormBody from "Components/AdForm/Body/ad-form-body.component"
import ImageViewer from "Components/ImageViewer/image-viewer.component"
import { FlexWrappedPage } from "Styles/library/page"
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode"
import { ModelListContainer, ModelListItem, ModelsWrapper, ResultsText, SearchInput } from "Components/AdForm/Shared/side-form.styles"
import { centerModelCoordinates, getDefaultModelDimensions } from "Utils/dimensions"
import { PreviewContainer } from "Components/ImageViewer/image-viewer.styles"
import { Button } from "Styles/library/button"
import { updateAd } from "Api/Api/ads"
import { makeAction, Actions } from "Store/Actions/ActionTypes"
import _debounce from 'lodash/debounce';
import Modal from "Components/Modals/Modal/modal.component"
import { DivBox } from "Components/AdForm/Body/ad-form-body.styles"
import Progress from "../../Components/AdForm/Progress/progress.component"
import CircularProgress from '@bit/mui-org.material-ui.circular-progress';

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    previousStep: string
    nextStep: string
}

const CURRENT_STEP = 4

const AdFormModel = (props: Props) => {

    const { updateModel, previousStep, nextStep, adForm, clearAdForm } = props

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [selectedModel, setSelectedModel] = useState<any>(adForm.model)
    const [models, setModels] = useState<any[]>([])
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false)
    // const [filteredModels, setFilteredModels] = useState<any[]>([])

    const [disabled, setDisabled] = useState<boolean>(false);

    const [search, setSearch] = useState<string>("")

    const [searchType, setSearchType] = useState<"title" | "sku" | "category" | "tag">("title")

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value

        setSearch(val)
        debouncedTriggerSearch()
    }

    const triggerSearch = () => {
        setModels([])
        setPageNum(1)
        setTotalPageCount(0)
        setPagesQueried([])
    }

    const debouncedTriggerSearch = useCallback(_debounce(triggerSearch, 500), [])

    // useEffect(() => {
    //     const f = models.filter(m => m.title?.toLowerCase().includes(search.toLowerCase()) || m.sku?.toLowerCase().includes(search.toLowerCase()))
    //     setFilteredModels(f)
    // }, [models, search])


    const history = useHistory();

    const goToAd = () => {

        if (adForm._id) {
            clearAdForm()
            history.push(Links.ad.constructor(adForm._id))
        }
    }

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [resultAd, setResultAd] = useState<AdSchema>()

    const saveProgress = async () => {
        try {
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const update: Partial<Record<keyof AdSchema, any>> = {
                usdzLoc: selectedModel.models.usdz.url,
                glbLoc: selectedModel.models.glb.url,
                threedyModelName: selectedModel.title,
                threedyModelSKU: selectedModel.sku,
                consumerClientId: selectedModel.client_category_id
            }

            if (!adForm._id) return
            const res = await updateAd(adForm._id, update, accessToken)
            console.log("SUCCESSFUL UPDATE ", res)
            setResultAd(res.data.data as AdSchema)
            setShowSuccessModal(true)
        } catch (e) {
            console.error("Error updating ad", e)
            setShowErrorModal(true)
        }
    }

    const submitProgress = async () => {
        const modelW = adForm.modelWidth || getDefaultModelDimensions(adForm.width, adForm.height)
        const modelH = adForm.modelHeight || getDefaultModelDimensions(adForm.width, adForm.height)
        const modelX = adForm.modelXCoordinate || centerModelCoordinates(adForm.width, adForm.height).x
        const modelY = adForm.modelYCoordinate || centerModelCoordinates(adForm.width, adForm.height).y

        updateModel(
            modelW,
            modelH,
            selectedModel,
            modelX,
            modelY)
        history.push(nextStep)
    }

    const goBack = async () => {
        history.push(previousStep)
    }

    const [pageNum, setPageNum] = useState<number>(1);
    const [pagesQueried, setPagesQueried] = useState<any[]>([])
    const [limit, setLimit] = useState<number>(25)
    const [totalPageCount, setTotalPageCount] = useState<number>(0)

    useEffect(() => {

        const getModels = async () => {

            // don't repeat requests
            if (pagesQueried.length && pagesQueried.find((p: any) => p.pageNum === pageNum && p.limit === limit)) {
                return
            }
            try {
                setLoadingSearch(true)
                setPagesQueried(p => [...p, { pageNum, limit }])
                const accessToken = await getAccessTokenSilently({
                    audience: URLs.threedyAuth0Audience,
                    scope: "read:current_user",
                });

                const tkn: AuthToken = jwtDecode(accessToken)
                const isAdmin = tkn["https://thdy/app_md"].groups.includes("admin") && !tkn["https://thdy/app_md"].client_id
                const clientId = tkn["https://thdy/app_md"].client_id || null

                const offset = (pageNum - 1) * limit
                let url = ""

                let searchQuery = ""
                if (search) {
                    switch (searchType) {
                        case "title":
                            searchQuery += `&title=${search}`
                            break
                        case "sku":
                            searchQuery += `&sku=${search}`
                            break
                        case "category":
                            searchQuery += `&category=${search}`
                            break
                        case "tag":
                            searchQuery += `&tag=${search}`
                            break
                        default:
                            searchQuery += ""
                    }
                }

                if (isAdmin) {
                    // status=16 means model was approved – always include
                    url = `${URLs.threedyApi}/jobs/?offset=${offset}&limit=${limit}&ordering=title&status=16${searchQuery}`
                } else {
                    url = `${URLs.threedyApi}/jobs/?offset=${offset}&limit=${limit}&ordering=title&client=${clientId}&status=16${searchQuery}`
                }

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                const res = await fetch(url, options)

                const json = await res.json()
                console.log("RES ", json)
                setModels([...models, ...json.results])
                setTotalPageCount(Math.ceil(json.count / limit))

            } catch (e) {
                console.error(e);
            } finally {
                setLoadingSearch(false)
            }
        };

        if (isAuthenticated) {
            getModels()
        }

    }, [isAuthenticated, getAccessTokenSilently, pagesQueried, pageNum, limit, models, search, searchType])

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    }

    return (
        <AdFormPageWrapper>
            <div className="headerScroll">
                <AdFormHeader
                    currentStep={CURRENT_STEP}
                    totalSteps={AdForm.totalSteps}
                    title="Add your 3D model"
                    updateTitle="Update your 3D model"
                    subtitle="Choose a 3D model for your banner"
                    nextTitle="Positioning and adjusting your 3D model"
                    stepOneCheck={2}
                    stepTwoCheck={2}
                    stepThreeCheck={2}
                    stepFourCheck={1}
                    updating={adForm.updating}
                    id={adForm._id}
                />
                <Progress currentStep={CURRENT_STEP} totalSteps={AdForm.totalSteps} updating={adForm.updating} />
            </div>
            <AdFormBody>
                <FlexWrappedPage>
                        <PreviewContainer>
                            <DivBox>
                                <p className="bannerPreviewTitle">Banner Preview</p>
                            </DivBox>
                            <ImageViewer
                                fileUrl={adForm.background}
                                transparent={adForm.transparent}
                                width={adForm.width}
                                height={adForm.height}
                                model={{
                                    xCoordinate: centerModelCoordinates(adForm.width, adForm.height).x,
                                    yCoordinate: centerModelCoordinates(adForm.width, adForm.height).y,
                                    width: getDefaultModelDimensions(adForm.width, adForm.height),
                                    height: getDefaultModelDimensions(adForm.width, adForm.height),
                                    details: selectedModel,
                                    zoom: adForm.zooming,
                                    autoRotate: adForm.autoRotate,
                                    autoRotateSpeed: adForm.autoRotateSpeed,
                                    autoRotateDirection: adForm.autoRotateDirection,
                                    shadowIntensity: adForm.shadowIntensity,
                                    shadowEdgeSoftness: adForm.shadowEdgeSoftness,
                                    cameraControls: true,
                                    cameraDistance: adForm.cameraDistance,
                                    cameraVerticalAngle: adForm.cameraVerticalAngle,
                                    cameraHorizontalAngle: adForm.cameraHorizontalAngle
                                }}
                            />
                            {/* <ExpandButton onClick={makeImageFullScreen}>
                                <div><img src={ExpandIcon} alt="expand" /></div>
                            </ExpandButton> */}
                        </PreviewContainer>
                    <ModelsWrapper>
                        <SearchInput value={search} onChange={handleSearch} placeholder={"Search models by title or SKU..."} />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", marginTop: '12px' }}>
                            <Button
                                style={{ marginRight: '16px' }}
                                variant={searchType === "title" ? "primary" : "tertiary"}
                                onClick={() => { setSearchType("title"); triggerSearch() }}
                            >Title
                            </Button>
                            <Button
                                style={{ marginRight: '16px' }}
                                variant={searchType === "sku" ? "primary" : "tertiary"}
                                onClick={() => { setSearchType("sku"); triggerSearch() }}
                            >SKU
                            </Button>
                            {/* <Button
                                style={{ marginRight: '16px' }}
                                variant={searchType === "category" ? "primary" : "tertiary"}
                                onClick={() => { setSearchType("category"); triggerSearch() }}
                            >Category
                            </Button>
                            <Button
                                style={{ marginRight: '16px' }}
                                variant={searchType === "tag" ? "primary" : "tertiary"}
                                onClick={() => { setSearchType("tag"); triggerSearch() }}
                            >Tag
                            </Button> */}
                        </div>
                        <br />
                        <ResultsText>
                            {loadingSearch
                                ? `Searching for models...`
                                : `Search results: ${models.length} ${models.length === 1 ? "Item" : "Items"}`}
                        </ResultsText>
                        {loadingSearch && 
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                                <CircularProgress color='primary' size="60px"/>
                            </div>}
                        <ModelListContainer>
                            {!loadingSearch && models && models.length
                                ? <>
                                    {models.map((m: Model, idx: number) => (
                                        <ModelListItem
                                            key={`model-${idx}`}
                                            onClick={() => setSelectedModel(m)}
                                            selected={selectedModel?.job_id === m.job_id}
                                        >
                                            <img src={m.source_image_url} alt="source" />
                                            <div className="details">
                                                <h3 title={m.title}>{m.title}</h3>
                                                <p title={m.sku}>{m.sku}</p>
                                            </div>
                                        </ModelListItem>
                                    ))
                                    }
                                    {pageNum < totalPageCount && <Button style={{ minHeight: '114px' }} variant="primary" onClick={() => setPageNum(p => p + 1)}>Load More</Button>}
                                </>
                                : null
                            }
                        </ModelListContainer>
                    </ModelsWrapper>
                </FlexWrappedPage>
                <a onClick={scrollToTop} className="topPageLink">Top of Page</a>
            </AdFormBody>
            <div className="footerScroll">
                <AdFormFooter>
                    {!adForm.updating ? <StepButton
                        text="Back"
                        callback={goBack}
                        variant="back"
                    /> : <div></div>}
                    {/* <StepButton
                        text={adForm.updating ? "Update" : "Continue"}
                        callback={adForm.updating ? saveProgress : submitProgress}
                        variant="primary"
                        disabled={disabled || !selectedModel?.job_id}
                    /> */}
                    <StepButton
                        text={"Continue"}
                        callback={submitProgress}
                        variant="primary"
                        disabled={disabled || !selectedModel?.job_id}
                    />
                </AdFormFooter>
            </div>
            {showSuccessModal
                && <Modal
                    title="Success! You've updated your banner."
                    text={`You can access ${resultAd?.adName} in My Banners.`}
                    callback={goToAd}
                    callbackText={"Go to My Banner"}
                    handleClose={() => setShowSuccessModal(false)}
                    ad={resultAd}
                />}
            {showErrorModal && <Modal
                title="Sorry! Something went wrong."
                text={`Something has gone wrong along the way, and your banner hasn’t been updated. Please try again.`}
                callback={saveProgress}
                callbackText={"Try Again"}
                handleClose={() => setShowErrorModal(false)}
                showGoBack={true}
            />}
        </AdFormPageWrapper >
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        adForm: state.adForm
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        updateModel: async (width: number, height: number, model: Model, modelX: number, modelY: number) => dispatch(setAdModel(width, height, model, modelX, modelY)),
        clearAdForm: async () => dispatch(makeAction(Actions.CLEAR_AD_FORM)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdFormModel)