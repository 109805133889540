import { type } from "os";
import styled from "styled-components";

type ContainerProps = {
    contained?: boolean
}

export const PreviewContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 32px 40px;
    background: ${({ contained }) => contained ? "" : "#FAFAFC"};
    border: ${({ contained }) => contained ? "" : "2px solid #858993"};
    box-sizing: border-box;
    border-radius: 8px;
    overflow: auto;
    // height: 100%;
`

export const ExpandButton = styled.button`

    background: rgba(255, 255, 255, 0.4);
    /* Neutral Colours/Lighter */

    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    backdrop-filter: blur(24px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    align-self: flex-end;
    margin-top: 20px;

    & > div {
        width: 32px;
        height: 32px;

        background: #353E5A;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
`

type WrapperProps = {
    width: number
    height: number
    withInput?: boolean
    selectionScreen?: boolean
    fileUrl?: string
}

export const Wrapper = styled.div<WrapperProps>`

    ${({ width, height, withInput }) => {
        if (withInput) {
            return `position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;`
        } else {
            let w = width;
            let h = height;
            // if (width < 220 && height < 220) {
            //     w = 220
            //     h = 220
            // }
            return `position: initial;
                    width: ${w}px;
                    height: ${h}px;
                    // border-radius: 8px;
            `
        }
    }}

    z-index: 2;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .preview-img {
        background-image: url('https://assets.swirl.nextechar.com/templates/transparentBackground.png');
        background-repeat: repeat;
    }

    background: ${({ selectionScreen }) => selectionScreen ? '#F2F2F3' : ''};
    border: ${({ fileUrl }) => fileUrl ? "" : "0.5px solid #858993"};
    // border: 2px solid #858993;
    box-sizing: border-box;
    // border-radius: 9px;
`

export const Canvas = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const PlaceholderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    p {
        max-width: 80%;
        text-align: center;
        margin: 0.5rem 0 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        /* or 137% */

        text-align: center;

        /* Text Colours/Headline 2 */

        color: #717171;
    }
`

export const DeleteButton = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
`

type ImgProps = {
    width: number
    height: number
}

// to handle imperfectly dimensions images
// leave out for now to match what ad tech does
// ${({ width, height }) => {
//     // if (width > height) {
//     //     return `width: 100%;
//     //         height: auto;
//     //     `
//     // } else {
//     //     return `height: 100%;
//     //     width: auto;`
//     // }
// };

export const Img = styled.img<ImgProps>`
    width: 100%;
    height: 100%;
`

type VIARWrapperProps = {
    pointerEvents: boolean;
}

export const ViewInArButtonWrapper = styled.div<VIARWrapperProps>`
    width: 100%;
    height: 100%;
    position: relative;
    pointer-events: ${({ pointerEvents }) => pointerEvents ? "" : "none"};
`

type ViewInArButtonProps = {
    xCoordinate?: number
    yCoordinate?: number
    width?: number
}

export const ViewInArButton = styled.button<ViewInArButtonProps>`
    position: absolute;
    top: ${({ yCoordinate }) => `${yCoordinate}px`};
    left: ${({ xCoordinate }) => `${xCoordinate}px`};
    // width:${({ width }) => width ? `${width}px` : '90px'};
    width: max-content;
    height: 35px;
    padding:2px;
    border-radius:6px;
    background-color:rgba(10,10,10,0.5);
    border:none;
    color:#fff;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 90px;
        height: auto;
    }
    p {
        margin: 0;
    }
`