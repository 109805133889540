import { Header, LinkList, Nav } from "./page-header.styles"
import swirlLogo from 'Assets/images/logos/SwirlLogo.png'
import swirlIcon from 'Assets/images/logos/SwirlIcon.png'
import { Link } from "react-router-dom"
import { Links } from "Values/Constants"
import { Button } from "Styles/library/button"
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
    preview?: boolean
}
const PageHeader = (props: Props) => {

    const { preview } = props
    const accessFromSuite = localStorage.getItem("fromSuite"); 

    const { logout } = useAuth0();

    const exit = () => {
        localStorage.setItem("fromSuite", JSON.stringify(false))
        window.close()
    }

    return (
        <Header>
            <Nav>
                <Link to={Links.home}>
                    <img src={swirlLogo} className="img1" style={{width: "222px", height: "62px"}} alt="logo" />
                    <img src={swirlIcon} className="img2" style={{width: "82px", height: "62px"}} alt="logo" />
                </Link>
                {preview ? null :
                    <LinkList>
                        <Link to={Links.home}>Home</Link>
                        <Link to={Links.myAds}>My Banners</Link>
                        <Link to={Links.support}>Support</Link>
                    </LinkList>
                }
            </Nav>
            {preview ? null : accessFromSuite === "true" ? 
                <Button variant="secondary" onClick={exit}>
                    Exit
                </Button> :
                <Button variant="secondary" onClick={() => logout({ returnTo: window.location.origin })}>
                    Log Out
                </Button>
            }
            {/* <img src={AccountLogo} alt="account-logo" /> */}
        </Header>
    )
}
export default PageHeader