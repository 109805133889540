import React, { useEffect, useState } from "react"
import { Button } from "Styles/library/button"
import { BottomActions, ModalBox, ModalContent, ModalWrapper } from "Styles/library/modal"
import { DividedHeader } from "../Modal/modal.styles"
import FolderIcon from 'Assets/images/icons/folder-icon.svg'
import CloseIcon from 'Assets/images/icons/close-icon.svg'
import { FolderRow, FoldersSection, TitleRow } from "./move-to-folder.styles"
import LeftArrow from 'Assets/images/arrows/left-arrow.svg'
import { useLocation } from "react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { Links, URLs } from "Values/Constants"
import { AuthToken, Folder } from "@declarations"
import { createFolder, FolderSubmission, searchFolderContents } from "Api/Api/folder"
import jwtDecode from "jwt-decode"
import CreateFolderModal from "../CreateFolderModal/create-folder-modal.component"

type Props = {
    callback: (folder: null | string[]) => void
    handleClose: () => void
    filename: string
    disabled?: boolean
}

const MoveToFolderModal = (props: Props) => {

    const { callback, handleClose, filename = false } = props

    const { getAccessTokenSilently } = useAuth0();

    const handleOutsideClose = (e: React.MouseEvent<HTMLElement>) => {
        const id = e.currentTarget.id
        if (id === "modal-wrapper") {
            handleClose()
        }
    }

    const [folderAncestors, setFolderAncestors] = useState<string[] | null>(null)

    const location = useLocation()
    useEffect(() => {
        const p = location.pathname
        const ancestors = p.split("/").slice(2)
        if (ancestors.length) {
            setFolderAncestors(ancestors)
        } else {
            setFolderAncestors(null)
        }
    }, [location])

    const [folderNames, setFolderNames] = useState<string[]>([])

    useEffect(() => {

        const getSubFolders = async () => {
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const tkn: AuthToken = jwtDecode(accessToken)
            console.log("TKN", tkn)
            const creatorClientId = tkn["https://thdy/app_md"].client_id || null


            // get folders

            const ancestors = folderAncestors === null ? folderAncestors : `${folderAncestors.join(",")}`
            const res0 = await searchFolderContents(creatorClientId, ancestors, accessToken)

            const result: string[] = []
            for (const a of res0.data as Folder[]) {
                result.push(a._id.name)
            }

            setFolderNames(result)
        }

        getSubFolders()

    }, [folderAncestors, getAccessTokenSilently])

    const goUpFolder = () => {
        if (folderAncestors === null || folderAncestors.length === 1) {
            setFolderAncestors(null)
        } else {
            const arr = folderAncestors.splice(0, folderAncestors.length - 1)
            setFolderAncestors(arr)
        }
    }

    const goDownFolder = (name: string) => {
        if (folderAncestors === null) {
            setFolderAncestors([name])
        } else {
            setFolderAncestors([...folderAncestors, name])
        }
    }


    const [showFolderModal, setShowFolderModal] = useState<boolean>(false)

    const toggleFolderModal = () => {
        setShowFolderModal(!showFolderModal)
    }

    const handleCreateFolder = async (name: string) => {
        try {

            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const tkn: AuthToken = jwtDecode(accessToken)
            console.log("TKN", tkn)
            const clientId = tkn["https://thdy/app_md"].client_id || null

            const submission: FolderSubmission = {
                _id: {
                    name: name,
                    path: folderAncestors === null ? folderAncestors : `,${folderAncestors.join(",")},`,
                    creatorClientId: clientId
                },
            }
            const res = await createFolder(submission, accessToken)
            console.log("RES from creating folder", res)
            const newFolder: Folder = res.data.data
            setFolderNames([...folderNames, newFolder._id.name])

        } catch (e) {
            console.error("ERROR creating folder", e)
        }
    }

    const [alreadyHere, setAlreadyHere] = useState<boolean>(false)

    useEffect(() => {
        const lastAncestor = folderAncestors === null ? null : folderAncestors[folderAncestors.length - 1]
        const endOfPath = lastAncestor === null ? location.pathname.endsWith(Links.myAds) : location.pathname.endsWith(lastAncestor)
        setAlreadyHere(endOfPath)
    }, [location, folderAncestors])

    return (
        <ModalWrapper id="modal-wrapper" onClick={handleOutsideClose}>
            <ModalBox onClick={e => e.stopPropagation()} dropPadding={true}>
                <DividedHeader>
                    <div className="title-wrapper">
                        <img src={FolderIcon} alt="folder" />
                        <h2>Move to {folderAncestors === null ? "Root" : folderAncestors[folderAncestors.length - 1]}</h2>
                    </div>
                    <button className="close-wrapper" onClick={handleClose}>
                        <img src={CloseIcon} alt="close" />
                        <h3>Close</h3>
                    </button>
                </DividedHeader>
                <ModalContent>
                    <TitleRow>
                        <h3>Choose Destination Folder</h3>
                        <Button variant="primary" onClick={toggleFolderModal}>
                            Create Folder
                        </Button>
                    </TitleRow>
                    {folderAncestors !== null && folderAncestors.length ?
                        <button
                            onClick={goUpFolder}
                            style={{ alignSelf: 'flex-start', display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <img src={LeftArrow} alt="back" style={{ marginRight: '6px' }} />
                            Back
                        </button>
                        : null}
                    <FoldersSection>
                        {folderNames.map((f: string, idx: number) => {
                            if (filename === f) return null
                            return <FolderRow onClick={() => goDownFolder(f)} key={f + idx}>
                                {f}
                            </FolderRow>
                        })}
                    </FoldersSection>
                    <BottomActions>
                        <div></div>
                        <Button variant="primary" onClick={() => callback(folderAncestors === null ? null : folderAncestors)}
                            disabled={alreadyHere}
                        >Move Here</Button>
                    </BottomActions>
                </ModalContent>
            </ModalBox>
            {showFolderModal &&
                <CreateFolderModal
                    callback={(folderName: string) => {
                        handleCreateFolder(folderName)
                        toggleFolderModal()
                    }}
                    handleClose={toggleFolderModal}
                    h2="Create Folder"
                    p="here"
                    existingFolders={folderNames}
                />}
        </ModalWrapper>
    )
}

export default MoveToFolderModal