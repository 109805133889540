import { Ad, Analytics, DateRange, ReduxAction } from "@declarations";

export enum Actions {
    // AD FORM
    SET_AD_FORM = "SET_AD_FORM",
    CLEAR_AD_FORM = "CLEAR_AD_FORM",

    // ANALYTICS
    SET_ANALYTICS_DATES = "SET_ANALYTICS_DATES",
    SET_ANALYTICS_DATA = "SET_ANALYTICS_DATA",
    SET_ANALYTICS_LOADING = "SET_ANALYTICS_LOADING",
    CLEAR_ANALYTICS_DATA = "CLEAR_ANALYTICS_DATA"
}

// overloads
// AD FORM
export function makeAction(type: Actions.SET_AD_FORM, payload: Ad): ReduxAction
export function makeAction(type: Actions.CLEAR_AD_FORM): ReduxAction

// ANALYTICS
export function makeAction(type: Actions.SET_ANALYTICS_DATES, payload: Record<string, DateRange>): ReduxAction
export function makeAction(type: Actions.SET_ANALYTICS_DATA, payload: Analytics.Data): ReduxAction
export function makeAction(type: Actions.SET_ANALYTICS_LOADING, payload: Analytics.Loading): ReduxAction
export function makeAction(type: Actions.CLEAR_ANALYTICS_DATA): ReduxAction

// definition
export function makeAction(type: Actions, payload?: Record<string, any> | boolean | number): ReduxAction {
    return { type, payload }
}
