import { AdSchema, AppState, DateRange, MomentRange, PageProps } from "@declarations"
import DatePicker from "Components/DatePicker/DatePicker"
import DownloadReportButton from "Components/DownloadReportButton/DownloadReportButton"
import { ContentBody, ContentHeader } from "Components/PageLayout/page-layout.styles"
import { connect } from "react-redux"
import { updateDates } from "Store/Actions/AnalyticsDatesActions"
import { store } from "Store/store"
import { GraphBox, SummaryRow } from "./analytics.styles"
import SummaryBox from "./SummaryBox/summary-box.component"
import ImpressionsIcon from 'Assets/images/icons/impressions-icon.svg'
import ClicksIcon from 'Assets/images/icons/clicks-icon.svg'
import ArExpIcon from 'Assets/images/icons/ar-exp-icon.svg'
import CtrIcon from 'Assets/images/icons/ctr-icon.svg'
import InteractionsIcon from 'Assets/images/icons/interactions-icon.svg'
import InteractionRateIcon from 'Assets/images/icons/interaction-rate-icon.svg'
import AvgDurationIcon from 'Assets/images/icons/avg-duration-icon.svg'
import TotalDurationIcon from 'Assets/images/icons/total-duration-icon.svg'
import { useEffect, useState } from "react"
import { getARClicksOverTime, getARCtrOverTime, getARExpsOverTime, getClicksOverTime, getCtrOverTime, getImpressionsOverTime, getInteractionsOverTime, getSummaryData } from "Store/Actions/AnalyticsActions"
import Skeleton from "react-loading-skeleton"
import TimelineChart from "./TimelineChart/TimelineChart"
import useChartWidth from "Utils/useChartWidth"
import { Actions, makeAction } from "Store/Actions/ActionTypes"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    ad?: AdSchema
}

const Analytics = (props: Props) => {

    const { ad, dates, updateDates,
        impressions, clicks, ctr, interactions, arExp,
        getSummaryData,
        getImpressionsGraphs,
        getClicksGraphs,
        getCTRGraphs,
        getInteractionsGraphs,
        getArGraphs,
        loading,
        clearAnalyticsData
    } = props

    const [section, setSection] = useState<string>("Impressions")

    useEffect(() => {
        // test ad
        // let ad = { _id: "615727edd4841d99220bdd54" }
        if (ad && ad._id) {
            getSummaryData(ad._id, dates)
        } else {
            clearAnalyticsData()
        }
    }, [ad, clearAnalyticsData, dates, getSummaryData])

    useEffect(() => {

        if (ad && ad._id) {
            switch (section) {
                case "Impressions":
                    getImpressionsGraphs(ad._id, dates)
                    return
                case "Clicks":
                    getClicksGraphs(ad._id, dates)
                    return
                case "CTR":
                    getCTRGraphs(ad._id, dates)
                    return
                case "Interactions":
                    getInteractionsGraphs(ad._id, dates)
                    return
                case "AR Exp.":
                    getArGraphs(ad._id, dates)
                    return
                default:
                    return
            }
        }

    }, [dates, getArGraphs, getCTRGraphs, getClicksGraphs, getImpressionsGraphs, getInteractionsGraphs, getSummaryData, ad, section])

    const width = useChartWidth()

    const renderGraphs = (section: string) => {
        switch (section) {
            case "Impressions":
                return <>
                    {impressions.overTime?.length
                        ?
                        <GraphBox>
                            <header>
                                <h3>Total impressions over time</h3>
                            </header>
                            <TimelineChart
                                width={width}
                                height={500}
                                startDate={dates.start}
                                endDate={dates.end}
                                data={impressions.overTime}
                                legendTitle="Number of Impressions"
                            />
                        </GraphBox>
                        : null
                    }
                </>
            case "Clicks":
                return <>
                    {clicks.overTime?.length
                        ? <GraphBox>
                            <header>
                                <h3>Total clicks over time</h3>
                            </header>
                            <TimelineChart
                                width={width}
                                height={500}
                                startDate={dates.start}
                                endDate={dates.end}
                                data={clicks.overTime}
                                legendTitle="Number of Clicks"
                            />
                        </GraphBox>
                        : null
                    }
                </>
            case "CTR":
                return <>
                    {ctr.overTime?.length
                        ? <GraphBox>
                            <header>
                                <h3>Click-through rate (clicks/impressions)</h3>
                            </header>
                            <TimelineChart
                                width={width}
                                height={500}
                                startDate={dates.start}
                                endDate={dates.end}
                                data={ctr.overTime}
                                legendTitle="Click-through rate"
                                dataFormat="percent"
                            />
                        </GraphBox>
                        : null
                    }
                </>
            case "Interactions":
                return <>
                    <GraphBox>
                        <header>
                            <h3>Interaction analytics overview</h3>
                        </header>
                        <SummaryRow>
                            <SummaryBox
                                icon={InteractionRateIcon}
                                text="Average Interaction Rate"
                                value={interactions.avgRate}
                            />
                            <SummaryBox
                                icon={TotalDurationIcon}
                                text="Total Interaction Duration"
                                value={interactions.totalDuration}
                            />
                            <SummaryBox
                                icon={AvgDurationIcon}
                                text="Average Interaction Duration"
                                value={interactions.avgDuration}
                            />
                        </SummaryRow>
                    </GraphBox>
                    {interactions.overTime?.length
                        ? <GraphBox>
                            <header>
                                <h3>Total interactions over time</h3>
                            </header>
                            <TimelineChart
                                width={width}
                                height={500}
                                startDate={dates.start}
                                endDate={dates.end}
                                data={interactions.overTime}
                                legendTitle="Number of Interactions"
                            />
                        </GraphBox>
                        : null
                    }
                </>
            case "AR Exp.":
                return <>
                    {arExp.expOverTime?.length
                        ? <GraphBox>
                            <header>
                                <h3>Total AR experiences over time</h3>
                            </header>
                            <TimelineChart
                                width={width}
                                height={500}
                                startDate={dates.start}
                                endDate={dates.end}
                                data={arExp.expOverTime}
                                legendTitle="Number of AR experiences"
                            />
                        </GraphBox>
                        : null
                    }
                    {arExp.clicksOverTime?.length
                        ? <GraphBox>
                            <header>
                                <h3>Total AR clicks over time</h3>
                            </header>
                            <TimelineChart
                                width={width}
                                height={500}
                                startDate={dates.start}
                                endDate={dates.end}
                                data={arExp.clicksOverTime}
                                legendTitle="Number of AR clicks"
                            />
                        </GraphBox>
                        : null
                    }
                    {arExp.ctrOverTime?.length
                        ? <GraphBox>
                            <header>
                                <h3>AR click-through rate (AR clicks/AR Experiences)</h3>
                            </header>
                            <TimelineChart
                                width={width}
                                height={500}
                                startDate={dates.start}
                                endDate={dates.end}
                                data={arExp.ctrOverTime}
                                legendTitle="AR click-through rate"
                            />
                        </GraphBox>
                        : null
                    }
                </>
            default:
        }
    }

    return (
        <ContentBody id="analytics-page">
            <ContentHeader>
                <h3>3D Banners Overview</h3>
                <div className="actions">
                    <DownloadReportButton />
                    <DatePicker
                        startDate={dates.start}
                        endDate={dates.end}
                        startDateId="START_DATE"
                        endDateId="END_DATE"
                        updateDates={updateDates}
                    />
                </div>
            </ContentHeader>
            <SummaryRow>
                {loading.summaryData
                    ?
                    <>
                        <Skeleton height={91} />
                        <Skeleton height={91} />
                        <Skeleton height={91} />
                        <Skeleton height={91} />
                        <Skeleton height={91} />
                    </>
                    :
                    <>
                        <SummaryBox icon={ImpressionsIcon} text="Impressions" value={impressions.total} selection={section} sendSelection={(s: string) => setSection(s)} />
                        <SummaryBox icon={ClicksIcon} text="Clicks" value={clicks.total} selection={section} sendSelection={(s: string) => setSection(s)} />
                        <SummaryBox icon={CtrIcon} text="CTR" value={ctr.total} selection={section} sendSelection={(s: string) => setSection(s)} />
                        <SummaryBox icon={InteractionsIcon} text="Interactions" value={interactions.total} selection={section} sendSelection={(s: string) => setSection(s)} />
                        <SummaryBox icon={ArExpIcon} text="AR Exp." value={arExp.total} selection={section} sendSelection={(s: string) => setSection(s)} />
                    </>
                }
            </SummaryRow>
            {renderGraphs(section)}
        </ContentBody>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        dates: state.analyticsDates.dates,
        impressions: state.analytics.impressions,
        clicks: state.analytics.clicks,
        ctr: state.analytics.ctr,
        interactions: state.analytics.interactions,
        arExp: state.analytics.arExp,
        loading: state.analyticsLoading
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        updateDates: (dateRange: MomentRange) => dispatch(updateDates(dateRange)),
        getSummaryData: (id: string, dateRange: DateRange) => {
            dispatch(getSummaryData(id, dateRange))
        },
        getImpressionsGraphs: (id: string, dateRange: DateRange) => dispatch(getImpressionsOverTime(id, dateRange)),
        getClicksGraphs: (id: string, dateRange: DateRange) => dispatch(getClicksOverTime(id, dateRange)),
        getCTRGraphs: (id: string, dateRange: DateRange) => dispatch(getCtrOverTime(id, dateRange)),
        getInteractionsGraphs: (id: string, dateRange: DateRange) => dispatch(getInteractionsOverTime(id, dateRange)),
        getArGraphs: (id: string, dateRange: DateRange) => {
            dispatch(getARExpsOverTime(id, dateRange))
            dispatch(getARClicksOverTime(id, dateRange))
            dispatch(getARCtrOverTime(id, dateRange))
        },
        clearAnalyticsData: () => dispatch(makeAction(Actions.CLEAR_ANALYTICS_DATA))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)