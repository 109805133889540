import React from "react"
import { AdSchema, AppState, Form, PageProps } from "@declarations"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { store } from "Store/store"
import { setAdBasicDetails } from "Store/Actions/AdFormActions"
import { useState } from "react"
import AdFormPageWrapper from "Components/AdForm/Page/ad-form-page-wrapper.component"
import AdFormHeader from "Components/AdForm/Header/ad-form-header.component"
import AdFormFooter from "Components/AdForm/Footer/ad-form-footer.component"
import StepButton from "Components/AdForm/StepButton/step-button.component"
import { AdForm, Links, URLs } from "Values/Constants"
import AdFormBody from "Components/AdForm/Body/ad-form-body.component"
import ValidatedInput from "Components/Forms/Input/input.component"
import { required, maxLength, FormValidator } from "Components/Forms/form-validation"
import { FieldWrapper } from "Styles/library/forms"
import { Actions, makeAction } from "Store/Actions/ActionTypes"
import { useAuth0 } from "@auth0/auth0-react"
import { updateAd } from "Api/Api/ads"
import Tooltip from "Components/Tooltip/Tooltip"
import Modal from "Components/Modals/Modal/modal.component"
import Progress from "../../Components/AdForm/Progress/progress.component"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    previousStep: string
    nextStep: string
}

const CURRENT_STEP = 1

const AdFormBasicDetails = (props: Props) => {

    const { updateBasicDetails, adForm, previousStep, nextStep, clearAdForm } = props

    const [basicDetails, setBasicDetails] = useState<Form.Data>({
        title: adForm.title,
        // idTag: adForm.idTag,
        destinationLink: adForm.destinationLink,
        clickMacro: adForm.clickMacro
    })

    // Form helpers
    const handleValueChange = (name: Form.Field, value: string | number | undefined) => {
        // if (value) {
        setBasicDetails(data => ({
            ...data,
            [name]: value
        }))
        // }
    }

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (disabled) return;
        if (e.key === "Enter") submitProgress()
    }

    const [disabled, setDisabled] = useState<boolean>(true);

    const handleValidChange = FormValidator(setDisabled);
    // end of form helpers

    const history = useHistory();

    const { getAccessTokenSilently } = useAuth0();

    const goToAd = () => {

        if (adForm._id) {
            clearAdForm()
            history.push(Links.ad.constructor(adForm._id))
        }
    }

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [resultAd, setResultAd] = useState<AdSchema>()

    const saveProgress = async () => {
        setDisabled(true)
        try {
            setShowErrorModal(false)
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const update: Partial<Record<keyof AdSchema, any>> = {
                adName: basicDetails.title,
                activeURL: basicDetails.destinationLink,
                clickMacro: basicDetails.clickMacro
            }

            if (!adForm._id) return
            const res = await updateAd(adForm._id, update, accessToken)
            console.log("SUCCESSFUL UPDATE ", res)
            setResultAd(res.data.data as AdSchema)
            setShowSuccessModal(true)
        } catch (e) {
            console.error("Error updating banner", e)
            setShowErrorModal(true)
        } finally {
            setDisabled(false)
        }
    }

    const submitProgress = async () => {

        const { title,
            // idTag,
            destinationLink,
            clickMacro
        } = basicDetails

        if (!title
            // || !idTag
            // || !destinationLink
            // || !clickMacro
        ) return

        await updateBasicDetails(title as string, destinationLink as string, clickMacro as string)

        history.push(nextStep)
    }

    const goBack = async () => {
        await clearAdForm()
        history.push(previousStep)
    }
    return (
        <AdFormPageWrapper>
             <div className="headerScroll">
                <AdFormHeader
                    currentStep={CURRENT_STEP}
                    totalSteps={AdForm.totalSteps}
                    title="Enter details for your banner"
                    updateTitle="Update your banner details"
                    subtitle="Give your banner a title and attach your link"
                    nextTitle="Selecting your banner size"
                    stepOneCheck={1}
                    updating={adForm.updating}
                    id={adForm._id}
                />
                <Progress currentStep={CURRENT_STEP} totalSteps={AdForm.totalSteps} updating={adForm.updating} />
            </div>
            <AdFormBody>
                <h4>Please enter your banner details:</h4>
                <FieldWrapper>
                    <ValidatedInput
                        id="title"
                        name="title"
                        placeholder="Banner Title"
                        value={basicDetails.title}
                        validations={[required("Banner title"), maxLength(255)]}
                        onValueChange={handleValueChange}
                        onValidChange={handleValidChange}
                        onKeyUp={onKeyUp}
                        type="text"
                    />
                </FieldWrapper>
                {/* <FieldWrapper>
                    <ValidatedInput
                        name="idTag"
                        placeholder="Ad ID Tag"
                        value={basicDetails.idTag}
                        validations={[required("Ad ID Tag")]}
                        onValueChange={handleValueChange}
                        onValidChange={handleValidChange}
                        onKeyUp={onKeyUp}
                        type="text"
                    />
                </FieldWrapper> */}

                <div style={{ display: 'flex', width: '100%', maxWidth: '450px' }}>

                    <FieldWrapper>
                        <div style={{ position: 'absolute', left: '-40px', top: '35%' }}>
                            {/* eslint-disable-next-line no-template-curly-in-string */}
                            <Tooltip text="Users will be sent to the click URL when they click anywhere on the banner." />
                        </div>
                        <ValidatedInput
                            id="destinationLink"
                            name="destinationLink"
                            placeholder="Click URL"
                            value={basicDetails.destinationLink}
                            // validations={[required("Destination Link"), link]}
                            validations={[maxLength(255)]}
                            onValueChange={handleValueChange}
                            // onValidChange={handleValidChange}
                            onKeyUp={onKeyUp}
                            type="text"
                        />
                    </FieldWrapper>

                </div>

                {/* <div style={{ display: 'flex', width: '100%', maxWidth: '450px' }}>
                    <FieldWrapper>
                        <div style={{ position: 'absolute', left: '-40px', top: '35%' }}>
                            <Tooltip text="Click macro defaults to Nextech DSP standard macro ${CLICK_URL}. Replace with provided DSP macro or add a custom URL that will track clicks. Leave this parameter blank if you do not wish to use it or, if your URL or URL macro includes click tracking." />
                        </div>
                        <ValidatedInput
                            id="clickMacro"
                            name="clickMacro"
                            placeholder="Click Macro"
                            value={basicDetails.clickMacro}
                            // validations={[required("Click Macro")]}
                            validations={[maxLength(255)]}
                            onValueChange={handleValueChange}
                            // onValidChange={handleValidChange}
                            onKeyUp={onKeyUp}
                            type="text"
                        />
                    </FieldWrapper>
                </div> */}

            </AdFormBody>
            <div className="footerScroll">
                <AdFormFooter>
                    {!adForm.updating ? <StepButton
                        text="Back"
                        callback={goBack}
                        variant="back"
                    /> : <div></div>}
                    <StepButton
                        text={adForm.updating ? "Update" : "Continue"}
                        callback={adForm.updating ? saveProgress : submitProgress}
                        variant="primary"
                        disabled={disabled}
                    />
                </AdFormFooter>
            </div>
            {showSuccessModal
                && <Modal
                    title="Success! You've updated your banner."
                    text={`You can access ${resultAd?.adName} in My Banners. If you have updated the URL for your banner, then you will need to update the embed code in your DSP.`}
                    callback={goToAd}
                    callbackText={"Go to My Banner"}
                    handleClose={goToAd}
                    ad={resultAd}
                />}
            {showErrorModal && <Modal
                title="Sorry! Something went wrong."
                text={`Something has gone wrong along the way, and your banner hasn’t been updated. Please try again.`}
                callback={saveProgress}
                callbackText={"Try Again"}
                handleClose={() => setShowErrorModal(false)}
                showGoBack={true}
            />}
        </AdFormPageWrapper>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        adForm: state.adForm
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        updateBasicDetails: async (title: string, destinationLink: string, clickMacro: string) => dispatch(setAdBasicDetails(title, destinationLink, clickMacro)),
        clearAdForm: async () => dispatch(makeAction(Actions.CLEAR_AD_FORM)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdFormBasicDetails)