import { GraphBox } from "Components/Analytics/analytics.styles";
import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
    grid-template-areas:
        "details image"
        "settings image";

    @media screen and (max-width: 1000px) {
        grid-template-columns: 100%;
        grid-template-areas:
        "image"
        "details"
        "settings";
    }
`
export const Details = styled(GraphBox)`
    grid-area: details;
    overflow: auto;
`

export const Settings = styled(GraphBox)`
    grid-area: settings;
`

export const Preview = styled(GraphBox)`
    grid-area: image;
`