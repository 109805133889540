import styled from "styled-components";

export const TabRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    border-bottom: 1px solid #bdbec3;
    margin: 0 0 40px;

    margin: 0 108px;


    @media screen and (max-width: 1000px) {
        margin: 0 24px;
    }
`

type TabButtonProps = {
    selected: boolean
}

export const TabButton = styled.button<TabButtonProps>`

    background: transparent;
    border-radius: 0 !important;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 100%;
    color: #030e31;
    padding: 20px 0;
    &:not(:last-child) {
        margin-right: 48px;
    }


    ${({ selected }) => {
        if (selected) {
            return `
            border-bottom: solid 4px #030e31;
            font-weight: 500;
            `
        }
    }}

`