import { toPng } from 'html-to-image';
import { jsPDF } from "jspdf";
import downloadIconWhite from "Assets/images/icons/download-icon-white.png"
import { AppState, PageProps, RouteParams } from '@declarations';
import { connect } from 'react-redux';
import { store } from 'Store/store';
import { useParams } from 'react-router-dom';
import { Button } from 'Styles/library/button';

type Props = PageProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
}

const DownloadReportButton = (props: Props) => {

    const { id } = useParams<RouteParams>()

    const name = `Analytics Report ${id}`

    const downloadPage = () => {
        const page: HTMLElement | null = document.getElementById("analytics-page")
        if (page === null) console.error("Error downloading report: can't select page by ID")
        toPng(page as HTMLElement, { quality: 0.95 })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = `${name}.jpeg`;
                const pdf = new jsPDF();
                const imgProps = pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(`${name}.pdf`);
            });
    }

    return (
        <Button
            onClick={downloadPage}
            variant="primary"
            icon="left"
        >
            <img src={downloadIconWhite} alt="download" />
            Download Report
        </Button>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DownloadReportButton)