import styled from "styled-components";

export const Header = styled.header`
    min-height: 70px;
    background: #030E31;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 36px;
    flex-wrap: wrap;

    .img2 {
        display: none;
    }

    @media screen and (max-width: 800px) {
        .img1 {
            display: none;
        }

        .img2 {
            display: block;
        }
    }

    @media screen and (max-width: 637px) {
        .img1 {
            display: block;
        }

        .img2 {
            display: none;
        }
    }
`

export const Nav = styled.nav`

    display: flex;
    flex-wrap: wrap;

    // img {
    //     margin-right: 48px;
    // }
   a {
       padding: 0;
        color: white;
        margin: 0px 48px 0px 0;
        display: flex;
        align-items: center;
    }
`

export const LinkList = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`