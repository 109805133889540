import { AdSchema, AppState, AuthToken, CreativeBg, PageProps } from "@declarations"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { store } from "Store/store"
import { setAdBackground, } from "Store/Actions/AdFormActions"
import { useEffect, useState } from "react"
import AdFormPageWrapper from "Components/AdForm/Page/ad-form-page-wrapper.component"
import AdFormHeader from "Components/AdForm/Header/ad-form-header.component"
import AdFormFooter from "Components/AdForm/Footer/ad-form-footer.component"
import StepButton from "Components/AdForm/StepButton/step-button.component"
import { AdForm, Links, URLs } from "Values/Constants"
import AdFormBody from "Components/AdForm/Body/ad-form-body.component"
import ValidatedFileInput from "Components/Forms/FileInput/file-input.component"
import { required, FormValidator } from "Components/Forms/form-validation"
import { ImageSelectionSection, ImageTileButton, ImageTileRow, ImageTilesSection } from "Components/AdForm/ImageTile/image-tile.styles"
import EmptyImageIcon from 'Assets/images/icons/empty-image-icon.svg'
import { FlexWrappedPage } from "Styles/library/page"
import ImageViewer from "Components/ImageViewer/image-viewer.component"
import { BgSubmission, createBgImage, getUploadUrl, searchCreativeBgByCreator, uploadImage } from "Api/Api/bgImages"
import Tooltip from "Components/Tooltip/Tooltip"
import { SectionTitleWrapper } from "Components/AdForm/Shared/side-form.styles"
import { PreviewContainer } from "Components/ImageViewer/image-viewer.styles"
import { useAuth0 } from "@auth0/auth0-react";
import ImageModal from "Components/Modals/AdForm/ImageModal/image-modal.component"
import { getHeightAndWidthFromDataUrl } from "Utils/images"
import { updateAd } from "Api/Api/ads"
import jwtDecode from "jwt-decode"
import { Actions, makeAction } from "Store/Actions/ActionTypes"
import { templateCreativeBgs } from "Data/template-creativebgs"
import Modal from "Components/Modals/Modal/modal.component"
import { DivBox } from "Components/AdForm/Body/ad-form-body.styles"
import Progress from "../../Components/AdForm/Progress/progress.component"
import CircularProgress from '@bit/mui-org.material-ui.circular-progress';
import {
    Container,
} from "Components/Forms/FileInput/file-input.styles"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    previousStep: string
    nextStep: string
}

const CURRENT_STEP = 3

const AdFormBackground = (props: Props) => {

    const { updateBackground, previousStep, nextStep, adForm, clearAdForm } = props

    const { getAccessTokenSilently } = useAuth0();

    const [creativeBgs, setCreativeBgs] = useState<CreativeBg[]>([])
    const [creativeBgUrl, setCreativeBgUrl] = useState<string>(adForm.background)
    const [localUploadFile, setLocalUploadFile] = useState<File | null>(null)
    const [localUploadFileUrl, setLocalUploadFileUrl] = useState<string>("")
    const [showImageModal, setShowImageModal] = useState<boolean>(false)
    const [pendingUpload, setPendingUpload] = useState<boolean>(false)
    const [loadingUploads, setLoadingUploads] = useState<boolean>(false)

    useEffect(() => {

        const getExistingUploads = async () => {
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const tkn: AuthToken = jwtDecode(accessToken)
            console.log("TKN", tkn)
            const clientId = tkn["https://thdy/app_md"].client_id?.toString() || null
            const res = await searchCreativeBgByCreator(clientId, accessToken)

            setCreativeBgs(res.data as CreativeBg[])
        }

        getExistingUploads()

    }, [getAccessTokenSilently])

    const [disabled, setDisabled] = useState<boolean>(true);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [pendingSubmission, setPendingSubmission] = useState<boolean>(false)
    const handleValidChange = FormValidator(setDisabled);

    const selectBackgroundFile = async (url: string) => {
        setCreativeBgUrl(url)
        setNoBackground(false)
    }

    const receiveUploadFile = (file: File | null) => {
        setLocalUploadFile(file)
        if (file) {
            const url = URL.createObjectURL(file)
            setLocalUploadFileUrl(url)
        } else {
            setLocalUploadFileUrl("")
        }
    }

    const submitUploadFile = async () => {
        try {
            if (!localUploadFile) return
            setPendingUpload(true)

            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })
            const tkn: AuthToken = jwtDecode(accessToken)
            const clientId = tkn["https://thdy/app_md"].client_id || null

            // GET SIGNED URL FROM API TO COMMUNICATE WITH S3
            const res = await getUploadUrl(accessToken, localUploadFile.type)
            console.log(res)
            const url = res.data.data.presignedURL
            console.log(url)
            const key = res.data.data.Key
            // UPLOAD IMAGE FILE TO 3
            const res2 = await uploadImage(url, localUploadFile)
            console.log(res2)
            if (res2.status !== 200) throw new Error(res.statusText)
            // SEND DETAILS TO API FOR STORINNG CREATIVE BG
            const objectUrl = URLs.s3Bucket + key
            console.log(objectUrl)
            const dimensions = await getHeightAndWidthFromDataUrl(objectUrl)
            console.log("test1")
            const submission: BgSubmission = {
                width: dimensions.width,
                height: dimensions.height,
                creativeBg: objectUrl,
                creatorClientId: clientId,
                consumerClientId: 1 // not needed here
            }
            console.log("test")

            const res3 = await createBgImage(submission, accessToken)
            console.log(res3)

            setPendingUpload(false)
            setShowImageModal(false)
            setLocalUploadFile(null)
            setLocalUploadFileUrl("")
            setCreativeBgs(bgs => [...bgs, res3.data.data as CreativeBg])
            setCreativeBgUrl((res3.data.data as CreativeBg).creativeBg)
        } catch (e) {
            console.error("ERROR UPLOADING FILE ", e)
            setPendingUpload(false)
        }
    }

    const clearUploadFile = () => {
        setLocalUploadFile(null)
        setLocalUploadFileUrl("")
        setShowImageModal(false)
    }

    const history = useHistory();

    const goToAd = () => {

        if (adForm._id) {
            clearAdForm()
            history.push(Links.ad.constructor(adForm._id))
        }
    }

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [resultAd, setResultAd] = useState<AdSchema>()

    const saveProgress = async () => {
        setPendingSubmission(true)
        try {
            setShowErrorModal(false)
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const update: Partial<Record<keyof AdSchema, any>> = {
                creativeBg: creativeBgUrl,
                transparent: noBackground,
            }

            if (!adForm._id) return
            const res = await updateAd(adForm._id, update, accessToken)
            console.log("SUCCESSFUL UPDATE ", res)
            setResultAd(res.data.data as AdSchema)
            setShowSuccessModal(true)
        } catch (e) {
            console.error("Error updating banner", e)
            setShowErrorModal(true)
        } finally {
            setPendingSubmission(false)
        }
    }

    const submitProgress = async () => {
        await updateBackground(creativeBgUrl, noBackground)
        history.push(nextStep)
    }

    const goBack = async () => {
        history.push(previousStep)
    }

    const clearBackground = async () => {
        setCreativeBgUrl("")
        setNoBackground(false)
        setButtonDisabled(true)
    }

    const getPlaceholdersCount = (bgImgs: CreativeBg[]) => {
        const total = 2
        if (!bgImgs || !bgImgs.length) return total
        if (total - bgImgs.length > 0) return total - bgImgs.length
        return 0
    }

    const [listOfUploadedFiles, setListOfUploadedFiles] = useState<CreativeBg[]>([])

    const [noBackground, setNoBackground] = useState<boolean>(false)

    useEffect(() => {

        const prepTemplates = async (files: CreativeBg[]) => {
            setLoadingUploads(true)
            const result = []
            for (const i of files) {
                const dims = await getHeightAndWidthFromDataUrl(i.creativeBg)
                if (dims.width == Number(adForm.width) && dims.height == Number(adForm.height)) {
                    result.push(i)
                }
            }
            setListOfUploadedFiles(result)
            setLoadingUploads(false)
            return
        }
        prepTemplates(creativeBgs)

    }, [adForm.height, adForm.width, creativeBgs])

    const hideContent = true;

    useEffect(() => {
        if (creativeBgUrl || noBackground) {
            setButtonDisabled(false)
        }
    }, [creativeBgUrl, noBackground])

    useEffect(() => { 
        if (adForm.transparent == true) {
            setNoBackground(true)
        }
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    }

    return (
        <AdFormPageWrapper>
             <div className="headerScroll">
                <AdFormHeader
                    currentStep={CURRENT_STEP}
                    totalSteps={AdForm.totalSteps}
                    title="Add your background image"
                    updateTitle="Update your background image"
                    subtitle="Apply a background image to your banner"
                    nextTitle="Adding your 3D model"
                    stepOneCheck={2}
                    stepTwoCheck={2}
                    stepThreeCheck={1}
                    updating={adForm.updating}
                    id={adForm._id}
                />
                <Progress currentStep={CURRENT_STEP} totalSteps={AdForm.totalSteps} updating={adForm.updating} />
            </div>
            <AdFormBody>
                <FlexWrappedPage>
                        <PreviewContainer>
                            <DivBox>
                                <p className="bannerPreviewTitle">Banner Preview</p>
                            </DivBox>
                            <ImageViewer
                                fileUrl={creativeBgUrl}
                                transparent={noBackground}
                                width={adForm.width}
                                height={adForm.height}
                                selectionScreen={true}
                                showClear={true}
                                clearBackground={clearBackground}
                                // model={{
                                //     width: adForm.modelWidth as number,
                                //     height: adForm.modelHeight as number,
                                //     xCoordinate: adForm.modelXCoordinate as number,
                                //     yCoordinate: adForm.modelYCoordinate as number,
                                //     details: adForm.model,
                                //     zoom: adForm.zooming,
                                //     autoRotate: adForm.autoRotate,
                                //     autoRotateSpeed: adForm.autoRotateSpeed,
                                //     autoRotateDirection: adForm.autoRotateDirection,
                                //     shadowIntensity: adForm.shadowIntensity,
                                //     shadowEdgeSoftness: adForm.shadowEdgeSoftness,
                                //     cameraControls: true,
                                //     cameraDistance: adForm.cameraDistance,
                                //     cameraVerticalAngle: adForm.cameraVerticalAngle,
                                //     cameraHorizontalAngle: adForm.cameraHorizontalAngle
                                // }}
                                showViewInArButton={adForm.viewInArButton}
                                viewInArButton={{
                                    xCoordinate: adForm.viewInArButtonXCoordinate,
                                    yCoordinate: adForm.viewInArButtonYCoordinate,
                                    type: adForm.customButton
                                }}
                            />
                            {/* <ExpandButton onClick={makeImageFullScreen}>
                                <div><img src={ExpandIcon} alt="expand" /></div>
                            </ExpandButton> */}
                        </PreviewContainer>
                    <ImageSelectionSection>
                        <ImageTilesSection>
                            <SectionTitleWrapper>
                            <div className="title-wrapper">
                                <h4>Uploaded Backgrounds</h4>
                                <Tooltip text="Only image files matching your selected banner dimensions will appear below." 
                                center={true}/>
                                </div>
                            </SectionTitleWrapper>
                            {loadingUploads && 
                                <div style={{  alignItems: 'center' }}>
                                    <CircularProgress color='primary' size="60px"/>
                                </div>}
                            <ImageTileRow>
                                {!loadingUploads &&
                                    <ImageTileButton onClick={() => setShowImageModal(true)} selected={false}>
                                        <img src={EmptyImageIcon} alt="empty" />
                                        <h3>Upload New Image</h3>
                                    </ImageTileButton>}
                                {!loadingUploads && listOfUploadedFiles.map((i: any) => (
                                    <ImageTileButton key={`bg-img-${i._id}`} onClick={() => selectBackgroundFile(i.creativeBg)} selected={creativeBgUrl == i.creativeBg ? true : false}>
                                        <img src={i.creativeBg} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                ))}
                                {!loadingUploads && [...Array(getPlaceholdersCount(listOfUploadedFiles))].map((_: any, idx: number) => (
                                    <ImageTileButton key={`placeholder-img-${idx}`} disabled={true} selected={false}>
                                    </ImageTileButton>
                                ))}
                            </ImageTileRow>
                        </ImageTilesSection>
                        <ImageTilesSection>
                            <SectionTitleWrapper>
                            <div className="title-wrapper">
                                <h4>Stock Backgrounds</h4>
                                <Tooltip text="Most stock backgrounds are only available for presets." 
                                center={true}/>
                                </div>
                            </SectionTitleWrapper>
                            <ImageTileRow>
                                <DivBox>
                                    <ImageTileButton onClick={() => {
                                        setNoBackground(true)
                                        setCreativeBgUrl("")
                                    }} selected={noBackground == true ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/transparentBackground.png'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto',
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Transparent Background</p>
                                </DivBox>
                                {adForm.width == 300 && adForm.height == 250 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen300x250.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen300x250.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen</p>
                                </DivBox> : adForm.width == 728 && adForm.height == 90 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen728x90.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen728x90.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen</p>
                                </DivBox> : adForm.width == 970 && adForm.height == 90 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen970x90.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen970x90.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen</p>
                                </DivBox> : adForm.width == 160 && adForm.height == 600 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen160x600.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen160x600.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen</p>
                                </DivBox> : adForm.width == 300 && adForm.height == 600 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen300x600.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen300x600.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen</p>
                                </DivBox> : adForm.width == 970 && adForm.height == 250 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen970x250.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen970x250.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchen300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen</p>
                                </DivBox> : null}
                                {adForm.width == 300 && adForm.height == 250 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter300x250.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter300x250.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen Counter</p>
                                </DivBox> : adForm.width == 728 && adForm.height == 90 ?  <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter728x90.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter728x90.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen Counter</p>
                                </DivBox> : adForm.width == 970 && adForm.height == 90 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter970x90.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter970x90.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen Counter</p>
                                </DivBox> : adForm.width == 160 && adForm.height == 600 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter160x600.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter160x600.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen Counter</p>
                                </DivBox> : adForm.width == 300 && adForm.height == 600 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter300x600.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter300x600.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                <p className="backgroundTitle">Empty Kitchen Counter</p>
                                </DivBox> : adForm.width == 970 && adForm.height == 250 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter970x250.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter970x250.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyKitchenCounter300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Kitchen Counter</p>
                                </DivBox> : null}
                                {adForm.width == 300 && adForm.height == 250 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace300x250.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace300x250.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Office Space</p>
                                </DivBox> : adForm.width == 728 && adForm.height == 90 ?   <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace728x90.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace728x90.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Office Space</p>
                                </DivBox> : adForm.width == 970 && adForm.height == 90 ?  <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace970x90.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace970x90.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Office Space</p>
                                </DivBox> : adForm.width == 160 && adForm.height == 600 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace160x600.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace160x600.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Office Space</p>
                                </DivBox> : adForm.width == 300 && adForm.height == 600 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace300x600.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace300x600.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Office Space</p>
                                </DivBox> : adForm.width == 970 && adForm.height == 250 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace970x250.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/EmptyOfficeSpace970x250.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/EmptyOfficeSpace300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Office Space</p>
                                </DivBox>: null}
                                {adForm.width == 300 && adForm.height == 250 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom300x250.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom300x250.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room</p>
                                </DivBox> : adForm.width == 728 && adForm.height == 90 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom728x90.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom728x90.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room</p>
                                </DivBox> : adForm.width == 970 && adForm.height == 90 ?  <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom970x90.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom970x90.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room</p>
                                </DivBox> : adForm.width == 160 && adForm.height == 600 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom160x600.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom160x600.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room</p>
                                </DivBox> : adForm.width == 300 && adForm.height == 600 ?  <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom300x600.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom300x600.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room</p>
                                </DivBox> : adForm.width == 970 && adForm.height == 250 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom970x250.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom970x250.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/Livingroom300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room</p>
                                </DivBox> : null}
                                {adForm.width == 300 && adForm.height == 250 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable300x250.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable300x250.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room Table</p>
                                </DivBox> : adForm.width == 728 && adForm.height == 90 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable728x90.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable728x90.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room Table</p>
                                </DivBox> : adForm.width == 970 && adForm.height == 90 ?  <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable970x90.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable970x90.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room Table</p>
                                </DivBox> : adForm.width == 160 && adForm.height == 600 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable160x600.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable160x600.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room Table</p>
                                </DivBox> : adForm.width == 300 && adForm.height == 600 ?  <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable300x600.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable300x600.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto'
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room Table</p>
                                </DivBox> : adForm.width == 970 && adForm.height == 250 ? <DivBox>
                                    <ImageTileButton onClick={() => selectBackgroundFile('https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable970x250.jpg')} selected={creativeBgUrl == 'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable970x250.jpg' ? true : false}>
                                        <img src={'https://assets.swirl.nextechar.com/templates/2022-01-25/LivingroomTable300x250.jpg'} alt="bg" style={{
                                            // width: '100%',
                                            // height: '100%'
                                            width: adForm.width < adForm.height ? '100%' : 'auto',
                                            height: adForm.height < adForm.width ? '100%' : 'auto',
                                        }} />
                                    </ImageTileButton>
                                    <p className="backgroundTitle">Empty Living Room Table</p>
                                </DivBox> : null}
                            </ImageTileRow>
                        </ImageTilesSection>
                        {!hideContent && templateCreativeBgs.hasOwnProperty(`${adForm.width}x${adForm.height}`) && <ImageTilesSection>
                            <SectionTitleWrapper>
                                <h4>Downloadable Templates</h4>
                                <Tooltip text="Not sure of what images to use on your banner? 
Use one of our templates to get started, or download and edit them to best suit your banner." />
                            </SectionTitleWrapper>
                            <ImageTileRow>
                                {templateCreativeBgs[`${adForm.width}x${adForm.height}`]?.map((t: { title: string, folderUrl: string, bgImageUrl: string }, idx: number) => (
                                    <ImageTileButton key={`template-${idx}`} as="a" href={t.folderUrl} selected={false} download>
                                        <img className="template-img" src={t.bgImageUrl} alt="template" />
                                    </ImageTileButton>
                                ))}

                            </ImageTileRow>
                        </ImageTilesSection>
                        }
                    </ImageSelectionSection>
                </FlexWrappedPage>
                <a onClick={scrollToTop} className="topPageLink">Top of Page</a>
            </AdFormBody>
            <div className="footerScroll">
                <AdFormFooter>
                    {!adForm.updating ? <StepButton
                        text="Back"
                        callback={goBack}
                        variant="back"
                    /> : <div></div>}
                    <StepButton
                        text={adForm.updating ? "Update" : "Continue"}
                        callback={adForm.updating ? saveProgress : submitProgress}
                        variant="primary"
                        disabled={buttonDisabled || pendingSubmission}
                    />
                </AdFormFooter>
            </div>
            {showImageModal &&
                <ImageModal
                    callback={submitUploadFile}
                    handleClose={clearUploadFile}
                    disabled={disabled || pendingUpload}
                >
                    {pendingUpload ? 
                        <Container
                        style={{
                            overflow: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        width={adForm.width}
                        height={adForm.height}
                        >
                            <div style={{  alignItems: 'center', zIndex: 10 }}>
                                <CircularProgress color='primary' size="60px"/>
                            </div>
                        </Container> :
                        <ValidatedFileInput
                            id="fileInput"
                            name="background"
                            placeholder="Browse Files"
                            width={adForm.width}
                            height={adForm.height}
                            validations={[required("Background image")]}
                            onValidChange={handleValidChange}
                            file={localUploadFile}
                            fileUrl={localUploadFileUrl}
                            sendFile={receiveUploadFile}
                            acceptedFileTypes={["image/jpg", "image/jpeg", "image/png"]}
                        />}
                </ImageModal>
            }
            {showSuccessModal
                && <Modal
                    title="Success! You've updated your banner."
                    text={`You can access ${resultAd?.adName} in My Banners.`}
                    callback={goToAd}
                    callbackText={"Go to My Banner"}
                    handleClose={goToAd}
                    ad={resultAd}
                />}
            {showErrorModal && <Modal
                title="Sorry! Something went wrong."
                text={`Something has gone wrong along the way, and your banner hasn’t been updated. Please try again.`}
                callback={saveProgress}
                callbackText={"Try Again"}
                handleClose={() => setShowErrorModal(false)}
                showGoBack={true}
            />}
        </AdFormPageWrapper>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        adForm: state.adForm
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        updateBackground: async (background: string, transparent: boolean) => dispatch(setAdBackground(background, transparent)),
        clearAdForm: async () => dispatch(makeAction(Actions.CLEAR_AD_FORM)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdFormBackground)