import { ReactElement } from "react";
import { faqs } from "Data/faqs";
import { FAQ } from "@declarations";
import { FAQList, Container } from "./faqs.styles";
import PricingFAQ from "./faq.component";
import { SectionTitle } from "Styles/library/titles";

const Faqs = (): ReactElement => {

    return (
        <Container>
            <SectionTitle>
                <h1>Frequently Asked Questions
                </h1>
            </SectionTitle>
            <FAQList>
                {faqs.map((faq: FAQ, index: number) => (
                    <PricingFAQ faq={faq} key={`faq-${index}`} />
                ))}
            </FAQList>
        </Container>
    );
};

export default Faqs
