import styled from "styled-components";
import { Box } from "Styles/library/box";

export const ReviewSection = styled.div`
    display: grid;
    grid-template-rows: repeat(auto);
    grid-template-columns: 1fr;
    grid-gap: 24px;
`

export const ReviewBox = styled(Box)`
    padding: 32px 40px;
    overflow: auto;
    width: 100%;

    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
    }
`

export const ReviewRow = styled.div`
    display: grid;
    grid-template-columns: 170px 1fr auto;
    grid-template-rows: 1fr;
    grid-gap: 32px;
    align-items: center;
    padding: 24px 26px;

    h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px !important;
        line-height: 26px !important;
        /* identical to box height, or 144% */

        text-transform: uppercase;

        /* Text Colours/Headline 2 */

        color: #717171 !important;
    }

    h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        /* identical to box height, or 144% */


        /* Text Colours/ Headline */

        color: #030E31;
    }

    Link {
        float: right;
    }

    .editLink {
        display: flex;
        justify-content: space-between;
        margin-right: -30px;
    }
`
export const PreviewRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #F2F2F3;
    border-radius: 8px;
    padding: 32px;

    img{
        width: 100%;
        object-fit: contain;
    }
`
