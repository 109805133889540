import { cleanDuration } from "Utils/time"
import { Box } from "./summary-box.styles"

type Props = {
    icon: string
    text: string
    value: number
    selection?: string
    sendSelection?: (s: string) => void
}

const SummaryBox = (props: Props) => {

    const { icon, text, value, selection, sendSelection } = props

    return (
        <Box selected={selection === text} onClick={() => { if (sendSelection) sendSelection(text) }}>
            <img src={icon} alt="icon" />
            <p>{text}</p>
            <h3>{value
                ? text === "CTR" || text.toLowerCase().includes("rate")
                    ? `${value.toFixed(2)}%`
                    : text.toLowerCase().includes("duration")
                        ? cleanDuration(value)
                        : value
                : "--"
            }</h3>
        </Box>
    )
}

export default SummaryBox