import React, { ReactElement } from "react"
import { Button } from "Styles/library/button"
import { BottomActions, ModalBox, ModalWrapper } from "Styles/library/modal"

type Props = {
    callback: () => void
    handleClose: () => void
    disabled?: boolean
    children: ReactElement | ReactElement[]
}

const ImageModal = (props: Props) => {

    const { callback, handleClose, disabled = false, children } = props

    const handleOutsideClose = (e: React.MouseEvent<HTMLElement>) => {
        const id = e.currentTarget.id
        if (id === "modal-wrapper") {
            handleClose()
        }
    }

    return (
        <ModalWrapper id="modal-wrapper" onClick={handleOutsideClose}>
            <ModalBox onClick={e => e.stopPropagation()}>
                {children}
                <BottomActions>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="primary" onClick={callback} disabled={disabled}>Confirm</Button>
                </BottomActions>
            </ModalBox>
        </ModalWrapper>
    )
}

export default ImageModal