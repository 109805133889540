import { Tile, TileBanner, TileImage } from "./menu-tile.styles"

type Props = {
    imageSrc: string
    text: string
    link: string
}

const MenuTile = (props: Props) => {

    const { imageSrc, text, link } = props

    return (
        <Tile to={link}>
            <TileImage src={imageSrc} alt="tile" />
            <TileBanner>
                {text}
            </TileBanner>
        </Tile>
    )
}

export default MenuTile