import React, { useEffect, useState } from 'react';
import {
    Form
} from '@declarations';

// Field Input Validations Functions
const isValidEmail = (value: string | number): boolean => ((/\S+@\S+\.\S+/).test(value as string));
const isValidPassword = (value: string | number): boolean => (value as string).length >= 8 && (value as string).length <= 64;
const isValidLink = (value: string | number) => (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm).test(value as string)
const isRequired = (value: string | number): boolean => (value as string).trim().length > 0;
const setMinLength = (min: number) => (value: string | number): boolean => (value as string).trim().length >= min;
const setMaxLength = (max: number) => (value: string | number): boolean => (value as string).trim().length <= max;
const setMinMaxLength = (min: number, max: number) => (value: string | number): boolean => min <= (value as string).trim().length && (value as string).trim().length <= max;
const setMinValue = (min: number) => (value: string | number): boolean => value as number >= min

// Field Input Validations
export const email: Form.Validation = {
    func: isValidEmail,
    message: "Please enter a valid email."
}

export const password: Form.Validation = {
    func: isValidPassword,
    message: "Password must be between 8 and 64 characters long.",
}

export const link: Form.Validation = {
    func: isValidLink,
    message: "Please enter a valid link."
}

export const required = (fieldName?: string): Form.Validation => {
    return {
        func: isRequired,
        message: `${fieldName || 'This field'} is required.`
    }
}

export const minLength = (min: number, fieldName?: string): Form.Validation => {
    return {
        func: setMinLength(min),
        message: `${fieldName || 'This field'} must be at least ${min} characters long.`
    }
}

export const maxLength = (max: number, fieldName?: string): Form.Validation => {
    return {
        func: setMaxLength(max),
        message: `${fieldName || 'This field'} must be less than ${max} characters long.`
    }
}

export const minMaxLength = (min: number, max: number, fieldName?: string): Form.Validation => {
    return {
        func: setMinMaxLength(min, max),
        message: `${fieldName || 'This field'} must be between ${min} and ${max} characters long.`
    }
}

export const minValue = (min: number, fieldName?: string): Form.Validation => {
    return {
        func: setMinValue(min),
        message: `${fieldName || 'This field'} must be have a value of at least ${min}.`
    }
}

export const handleInputChange = (data: Form.Input,
    setUserData: React.Dispatch<React.SetStateAction<Form.Input>>,
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>  //| React.DragEvent<HTMLDivElement>
): void => {

    const { validations } = data;
    const value = e.target.value;
    const errors: string[] = [];

    if (validations) {
        for (const v of validations) {
            const valid = v.func(value);
            if (!valid) errors.push(v.message);
        }
    }

    setUserData((data: Form.Input) => ({
        ...data,
        value: e.target.value,
        errors: errors
    }))

    return;
};



export const validateInput = (data: Form.Input): boolean => {
    let valid = true;

    if ((data.validations?.length && !data.value) || data.errors.length || (data.matchErrors && data.matchErrors.length)) {
        valid = false;
    }

    return valid;
}

export const validateMatch = (requiredMatch: string,
    value: string,
    errorMessage: string,
    setUserData: React.Dispatch<React.SetStateAction<Form.Input>>,
): boolean => {
    const valid = requiredMatch === value;

    const errors: string[] = [];
    if (!valid) errors.push(errorMessage);

    setUserData((data: Form.Input) => ({
        ...data,
        matchErrors: errors
    }))

    return valid;
}

export const validateUnique = (existingValues: string[],
    value: string,
    errorMessage: string,
    setUserData: React.Dispatch<React.SetStateAction<Form.Input>>,
): boolean => {
    const valid = !existingValues.includes(value)

    const errors: string[] = [];
    if (!valid) errors.push(errorMessage);

    setUserData((data: Form.Input) => ({
        ...data,
        matchErrors: errors
    }))

    return valid;
}


export const FormValidator = (callback: React.Dispatch<React.SetStateAction<boolean>>): ((valid: boolean) => void) => {
    const [validations, setValidations] = useState<number>(0);

    useEffect(() => {
        if (validations === 0) callback(false);
        else callback(true);
    }, [callback, validations])

    const handleValidChange = (valid: boolean) => {
        if (!valid) setValidations((validations: number) => validations + 1)
        else if (validations > 0) setValidations((validations: number) => validations - 1)

    }

    return handleValidChange;
}
