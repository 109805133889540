import PageLayout from "Components/PageLayout/page-layout.component"
import { Link } from "react-router-dom"
import { Banner, BannerText, BannerBackWrapper } from "Styles/library/banner"
import { Links } from "Values/Constants"
import BackArrow from 'Assets/images/arrows/chevron-left-white.svg'


const PageNotFound = () => {

    return (
        <PageLayout>
            <Banner>
                <BannerText>
                    <Link to={Links.home}>
                        <BannerBackWrapper>
                            <img src={BackArrow} alt="back" />
                            <h6 >Back Home</h6>
                        </BannerBackWrapper>
                    </Link>

                    <h1>Page Not Found</h1>
                </BannerText>
            </Banner>
        </PageLayout>
    )
}

export default PageNotFound