import styled from "styled-components";

export const PhoneCanvas = styled.div`
    
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    img {
        // max-height: 90vh;
        height: 600px;
    }

    // img.bg {
    //     margin-top: -20rem;
    // }
    height: 90vh;
    overflow-y: auto;
`

export const Banner = styled.div`
    // position: absolute;
    // bottom: 4rem;
    background: #00000080;
    backdrop-filter: blur(4px);
    width: 15rem;
    // height: 3rem;
    margin: 0 2rem;
    border-radius: 6px;
    color: white;
    margin-top: -8rem;

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas:
        "title cta"
        "subtitle cta";
    grid-gap: 4px;
    padding: 8px;

    h5 {
        font-size: 12px;
        font-weight: bold;
        margin: 0;
        grid-area: title;
    }

    p {
        font-size: 11px;
        margin: 0;
        grid-area: subtitle;
        text-align: left;
    }

    button {
        background: #ffffff80;
        color: #00000090;
        border-radius: 6px;
        padding: 8px;
        grid-area: cta;
        font-size: 11px;
    }

`