import { Canvas, DeleteButton, Img, PlaceholderWrapper, ViewInArButton, ViewInArButtonWrapper, Wrapper, } from "./image-viewer.styles"
import TrashIcon from 'Assets/images/forms/trash.svg'
import ViewInArIcon from 'Assets/images/logos/view-in-ar-button.png'
import '@google/model-viewer'
import { CustomArButton, Model } from "@declarations"
import ImagePlaceholder from 'Assets/images/forms/image-placeholder.svg'
import { PREVIEW_IMAGE_ID } from "Utils/preview-image"
import { Rnd } from "react-rnd"
import "./image-viewer.css"
import { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { uploadImage, getUploadUrl } from "Api/Api/blobImages"
import { URLs } from "Values/Constants"


type Props = {
    transparent?: boolean
    captureBlob?: boolean
    fileUrl: string
    width: number
    height: number
    selectionScreen?: boolean
    clearFile?: () => void
    clearBackground?: () => void
    withInput?: boolean
    showClear?: boolean
    model?: {
        width: number
        height: number
        xCoordinate: number
        yCoordinate: number
        details: Model,
        zoom: boolean,
        autoRotate: boolean,
        autoRotateSpeed?: number,
        autoRotateDirection?: "left" | "right",
        shadowIntensity?: number,
        shadowEdgeSoftness?: number,
        cameraControls: boolean,
        cameraDistance?: number,
        cameraVerticalAngle?: number,
        cameraHorizontalAngle?: number,
        dims?: { width: number, height: number }
        sendModelDims?: (width: number, height: number) => void
        pos?: { x: number, y: number }
        sendModelPos?: (x: number, y: number) => void
    }
    showViewInArButton?: boolean
    viewInArButton?: {
        xCoordinate: number
        yCoordinate: number,
        type: CustomArButton
        label?: string
        dims?: { width: number, height: number }
        sendArDims?: (width: number, height: number) => void
        pos?: { x: number, y: number }
        sendArPos?: (x: number, y: number) => void
    }
}

const ImageViewer = forwardRef((props: Props, ref: any) => {
    const modelRef = useRef<any>(null);
    const [localUploadFile, setLocalUploadFile] = useState<File | null>(null)

    const { transparent, fileUrl, width, height, selectionScreen, clearBackground, clearFile, withInput, showClear, model, showViewInArButton, viewInArButton, captureBlob } = props

    useEffect(() => {
        async function capture() {
            // console.log("modelRef.current=", modelRef.current)
            let dataURL = await modelRef?.current?.toBlob({ idealAspect: true })
            if (dataURL) {
                setLocalUploadFile(dataURL)
            }
        }
        if (captureBlob) {
            capture()
        }
    }, [captureBlob])

    useImperativeHandle(ref, () => ({
        async submitBlobFile(accessToken: any) {
            // console.log("localuploadfile=", localUploadFile)
            try {
                if (localUploadFile) {
                    const res = await getUploadUrl(accessToken, localUploadFile.type)
                    // const res = await getUploadUrl(accessToken, "img/png") //janet to test error cases
                    const url = res.data.data.presignedURL
                    const key = res.data.data.Key
                    console.log(url, key)
                    const res2 = await uploadImage(url, localUploadFile)
                    if (res2.status !== 200) throw new Error(res.statusText)
                    const objectUrl = URLs.s3Bucket + "captureblob/" + key
                    return objectUrl
                } else {
                    throw new Error("Cannot find Local Captured Blob")
                }
            } catch (e) {
                console.error("Error uploading static blob", e)
                throw e;
            }

        }
    }))

    const progressBar = useRef<any>(null)
    const fadedRef = useRef<any>(null)

    const progressEvent = (event : any) => {
        console.log(progressBar)
        if (progressBar && progressBar.current) {
            console.log(progressBar.current)
            progressBar.current.style.width = (event as CustomEvent).detail.totalProgress*100 + "%"
        }
    }

    const loadEvent = () => {
        console.log(fadedRef)
        if (fadedRef && fadedRef.current) {
            fadedRef.current.classList.add("fadeout");
            if (fadedRef.current.classList.contains("fadein")) {
                fadedRef.current.classList.remove("fadein")
            }
        }
    }
    
    useEffect(() => {
        if (modelRef && modelRef.current) {
            console.log(modelRef.current)

            modelRef.current.addEventListener('load', loadEvent)
            console.log(fadedRef)

            modelRef.current.addEventListener('progress', progressEvent)
            console.log(progressBar)
        }
    }, [loadEvent, progressEvent])


    const renderModelViewer = (model: any, viewInArButton: any) => {

        const attributes: Record<string, any> = {}

        if (!model.zoom) {
            attributes["disable-zoom"] = true
        }
        if (model.autoRotate) {
            attributes["auto-rotate"] = true
        }
        if (model.autoRotateSpeed && model.autoRotateDirection) {
            if (model.autoRotateDirection === "left") {
                attributes["rotation-per-second"] = `-${model.autoRotateSpeed}deg`
            } else {
                attributes["rotation-per-second"] = `${model.autoRotateSpeed}deg`
            }
        }
        if (model.shadowIntensity) {
            attributes["shadow-intensity"] = model.shadowIntensity
        }
        if (model.shadowEdgeSoftness) {
            attributes["shadow-softnesss"] = model.shadowEdgeSoftness
        }
        if (model.cameraDistance !== "undefined" && model.cameraVerticalAngle !== "undefined" && model.cameraHorizontalAngle !== "undefined") {
            attributes["camera-orbit"] = `${model.cameraHorizontalAngle}deg ${model.cameraVerticalAngle}deg ${model.cameraDistance}%`
        }

        if (!model.cameraControls && (model.dims && model.pos)) {
            return <Rnd
                size={{ width: model?.dims?.width, height: model.dims?.height }}
                position={{ x: model?.pos?.x, y: model?.pos?.y }}
                onDragStop={(e, d) => { if (model.sendModelPos) model.sendModelPos(d.x, d.y) }}
                onResize={(e, direction, ref, delta) => {
                    if (model.sendModelDims) model.sendModelDims(ref.offsetWidth, ref.offsetHeight);
                }}
                className="rnd"
            >
                <model-viewer
                    src={model?.details.models?.glb?.url}
                    style={{
                        width: `${model?.dims?.width}px`,
                        height: `${model?.dims?.height}px`,
                        pointerEvents: 'none',
                    }}
                    poster={""}
                    disable-zoom
                    loading="eager"
                    alt="3d model"
                    draggable={false}
                    ref={modelRef}
                >
               
                    {showViewInArButton && viewInArButton &&
                        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                            <ViewInArButton
                                xCoordinate={viewInArButton?.xCoordinate}
                                yCoordinate={viewInArButton?.yCoordinate}
                                style={{ position: 'absolute' }}
                            >
                                {viewInArButton?.type === "image"
                                    ? <img src={ViewInArIcon} alt="logo" draggable={false} />
                                    : <p>{viewInArButton?.label}</p>
                                }
                            </ViewInArButton>
                        </div>
                    }

                    <div slot="progress-bar" id="progress-bar-background" title="loading" className="fadein" ref={fadedRef}>
                        <div id="progress-bar-a3d">
                            <div id="progress-bar-a3d-mask" ref={progressBar}></div>
                        </div>
                    </div>
                </model-viewer>
            </Rnd>
        } else {
            return <model-viewer
                src={model?.details.models?.glb?.url}
                style={{
                    position: "absolute",
                    width: `${model?.width}px`,
                    height: `${model?.height}px`,
                    left: `${model?.xCoordinate}px`,
                    top: `${model?.yCoordinate}px`,
                }}
                poster={""}
                {...attributes}
                camera-controls
                loading="eager"
                alt="3d model"
                ref={modelRef}
                id="model-viewer"
            >
           
                {showViewInArButton && viewInArButton &&
                    <ViewInArButtonWrapper pointerEvents={viewInArButton.pos ? true : false}>
                        {viewInArButton.pos
                            ? <Rnd
                                // size={{ width: viewInArButton?.dims?.width, height: viewInArButton.dims?.height }}
                                size={{ width: '100px', height: '35px' }}
                                position={{ x: viewInArButton?.pos?.x, y: viewInArButton?.pos?.y }}
                                onDragStop={(e, d) => { if (viewInArButton.sendArPos) viewInArButton.sendArPos(d.x, d.y) }}
                                // onResize={(e, direction, ref, delta) => {
                                //     if (viewInArButton.sendArDims) viewInArButton.sendArDims(ref.offsetWidth, ref.offsetHeight);
                                // }}
                                className="rnd"
                            >
                                <ViewInArButton
                                    xCoordinate={0}
                                    yCoordinate={0}
                                    style={{
                                        position: 'initial',
                                        cursor: 'move'
                                    }}
                                // width={viewInArButton.dims.width}
                                >
                                    {viewInArButton.type === "image"
                                        ? <img src={ViewInArIcon} alt="logo" draggable={false} />
                                        : <p style={{ "marginBottom": 0 }}>{viewInArButton.label}</p>
                                    }
                                </ViewInArButton>
                            </Rnd>
                            :
                            <ViewInArButton
                                xCoordinate={viewInArButton?.xCoordinate}
                                yCoordinate={viewInArButton?.yCoordinate}
                                style={{ position: 'absolute' }}
                            >
                                {viewInArButton?.type === "image"
                                    ? <img src={ViewInArIcon} alt="logo" draggable={false} />
                                    : <p>{viewInArButton?.label}</p>
                                }
                            </ViewInArButton>
                        }
                    </ViewInArButtonWrapper>
                }

                    <div slot="progress-bar" id="progress-bar-background" title="loading" className="fadein" ref={fadedRef}>
                        <div id="progress-bar-a3d">
                            <div id="progress-bar-a3d-mask" ref={progressBar}></div>
                        </div>
                    </div>
            </model-viewer>
        }
    }

    const showText = (width: number, height: number) => {
        let show = true
        if (height <= 220 && width <= 220) {
            show = false
        }
        return show
    }

    return (
        <div>
            <Wrapper width={width} height={height} withInput={withInput} selectionScreen={selectionScreen} fileUrl={fileUrl}>
                <Canvas className={transparent ? "preview-img" : ""} id={PREVIEW_IMAGE_ID} >
                    {transparent ? null : fileUrl !== ""
                            ? <Img src={fileUrl} alt="preview" width={width} height={height} draggable={false} />
                            : selectionScreen
                                ? <PlaceholderWrapper>
                                    <img src={ImagePlaceholder} alt="drop-backgroud" draggable={false}
                                        style={height < width ? {
                                            height: '50%',
                                            maxHeight: '75px',
                                            width: 'auto',
                                        }
                                            : {
                                                width: '50%',
                                                maxWidth: '75px',
                                                height: 'auto',
                                            }} />
                                    {showText(width, height) && <p draggable={false}>Select a background to preview it here</p>}
                                </PlaceholderWrapper>
                                : null
                    }
                    {(((fileUrl || transparent) && showClear) || withInput) &&
                        <DeleteButton onClick={(showClear && !withInput) ? clearBackground : clearFile}>
                            <img src={TrashIcon} alt="delete" />
                        </DeleteButton>
                    }
                    {model?.details?.job_id ? renderModelViewer(model, viewInArButton) : null}
                    {/* {showViewInArButton && viewInArButton
                        ? viewInArButton.pos //&& viewInArButton.dims
                            ?
                            null
                            // <Rnd
                            //     // size={{ width: viewInArButton?.dims?.width, height: viewInArButton.dims?.height }}
                            //     size={{ width: '100px', height: '35px' }}
                            //     position={{ x: viewInArButton?.pos?.x, y: viewInArButton?.pos?.y }}
                            //     onDragStop={(e, d) => { if (viewInArButton.sendArPos) viewInArButton.sendArPos(d.x, d.y) }}
                            //     // onResize={(e, direction, ref, delta) => {
                            //     //     if (viewInArButton.sendArDims) viewInArButton.sendArDims(ref.offsetWidth, ref.offsetHeight);
                            //     // }}
                            //     className="rnd"
                            // >
                            //     <ViewInArButton
                            //         xCoordinate={0}
                            //         yCoordinate={0}
                            //         style={{
                            //             position: 'initial'
                            //         }}
                            //     // width={viewInArButton.dims.width}
                            //     >
                            //         {viewInArButton.type === "image"
                            //             ? <img src={ViewInArIcon} alt="logo" draggable={false} />
                            //             : <p style={{ "marginBottom": 0 }}>{viewInArButton.label}</p>
                            //         }
                            //     </ViewInArButton>
                            // </Rnd>
                            : null
                        // : <ViewInArButton
                        //     xCoordinate={viewInArButton?.xCoordinate}
                        //     yCoordinate={viewInArButton?.yCoordinate}
                        // >
                        //     {viewInArButton.type === "image"
                        //         ? <img src={ViewInArIcon} alt="logo" draggable={false} />
                        //         : <p>{viewInArButton.label}</p>
                        //     }
                        // </ViewInArButton>
                        : null 
                    */}
                </Canvas >
            </Wrapper >
        </div>
    )
})

export default ImageViewer