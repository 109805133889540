import { ToggleBox, ToggleContainer, ToggleTab, ToggleTitle } from "./toggle.styles"

type Props = {
    on: boolean
    sendToggle: () => void
    onText: string
    offText: string
}

const Toggle = (props: Props) => {

    const { on, sendToggle, onText, offText } = props

    return (
        <ToggleContainer>
            <ToggleTitle on={on ? true : false}>{on ? onText : offText}</ToggleTitle>
            <ToggleBox on={on ? true : false} onClick={sendToggle}>
                <ToggleTab />
            </ToggleBox>
        </ToggleContainer>
    )

}

export default Toggle