
import React, { useCallback, useMemo } from 'react';
import { Group } from '@visx/group';
import { AreaClosed, Line, Bar } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { GridRows, GridColumns } from '@visx/grid';
import { Analytics } from '@declarations';
import { bisector } from 'd3-array';
import "./TimelineChart.css"
import { LinearGradient } from '@visx/gradient';
import { localPoint } from '@visx/event';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { formatDateMonthDate } from 'Utils/dates';

const background = '#ffffff';
const accentColor = "#0038A8";
const accentColor2 = "#BDBEC3";
const accentColorDark = "#353E5A";
const axisColor = 'transparent';
const tickLabelColor = '#858993';
const tickLabelProps = () =>
({
    fill: tickLabelColor,
    fontSize: 12,
    fontFamily: 'Poppins',
    textAnchor: 'middle',
} as const);

type TooltipData = Analytics.TimelineSlice[]

const tooltipStyles = {
    ...defaultStyles,
    background,
    border: '1px solid white',
    color: accentColorDark,
    display: 'flex',
    boxShadow: "0px 2px 16px rgba(40, 41, 61, 0.12), -2px 20px 32px rgba(96, 97, 112, 0.24)"
};

const margin = { top: 40, right: 30, bottom: 70, left: 58 };

export type AreaProps = {
    width: number;
    height: number;
    startDate: number
    endDate: number
    data: Analytics.TimelineSlice[]
    data2?: Analytics.TimelineSlice[]
    dataFormat?: "number" | "percent"
    legendTitle: string
    legendTitle2?: string
};

export default withTooltip<AreaProps, TooltipData>(
    ({
        width,
        height,
        startDate,
        endDate,
        data,
        data2 = [],
        dataFormat = "number",
        legendTitle,
        legendTitle2 = "",
        showTooltip,
        hideTooltip,
        tooltipData,
        tooltipTop = 0,
        tooltipLeft = 0,
    }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {

        // bounds
        const xMax = width// - margin.left - margin.right;
        const yMax = height - margin.top - margin.bottom;

        // accessors
        const getDate = (d: Analytics.TimelineSlice) => new Date(d?.instance) || 0
        const getCount = (d: Analytics.TimelineSlice) => d?.count || 0
        const bisectDate = bisector<Analytics.TimelineSlice, Date>(d => new Date(d?.instance)).left || 0

        // scales
        const getMaxY = (data: Analytics.TimelineSlice[]): number => {
            let max = -Infinity
            for (const d of data) {
                if (d.count > max) max = d.count
            }
            return max
        }

        const getMinY = (data: Analytics.TimelineSlice[]) => {
            let min = Infinity
            for (const d of data) {
                if (d.count < min) min = d.count
            }
            return min
        }

        const xScale = useMemo(() =>
            scaleTime<number>({
                domain: [startDate, endDate],
                range: [0, xMax]
            }),
            [endDate, startDate, xMax]
        )

        const yScale = useMemo(() =>
            scaleLinear<number>({
                domain: [
                    Math.min(getMinY(data), getMinY(data2)),
                    Math.max(getMaxY(data), getMaxY(data2))
                ],
                range: [yMax, 0],
                nice: true,
            }),
            [data, data2, yMax],
        )
        const xTickFormatter: any = (n: number) => {
            return formatDateMonthDate(n)
        }
        const yTickFormatter: any = (n: number) => {
            switch (dataFormat) {
                case "number":
                    return n
                case "percent":
                    return `${n}%`
                default:
                    return n
            }
        }
        // tooltip handler
        const handleTooltip = useCallback(
            (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
                const { x } = localPoint(event) || { x: 0 };
                const x0 = xScale.invert(x - margin.left)
                const index = bisectDate(data, x0, 0);

                // data 1
                const d0 = data[index - 1];
                const d1 = data[index];

                let d = d0;
                if (d1 && getDate(d1)) {
                    d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
                }

                // data 2 
                const d02 = data2[index - 1]
                const d12 = data2[index]

                let d2 = d02;

                if (d12 && getDate(d12)) {
                    d2 = x0.valueOf() - getDate(d02).valueOf() > getDate(d12).valueOf() - x0.valueOf() ? d12 : d02;
                }

                showTooltip({
                    tooltipData: d2 ? [d, d2] : [d],
                    tooltipLeft: x - margin.left,
                    tooltipTop: d2 && (d2.count > d.count) ? yScale(getCount(d2)) : yScale(getCount(d)),
                });
            },
            [xScale, bisectDate, data, data2, showTooltip, yScale],
        );

        if (width < 10) return null;

        return (
            <div className="timeline-chart-container">
                <svg width={width} height={height} >
                    <rect x={0} y={0} width={width} height={height} fill={background} rx={14} style={{ display: 'flex', justifyContent: 'flex-end' }} />
                    <Group left={margin.left} top={margin.top}>
                        <GridRows scale={yScale} width={xMax} height={yMax} stroke="#e0e0e0" />
                        <GridColumns scale={xScale} width={xMax} height={yMax} stroke="#e0e0e0" />
                        <line x1={xMax} x2={xMax} y1={0} y2={yMax} stroke="#e0e0e0" />
                        <AxisBottom
                            top={yMax}
                            scale={xScale}
                            numTicks={width > 1000 ? 15 : 7}
                            tickFormat={xTickFormatter}
                            stroke={axisColor}
                            tickStroke={axisColor}
                            tickLabelProps={tickLabelProps}
                        />
                        <AxisLeft
                            scale={yScale}
                            // top={yMax}
                            left={margin.left - 80}
                            numTicks={width > 520 ? 15 : 7}
                            tickFormat={yTickFormatter}
                            stroke={axisColor}
                            tickStroke={axisColor}
                            tickLabelProps={tickLabelProps}
                        />
                        <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.1} />
                        <LinearGradient id="area-gradient-2" from={accentColor2} to={accentColor2} toOpacity={0.1} />
                        <AreaClosed<Analytics.TimelineSlice>
                            data={data}
                            x={d => xScale(getDate(d)) ?? 0}
                            y={d => yScale(getCount(d)) ?? 0}
                            yScale={yScale}
                            strokeWidth={2}
                            stroke="url(#area-gradient)"
                            fill="url(#area-gradient)"
                            curve={curveMonotoneX}
                        />
                        <AreaClosed<Analytics.TimelineSlice>
                            data={data2}
                            x={d => xScale(getDate(d)) ?? 0}
                            y={d => yScale(getCount(d)) ?? 0}
                            yScale={yScale}
                            strokeWidth={2}
                            stroke="url(#area-gradient-2)"
                            fill="url(#area-gradient-2)"
                            curve={curveMonotoneX}
                        />
                        <Bar
                            x={0}
                            y={margin.top}
                            width={xMax}
                            height={yMax}
                            fill="transparent"
                            rx={14}
                            onTouchStart={handleTooltip}
                            onTouchMove={handleTooltip}
                            onMouseMove={handleTooltip}
                            onMouseLeave={() => hideTooltip()}
                        />
                        {tooltipData && (
                            <g>
                                <Line
                                    from={{ x: tooltipLeft, y: 0 }}
                                    to={{ x: tooltipLeft, y: yMax }}
                                    stroke={accentColorDark}
                                    strokeWidth={1}
                                    pointerEvents="none"
                                    strokeDasharray="5,2"
                                />
                                <circle
                                    cx={tooltipLeft}
                                    cy={tooltipTop + 1}
                                    r={4}
                                    fill="black"
                                    fillOpacity={0.1}
                                    stroke="black"
                                    strokeOpacity={0.1}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                />
                                <circle
                                    cx={tooltipLeft}
                                    cy={tooltipTop}
                                    r={4}
                                    fill={"white"}
                                    stroke={accentColor}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                />
                            </g>
                        )}
                    </Group>
                </svg>
                {legendTitle &&
                    <div className="timeline-chart-legend">
                        <div className="dot blue-dot"></div>
                        <p>{legendTitle}</p>
                        {legendTitle2 &&
                            <div style={{ marginLeft: "48px", display: "flex", alignItems: "center" }}>
                                <div className="dot grey-dot"></div>
                                <p>{legendTitle2}</p>
                            </div>
                        }
                    </div>
                }
                {tooltipData && (
                    <div>
                        <Tooltip
                            key={Math.random()}
                            top={tooltipTop - 18}
                            left={tooltipLeft - 3}
                            style={tooltipStyles}
                            className="my-tooltip-class"
                        >
                            {tooltipData && (
                                <div className="tooltip-data-wrapper">
                                    <div className="tooltip-data">
                                        <div className="dot blue-dot"></div>
                                        <p>{yTickFormatter(getCount(tooltipData[0]))}</p>
                                    </div>
                                    {data2 && data2.length
                                        ? <div className="tooltip-data" style={{ marginLeft: "20px" }}>
                                            <div className="dot grey-dot"></div>
                                            <p>{yTickFormatter(getCount(tooltipData[1]))}</p>
                                        </div>
                                        : null}
                                </div>
                            )
                            }
                        </Tooltip>
                    </div>
                )}
            </div >
        );
    }
)