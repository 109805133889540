import { ReviewRow } from "Components/AdForm/Shared/review-form.styles"
import { SideFormRow } from "Components/AdForm/Shared/side-form.styles"
import ImageViewer from "Components/ImageViewer/image-viewer.component"
import { PreviewContainer } from "Components/ImageViewer/image-viewer.styles"
import { ContentBody } from "Components/PageLayout/page-layout.styles"
import { Button } from "Styles/library/button"
import { Container, Details, Preview, Settings } from "./ad-overview.styles"
import { AdSchema, AuthToken } from "@declarations"
import { useEffect, useState } from "react"
import Modal from "Components/Modals/Modal/modal.component"
import { useAuth0 } from "@auth0/auth0-react"
import { URLs } from "Values/Constants"
import { updateAd } from "Api/Api/ads"
import { searchFolders, FolderSubmission, createFolder } from "Api/Api/folder"
import jwtDecode from "jwt-decode"

type Props = {
    ad?: AdSchema 
}

const AdOverview = (props: Props) => {

    const { ad } = props

    const { getAccessTokenSilently } = useAuth0();

    const [showArchiveModal, setArchiveModal] = useState<boolean>(false)

    const [alreadyArchived, setAlreadyArchived] = useState<boolean>(false)

    useEffect(() => {
        if (ad?.folderPath?.startsWith(",Archives,")) {
            setAlreadyArchived(true)
        } else {
            setAlreadyArchived(false)
        }
    }, [ad])

    const archiveAd = async () => {

        try {
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const tkn: AuthToken = jwtDecode(accessToken)
            console.log("TKN", tkn)
            const creatorClientId = tkn["https://thdy/app_md"].client_id || null

            // check if Archives folder exists or create

            const existsRes = await searchFolders('Archives', null, creatorClientId, accessToken)
            console.log("ARCHIVES EXISTS RES ", existsRes)

            if (!existsRes.data?.length) {
                // create folder

                const tkn: AuthToken = jwtDecode(accessToken)
                console.log("TKN", tkn)
                const clientId = tkn["https://thdy/app_md"].client_id || null

                const submission: FolderSubmission = {
                    _id: {
                        name: 'Archives',
                        path: null,
                        creatorClientId: clientId
                    },
                }
                const createRes = await createFolder(submission, accessToken)
                console.log("CREATE ARCHIVES FOLDER RES", createRes)

            }

            const update: Partial<Record<keyof AdSchema, any>> = {
                folderPath: ',Archives,'
            }
            if (!ad || !ad._id) return
            const res = await updateAd(ad._id, update, accessToken)
            console.log("SUCCESSFUL UPDATE ", res)
            setAlreadyArchived(true)

            return
        } catch (e) {
            console.error("ERROR archiving ad", e)
        }

        // try {
        //     if (!ad?._id) throw Error("no ad")
        //     // GET AUTH0 TOKEN
        //     const accessToken = await getAccessTokenSilently({
        //         audience: URLs.threedyAuth0Audience,
        //         scope: "read:current_user",
        //     })

        //     const update: Partial<Record<keyof AdSchema, any>> = {
        //         deleted: true
        //     }

        //     const res = await updateAd(ad._id, update, accessToken)
        //     console.log("RES from archiving ad", res)

        //     history.push(Links.myAds)
        // }
        // catch (e) {
        //     console.error("ERROR archiving ad", e)
        // }
    }

    return (
        <ContentBody>
            <Container>
                <Details>
                    <header>
                        <h3>Basic Details</h3>
                    </header>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '0' }}>
                        <ReviewRow>
                            <h3>Banner Title</h3>
                            <h4>{ad?.adName}</h4>
                            <div></div>
                        </ReviewRow>
                        <ReviewRow>
                            <h3>Click URL</h3>
                            <h4>{ad?.activeURL}</h4>
                            <div></div>
                        </ReviewRow>
                        {/* <ReviewRow>
                            <h3>Click Macro</h3>
                            <h4>{ad?.clickMacro}</h4>
                            <div></div>
                        </ReviewRow> */}
                        <ReviewRow>
                            <h3>Banner Size</h3>
                            <h4>{ad?.adWidth && ad?.adHeight ? `${ad.adWidth}x${ad.adHeight}` : ""}</h4>
                            <div></div>
                        </ReviewRow>
                    </div>
                </Details>
                <Settings>
                    <header>
                        <h3>Banner Settings</h3>
                    </header>
                    <SideFormRow>
                        <h4>{alreadyArchived ? "This banner has been archived. You can restore it from the Archives folder." : "Do you want to remove this banner?"}</h4>
                        <Button variant="danger" onClick={() => setArchiveModal(true)} disabled={alreadyArchived}>
                            {alreadyArchived ? "Archived" : "Archive Banner"}
                        </Button>
                    </SideFormRow>
                </Settings>
                <Preview>
                    <header>
                        <h3>Banner Preview</h3>
                    </header>
                    <PreviewContainer contained={true}>
                        <ImageViewer
                            fileUrl={ad?.creativeBg || ""}
                            transparent={ad?.transparent}
                            width={ad?.adWidth || 300}
                            height={ad?.adHeight || 300}
                            model={{
                                xCoordinate: ad?.expleft || 0,
                                yCoordinate: ad?.exptop || 0,
                                width: ad?.expwidth || 0,
                                height: ad?.expheight || 0,
                                details: { job_id: ad?._id, models: { glb: { url: ad?.glbLoc } } },
                                zoom: !ad?.camDisableZoom,
                                autoRotate: ad?.autoRotate || false,
                                autoRotateSpeed: ad?.autoRotateSpeed,
                                autoRotateDirection: ad?.autoRotateDirection as "left" | "right",
                                shadowIntensity: ad?.shadowIntensity,
                                shadowEdgeSoftness: ad?.shadowSoftness,
                                cameraControls: true,
                                cameraDistance: ad?.camInitialDist,
                                cameraVerticalAngle: ad?.camHeightAngle,
                                cameraHorizontalAngle: ad?.paramInitialAngle
                            }}
                            showViewInArButton={ad?.arEnabled ? true : false}
                            viewInArButton={{
                                xCoordinate: ad?.arZoneLeft || 0,
                                yCoordinate: ad?.arZoneTop || 0,
                                type: "image"
                            }}
                        />
                        {/* <ExpandButton onClick={makeImageFullScreen}>
                            <div><img src={ExpandIcon} alt="expand" /></div>
                        </ExpandButton> */}
                    </PreviewContainer>
                </Preview>
            </Container>
            {showArchiveModal &&
                <Modal
                    title="You’re about to archive this 3D banner.
                    Are you sure?"
                    text={`This 3D banner will be moved to the archived folder. Any embeds will no longer be active, and all shared access will be lost.
                    You can restore this banner from your archive folder.`}
                    callback={() => {
                        archiveAd()
                        setArchiveModal(false)
                    }}
                    callbackText={"Yes, archive this banner"}
                    handleClose={() => setArchiveModal(false)}
                    buttonVariant="danger-fill"
                    showGoBack={true}
                />
            }
        </ContentBody>
    )
}

export default AdOverview