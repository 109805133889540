import styled from 'styled-components';
import { Label } from '../shared.styles';

type ContainerProps = {
    width: number
    height: number
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    // height: 334px;
    // width: 100%;
    // max-width: 425px;
    margin: 0.5rem 0;
    min-height: 3.875rem;

    border: 1px dashed #858993;
    border-radius: 9px;

    padding: 2% 5%;

    position: relative;
`;

export const FileInput = styled.input`
    display: none;
`;

export const FileLabel = styled(Label)`

    position: initial;
    pointer-events: initial;
    &:hover {
        cursor: pointer;
    }
    margin: 0;
    font-size: 0.875rem !important;

    color: ${({ theme }) => theme.colors.linkPrimary};

`;

export const FileInputDivider = styled.div`
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 5%;
    align-items: center;
    color: #717171;
    margin: 1% 0;

    hr {
        // flex: 1;
        border-top: 1px solid #717171;
        margin: 0;
    }

    p {
        font-weight: normal;
        font-size: clamp((.8 * 12px), 1.38vw, (16px));
        line-height: 22px;
        margin: 0 !important;
    }
`

export const DragOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: white;
    opacity: 0.4;
`