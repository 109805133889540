import { capitalizeFirstLetter, splitCamelCaseWithAbbreviations } from "./strings"

export const getTableHeader = (header: string): string => {
    const split = splitCamelCaseWithAbbreviations(header)
    const capitalized = split.map((s: string) => capitalizeFirstLetter(s))
    const result = capitalized.join(" ")
    return result
}

export declare type AlignType = 'left' | 'center' | 'right';

export const getTDAlign = (idx: number, isDate?: boolean): AlignType => {
    if (idx === 0) return "left"
    // if (isDate) return "left"
    return "center"
}