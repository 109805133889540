import Home from 'Pages/Home/Home';
import AdFormBasicDetails from 'Pages/AdForm/BasicDetails'
import AdFormDimensions from 'Pages/AdForm/Dimensions'
import AdFormBackground from 'Pages/AdForm/Background'
import AdFormModel from 'Pages/AdForm/Model'
import AdFormAdjustments from 'Pages/AdForm/Adjustments'
import AdFormReview from 'Pages/AdForm/Review'
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { store } from 'Store/store';
import { Links } from 'Values/Constants';
import { DefaultTheme } from 'Styles/theme';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'Styles/globalStyles';

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import MyAds from 'Pages/MyAds/MyAds';
import Support from 'Pages/Support/Support';
import ScrollToTop from 'Utils/useScrollToTop';
import AuthWrapper from 'Components/AuthWrapper/auth-wrapper.component'
import AdPage from 'Pages/AdPage/AdPage';
import { axiosConfig } from 'Config/axios';
import { SWRConfig } from 'swr';
import { fetcher } from 'Config/swr';
import PageNotFound from 'Pages/PageNotFound/PageNotFound';
import AdPreviewPage from 'Pages/AdPreviewPage/AdPreviewPage';

let persistor = persistStore(store);

axiosConfig()

function App() {
  const url = window.document.location.href

  useEffect(() => {
    if (window.history.length > 1) {
      localStorage.setItem("fromSuite", JSON.stringify(false))
    } else if (url.includes("fromsuite")) {
      localStorage.setItem("fromSuite", JSON.stringify(true))
    }
  })
 
  return (
    <React.Fragment>
      <ThemeProvider theme={DefaultTheme}>
        <GlobalStyles />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SWRConfig value={{ fetcher }}>
              <Router>
                <ScrollToTop />
                <Switch>
                  <Route exact path={Links.home}>
                    <AuthWrapper>
                      <Home />
                    </AuthWrapper>
                  </Route>
                  <Route exact path={Links.homeFromSuite}>
                    <AuthWrapper>
                      <Home />
                    </AuthWrapper>
                  </Route>

                  <Route path={Links.myAds}>
                    <AuthWrapper>
                      <MyAds />
                    </AuthWrapper>
                  </Route>

                  <Route exact path={Links.ad.definition}>
                    <AuthWrapper>
                      <AdPage />
                    </AuthWrapper>
                  </Route>

                  <Route exact path={Links.adForm.basicDetails}>
                    <AuthWrapper>
                      <AdFormBasicDetails
                        previousStep={Links.home}
                        nextStep={Links.adForm.dimensions}
                      />
                    </AuthWrapper>
                  </Route>
                  <Route exact path={Links.adForm.dimensions}>
                    <AuthWrapper>
                      <AdFormDimensions
                        previousStep={Links.adForm.basicDetails}
                        nextStep={Links.adForm.background}
                      />
                    </AuthWrapper>
                  </Route>

                  <Route exact path={Links.adForm.background}>
                    <AuthWrapper>
                      <AdFormBackground
                        previousStep={Links.adForm.dimensions}
                        nextStep={Links.adForm.model}
                      />
                    </AuthWrapper>
                  </Route>


                  <Route exact path={Links.adForm.model}>
                    <AuthWrapper>
                      <AdFormModel
                        previousStep={Links.adForm.background}
                        nextStep={Links.adForm.adjustments}
                      />
                    </AuthWrapper>
                  </Route>

                  <Route exact path={Links.adForm.adjustments}>
                    <AuthWrapper>
                      <AdFormAdjustments
                        previousStep={Links.adForm.model}
                        nextStep={Links.adForm.review}
                      />
                    </AuthWrapper>
                  </Route>

                  <Route exact path={Links.adForm.review}>
                    <AuthWrapper>
                      <AdFormReview
                        previousStep={Links.adForm.adjustments}
                        nextStep={Links.home}
                      />
                    </AuthWrapper>
                  </Route>

                  <Route exact path={Links.support}>
                    <AuthWrapper>
                      <Support />
                    </AuthWrapper>
                  </Route>

                  <Route path={Links.adPreview.definition}>
                    <AdPreviewPage />
                  </Route>

                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </Router>
            </SWRConfig>
          </PersistGate>
        </Provider >
      </ThemeProvider>
    </React.Fragment >
  );
}

export default App
