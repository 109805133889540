import styled from 'styled-components';
import "@fontsource/poppins";
import checkmark from "Assets/images/icons/checkmark.png"

export const Header = styled.header`
    background: ${({ theme }) => theme.colors.pageHeaderBackground};
    display: flex;
    justify-content: space-between;
    padding: 20px 12px;
    grid-area: header;
    height: 160px;

    & > * {
        color: ${({ theme }) => theme.colors.pageHeaderColor};
    }

    .middleColumn {
        width: 95%;
        margin-left: 50px;
    }

    .rightColumn {
        width: 3%;
        margin-top: -100px;
    }

    .rowOne {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
       
    }

    .stepTitle {
        margin-top: 15px;
        margin-left: 4%;
    }

    p {
        font-style: normal;
    }

    .title {
        font-weight: 500;
        font-size: 22px;
        line-height: 100%;
    }

    .subtitle {
        font-weight: 300;
        font-size: 18px;
        line-height: 100%;
    }

    .upNextBox {
        border: 0.5px solid #858993; 
        box-sizing: border-box;
        border-radius: 6px;
        height: 81px;
        width: 330px;
        margin-right: 3%;
        padding: 21px 0px 0px 16px;
    }

    .upNext {
        font-weight: 500;
        font-size: 20px;
        line-height: 50%;
    }

    .nextTitle {
        font-weight: 300;
        font-size: 16px;
        line-height: 100%;
    }

    .rowTwo {
        margin-left: 4%;
        margin-top: 10px;
        display: flex;
        flex-direction: row; 
    }

    .circle {
        width: 20px;
        height: 20px;
        border: 1px solid #DDDEE0;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        margin-right: 5px;
        color: #DDDEE0;
    }

    .circleSelected {
        width: 20px;
        height: 20px;
        border: 0px solid #DDDEE0;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        margin-right: 5px;
        color: #000000;
        background: #FF38F8;
    }

    .circleChecked {
        width: 20px;
        height: 20px;
        border: 1px solid #DDDEE0;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0px;
        margin-right: 5px;
        background: #FFFFFF;
        background-image: url(${checkmark});
        background-size: 13px 9px;
        background-repeat: no-repeat;
        background-position: center;
    }

    .stepName {
        font-weight: 300;
        font-size: 14px;
    }

    .stepNameSelected {
        font-weight: 500;
        font-size: 14px;
        border-bottom: 1px solid #FF38F8;
    }

    .divider {
        width: 80px;
        height: 0;
        border: 0.5px solid #F2F2F3;
        opacity: 0.5;
        margin: 10px;
        display:inline-block;
    }

    .img2 {
        display: none;
    }

    @media screen and (max-width: 1125px) {
        .divider {
            width: 40px;
        }
    }

    @media screen and (max-width: 990px) {
        .middleColumn {
            width: 98%;
            margin-left: 0;
        }
        .rightColumn {
            width: 45px;
        }
        .rowOne {
            justify-content: center;
        }
        .stepTitle {
            margin-top: 15px;
        }
        .title {
            text-align: center;
        }
        .subtitle {
            text-align: center;
        }
        .upNextBox {
            display: none;
        }
        .rowTwo {
            justify-content: center;
        }
        .stepName {
            display: none;
        }
        .stepNameSelected {
            display: none;
        }
        .divider {
            margin-left: 2px;
        }
    }

    @media screen and (max-width: 600px) {
        .title {
            font-size: 20px;
        }
        .subtitle {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 560px) {
        .middleColumn {
            margin-left: -20px;
        }
        .rightColumn {
            width: 30px;
        }
    }
`;

export const ReviewHeader = styled.header`
    background: ${({ theme }) => theme.colors.pageHeaderBackground};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 12px;
    grid-area: header;
    button:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    button:nth-child(3) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 16px;
    }
    & > * {
        color: ${({ theme }) => theme.colors.pageHeaderColor};
    }
    .reviewText {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        margin: 0;
        margin-left: -220px;
    }
    .img2 {
        display: none;
    }
    @media screen and (max-width: 850px) {
        .img1 {
            display: none;
        }
        .img2 {
            display: block;
        }
        .reviewText {
            margin-left: -50px;
        }
    }
    @media screen and (max-width: 600px) {
        .img2 {
            margin-left: -16px;
        }
    }
`;