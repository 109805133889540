import { Page } from "./ad-form-page-wrapper.styles"

type Props = {
    children?: React.ReactNode | React.ReactNode[]
}

const AdFormPageWrapper = (props: Props) => {

    const { children } = props

    return (
        <Page>
            {children}
        </Page>
    )
}

export default AdFormPageWrapper