import { xAPI } from "Values/Constants"

export const queryXapi = async (query: Array<Record<string, any>>): Promise<any> => {

    const headers = new Headers();
    headers.set('Authorization', "Basic " + btoa(xAPI.user + ":" + xAPI.pass))
    headers.set('Content-Type', 'application/json');

    const request: RequestInit = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(query)
    }

    const response = await fetch(`${xAPI.endPoint}`, request)

    if (response.status !== 200) {
        throw Error(await response.json());
    }

    return response.json();
}
