import { useCallback } from 'react';
import { useState, useEffect, useRef } from 'react';

const useOutsideClick = () => {
    const [isComponentVisible, setIsComponentVisible] = useState(true);
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback((event: any) => {
        // if clicked outside, set to false to trigger toggle action in component
        if (ref && ref.current && !ref.current.contains(event.target) && isComponentVisible) {
            setIsComponentVisible(false);
        }
        // reset component to initial value
        setIsComponentVisible(true);
    }, [isComponentVisible])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, [handleClickOutside]);

    return { ref, isComponentVisible, setIsComponentVisible };
}

export default useOutsideClick;