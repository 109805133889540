import { Ad, ReduxAction } from "@declarations";
import { Actions } from "Store/Actions/ActionTypes";
import { DefaultValues } from "Values/Constants";

export const initialAdState: Ad = {
    updating: false,
    title: "",
    // idTag: "",
    // eslint-disable-next-line no-template-curly-in-string
    clickMacro: "${CLICK_URL}",
    destinationLink: "",
    width: 0,
    height: 0,
    background: '',
    transparent: false,
    model: {},
    modelWidth: 0,
    modelHeight: 0,
    modelXCoordinate: 0,
    modelYCoordinate: 0,
    cameraDistance: 105,
    cameraVerticalAngle: 90,
    cameraHorizontalAngle: 0,
    zooming: false,
    shadows: true,
    shadowIntensity: 1,
    shadowEdgeSoftness: 1,
    autoRotate: true,
    autoRotateSpeed: 5,
    autoRotateDirection: "left",
    viewInArButton: false,
    viewInArButtonXCoordinate: 0,
    viewInArButtonYCoordinate: 0,
    customButton: "image",
    customButtonLabel: DefaultValues.customButtonLabel,
    arPlacement: "On the floor",
    bannersEnabled: true,
    customBanner: true,
    customBannerTitle: "Model",
    customBannerSubtitle: DefaultValues.customBannerSubTitle,
    // customBannerPrice: 0,
    customBannerCtaLabel: DefaultValues.customBannerCtaLabel,
    folderPath: null,
    staticLoad: ""
}

export const adForm = (state: Ad = initialAdState, { type, payload }: ReduxAction): Ad => {
    switch (type) {
        case Actions.SET_AD_FORM:
            return payload as Ad
        case Actions.CLEAR_AD_FORM:
            return initialAdState
        default:
            return state
    }
}