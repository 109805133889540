import { CreativeBg } from "@declarations";
import axios from "axios";
import useSWR from "swr";

export const uploadImage = (url: string, file: File | null) => {
    return fetch(url, {
        method: "PUT",
        body: file
    })
}

export const getUploadUrl = (token: string, contenttype: string) => {
    const path = `/files?contenttype=${encodeURIComponent(contenttype)}`
    return axios.get(path, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const GetAllBgImages = () => {
    const path = "creativebg"
    return useSWR(path);
}

export const getAllCreativeBg = (token: string) => {
    const path = "creativebg"
    return axios.get(path, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const searchCreativeBgByCreator = (creatorClientId: string | null, token: string) => {
    const path = `creativebg/searchByCreator?creatorClientId=${creatorClientId}`
    return axios.get(path, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const searchBgImages = (width: number, height: number) => {
    const path = `creativebg/search?width=${width}&height=${height}`
    return axios.get(path)
}

export type BgSubmission = Omit<CreativeBg, "createdDate" | "updatedDate" | "_id" | "__v">

export const createBgImage = (submission: BgSubmission, token: string) => {
    const path = "creativebg"
    return axios.post(path, submission, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const updateBgImage = (id: string, data: Record<string, string | number>) => {
    const path = `ads/${id}`
    return axios.patch(path, data)
}

export const deleteBgImage = (id: string) => {
    const path = `creativebg/${id}`
    return axios.delete(path)
}