import { AdSchema, AppState, ArPlacement, CustomArButton, Form, PageProps, RotateDirection } from "@declarations"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { store } from "Store/store"
import { setAdAdjustments } from "Store/Actions/AdFormActions"
import React, { useEffect, useState } from "react"
import AdFormPageWrapper from "Components/AdForm/Page/ad-form-page-wrapper.component"
import AdFormHeader from "Components/AdForm/Header/ad-form-header.component"
import AdFormFooter from "Components/AdForm/Footer/ad-form-footer.component"
import StepButton from "Components/AdForm/StepButton/step-button.component"
import { AdForm, DefaultValues, Links, URLs } from "Values/Constants"
import AdFormBody from "Components/AdForm/Body/ad-form-body.component"
import { ResetButton, DivBox } from "Components/AdForm/Body/ad-form-body.styles"
import ImageViewer from "Components/ImageViewer/image-viewer.component"
import { FlexWrappedPage } from "Styles/library/page"
import { FormValidator, maxLength, required } from "Components/Forms/form-validation"
import ValidatedInput from "Components/Forms/Input/input.component"
import Toggle from "Components/Forms/Toggle/toggle.component"
import { SideFormRow, SideFormRowTitle, SideFormWrapper } from "Components/AdForm/Shared/side-form.styles"
import { centerButtonCoordinatesInModel, centerModelCoordinates, getDefaultModelDimensions } from "Utils/dimensions"
import SliderInput from "Components/Forms/SliderInput/slider-input.component"
import { PreviewContainer } from "Components/ImageViewer/image-viewer.styles"
import Tabs from "Components/Tabs/tabs.component"
import Dropdown from "Components/Forms/Dropdown/Dropdown"
import { Button } from "Styles/library/button"
import { ModalInstructions, ModalTitle } from "Styles/library/modal"
import DragIcon from 'Assets/images/icons/drag-icon-blue.svg';
import ExpandIconBlue from 'Assets/images/icons/expand-icon-blue.svg'
import BannerModal from "Components/Modals/AdForm/BannerModal/banner-modal.component"
import ImageModal from "Components/Modals/AdForm/ImageModal/image-modal.component"
import { useAuth0 } from "@auth0/auth0-react"
import { updateAd } from "Api/Api/ads"
import { Actions, makeAction } from "Store/Actions/ActionTypes"
import Tooltip from "Components/Tooltip/Tooltip"
import Modal from "Components/Modals/Modal/modal.component"
import { useRef } from 'react';
import Progress from "../../Components/AdForm/Progress/progress.component"
import forwardArrow from "Assets/images/icons/forwardArrow.png"
import "Components/Forms/SliderInput/slider-input-component.css"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    previousStep: string
    nextStep: string
}

const CURRENT_STEP = 5

const BANNER_TITLE_MAX = 30
const BANNER_SUBTITLE_MAX = 40
const BANNER_CTA_LABEL_MAX = 20

const AdFormAdjustments = (props: Props) => {
    const childRef2 = useRef<any>(null);

    const { updateAdjustments, previousStep, nextStep, adForm, clearAdForm } = props

    const [tab, setTab] = useState<string>("Model Settings")

    const options: string[] = [
        "Model Settings",
        "AR Experience Settings"
    ]

    const handleTabSelection = (option: string) => {
        setTab(option)
    }

    const [showModelModal, setShowModelModal] = useState<boolean>(false)

    const toggleModelModal = () => {
        setShowModelModal(!showModelModal)
    }

    const [showButtonModal, setShowButtonModal] = useState<boolean>(false)

    const toggleButtonModal = () => {
        setShowButtonModal(!showButtonModal)
    }

    const [showBannerModal, setShowBannerModal] = useState<boolean>(false)

    const toggleBannerModal = () => {
        setShowBannerModal(!showBannerModal)
    }


    const [shadow, setShadow] = useState<{
        intensity: number,
        edgeSoftness: number
    }>({
        intensity: adForm.shadowIntensity ?? 1,
        edgeSoftness: adForm.shadowEdgeSoftness ?? 1
    })

    const [toggles, setToggles] = useState<Record<string, boolean>>({
        viewInArButton: adForm.viewInArButton,
        zooming: adForm.zooming,
        shadows: adForm.shadows,
        autoRotate: adForm.autoRotate,
        customBanner: adForm.customBanner
    })

    const toggleViewInArButton = () => {
        // if on, we are about to turn off
        if (toggles.viewInArButton) {
            if (!data.customButtonLabel) {
                handleValidChange(true)
                setData(data => ({
                    ...data,
                    customButtonLabel: adForm.customButtonLabel ? adForm.customButtonLabel : "Banner"
                }))
            }
            setData(data => ({
                ...data,
                viewInArButtonXCoordinate: centerButtonCoordinatesInModel(adForm.modelWidth, adForm.modelHeight).x,
                viewInArButtonYCoordinate: centerButtonCoordinatesInModel(adForm.modelWidth, adForm.modelHeight).y
            }))
        }
        setToggles(data => ({
            ...data,
            viewInArButton: !data.viewInArButton
        }))
    }

    const toggleZooming = () => {
        setToggles(data => ({
            ...data,
            zooming: !data.zooming
        }))
    }

    const toggleShadows = () => {
        setToggles(data => ({
            ...data,
            shadows: !data.shadows
        }))
    }

    const toggleAutoRotate = () => {
        setToggles(data => ({
            ...data,
            autoRotate: !data.autoRotate
        }))
    }

    const toggleCustomBanner = () => {
        // if custom banner turned on, we're about to turn off
        if (toggles.customBanner) {

            if (!data.customBannerTitle || (data.customBannerTitle as string).length > BANNER_TITLE_MAX) {
                handleValidChange(true)
                setData(data => ({
                    ...data,
                    customBannerTitle: adForm.model.title || adForm.customBannerTitle
                }))
            }

            if (!data.customBannerSubtitle || (data.customBannerSubtitle as string).length > BANNER_SUBTITLE_MAX) {
                handleValidChange(true)
                setData(data => ({
                    ...data,
                    customBannerSubtitle: adForm.customBannerSubtitle
                }))
            }

            if (!data.customBannerCtaLabel || (data.customBannerCtaLabel as string).length > BANNER_CTA_LABEL_MAX) {
                handleValidChange(true)
                setData(data => ({
                    ...data,
                    customBannerCtaLabel: adForm.customBannerCtaLabel
                }))
            }
        }
        setToggles(data => ({
            ...data,
            customBanner: !data.customBanner
        }))
    }

    const [data, setData] = useState<Form.Data>({
        destinationLink: adForm.destinationLink,
        modelWidth: adForm.modelWidth ?? getDefaultModelDimensions(adForm.width, adForm.height),
        modelHeight: adForm.modelHeight ?? getDefaultModelDimensions(adForm.width, adForm.height),
        modelXCoordinate: adForm.modelXCoordinate ?? centerModelCoordinates(adForm.width, adForm.height).x,
        modelYCoordinate: adForm.modelYCoordinate ?? centerModelCoordinates(adForm.width, adForm.height).x,
        cameraDistance: adForm.cameraDistance ?? 105,
        cameraVerticalAngle: adForm.cameraVerticalAngle ?? 90,
        cameraHorizontalAngle: adForm.cameraHorizontalAngle ?? 0,
        autoRotateSpeed: adForm.autoRotateSpeed ?? 5,
        autoRotateDirection: adForm.autoRotateDirection ?? "left",
        viewInArButtonXCoordinate: adForm.viewInArButtonXCoordinate || centerButtonCoordinatesInModel(adForm.modelWidth, adForm.modelHeight).x,
        viewInArButtonYCoordinate: adForm.viewInArButtonYCoordinate || centerButtonCoordinatesInModel(adForm.modelWidth, adForm.modelHeight).y,
        customButton: adForm.customButton ?? "image",
        customButtonLabel: adForm.customButtonLabel || DefaultValues.customButtonLabel,
        arPlacement: adForm.arPlacement ?? "On the floor",
        customBannerTitle: adForm.model.title || adForm.customBannerTitle,
        customBannerSubtitle: adForm.customBannerSubtitle || DefaultValues.customBannerSubTitle,
        // customBannerPrice: adForm.customBannerPrice ?? 0,
        customBannerCtaLabel: adForm.customBannerCtaLabel || DefaultValues.customBannerCtaLabel
    })

    const [customButton, setCustomButton] = useState<CustomArButton>("image")
    const [customButtonLabel, setCustomButtonLabel] = useState<string>("")

    useEffect(() => {
        setCustomButton(data.customButton as CustomArButton)
        setCustomButtonLabel(data.customButtonLabel as string)
    }, [data])

    const [modelDims, setModelDims] = useState({ width: adForm.modelWidth, height: adForm.modelHeight })
    // const [arDims, setArDims] = useState({ width: adForm.modelXCoordinate, height: adForm.modelYCoordinate })

    const receiveModelDims = (width: number, height: number) => {
        setModelDims({ width, height })
    }

    // const receiveArDims = (width: number, height: number) => {
    //     setArDims({ width, height })
    // }

    const [modelPos, setModelPos] = useState({ x: data.modelXCoordinate as number, y: data.modelYCoordinate as number })

    const receiveModelPos = (x: number, y: number) => {
        setModelPos({ x, y })
    }

    const [arPos, setArPos] = useState({ x: data.viewInArButtonXCoordinate as number, y: data.viewInArButtonYCoordinate as number })

    const receiveArPos = (x: number, y: number) => {
        setArPos({ x, y })
    }

    const confirmDims = () => {
        setData(data => ({
            ...data,
            modelWidth: modelDims.width,
            modelHeight: modelDims.height
        }))
    }

    const cancelDims = () => {
        setModelDims({ width: data.modelWidth as number, height: data.modelHeight as number })
        // setArDims({width: adForm.viewInArButton})
    }

    const confirmPositions = () => {
        setData(data => ({
            ...data,
            modelXCoordinate: modelPos.x,
            modelYCoordinate: modelPos.y
        }))
        setData(data => ({
            ...data,
            viewInArButtonXCoordinate: arPos.x,
            viewInArButtonYCoordinate: arPos.y
        }))

    }

    const cancelPositions = () => {
        setModelPos({ x: data.modelXCoordinate as number, y: data.modelYCoordinate as number })
        setArPos({ x: data.viewInArButtonXCoordinate as number, y: data.viewInArButtonYCoordinate as number })
        setShowModelModal(false)
    }

    const confirmations = () => {
        confirmDims()
        confirmPositions()
        setShowModelModal(false)
        setShowButtonModal(false)
    }

    const cancellations = () => {
        cancelPositions()
        cancelDims()
        setShowModelModal(false)
        setShowButtonModal(false)
    }

    const rotateDirectionOptions: RotateDirection[] = ["left", "right"]
    const customButtonOptions: CustomArButton[] = ["image", "text"]
    //const arPlacementOptions: ArPlacement[] = ["On the floor", "On the wall"]

    // Form helpers
    const handleValueChange = (name: Form.Field, value: string | number | undefined) => {
        setData(data => ({
            ...data,
            [name]: value
        }))
    }

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (disabled) return;
        if (e.key === "Enter") submitProgress()
    }

    const [disabled, setDisabled] = useState<boolean>(true);

    const handleValidChange = FormValidator(setDisabled);
    // end of form helpers

    const history = useHistory();

    const { getAccessTokenSilently } = useAuth0();

    const goToAd = () => {

        if (adForm._id) {
            clearAdForm()
            history.push(Links.ad.constructor(adForm._id))
        }
    }

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [resultAd, setResultAd] = useState<AdSchema>()

    const resetSettings = () => {
        setToggles(toggles => ({
            ...toggles,
            viewInArButton: adForm.viewInArButton,
            zooming: adForm.zooming,
            shadows: adForm.shadows,
            autoRotate: adForm.autoRotate,
            customBanner: adForm.customBanner
        }))
        setData(data => ({
            ...data,
            modelWidth: adForm.modelWidth,
            modelHeight: adForm.modelHeight,
            modelXCoordinate: adForm.modelXCoordinate,
            modelYCoordinate: adForm.modelYCoordinate,
            cameraDistance: adForm.cameraDistance,
            cameraVerticalAngle: adForm.cameraVerticalAngle,
            cameraHorizontalAngle: adForm.cameraHorizontalAngle,
            autoRotateSpeed: adForm.autoRotateSpeed,
            autoRotateDirection: adForm.autoRotateDirection,
            viewInArButtonXCoordinate: adForm.viewInArButtonXCoordinate,
            viewInArButtonYCoordinate: adForm.viewInArButtonYCoordinate,
            customButton: adForm.customButton,
            customButtonLabel: adForm.customButtonLabel,
            arPlacement: adForm.arPlacement,
            customBannerTitle: adForm.customBannerTitle,
            customBannerSubtitle: adForm.customBannerSubtitle,
            // customBannerPrice: adForm.customBannerPrice,
            customBannerCtaLabel: adForm.customBannerCtaLabel
        }))
        setShadow(shadow => ({
            ...shadow,
            intensity: adForm.shadowIntensity,
            edgeSoftness: adForm.shadowEdgeSoftness
        }))
    }

    const saveProgress = async () => {
        try {
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })
            // console.log("childRef2.current",childRef2.current)
            const objectUrl = await childRef2.current.captureblob(accessToken)
            // console.log("objectUrl", objectUrl)
            const update: Partial<Record<keyof AdSchema, any>> = {
                expwidth: data.modelWidth as number,
                expheight: data.modelHeight as number,
                expleft: data.modelXCoordinate as number,
                exptop: data.modelYCoordinate as number,
                camInitialDist: data.cameraDistance as number,
                camHeightAngle: data.cameraVerticalAngle as number,
                paramInitialAngle: data.cameraHorizontalAngle as number,
                camDisableZoom: !toggles.zooming,
                paramShadows: toggles.shadows,
                shadowIntensity: shadow.intensity,
                shadowSoftness: shadow.edgeSoftness,
                autoRotate: toggles.autoRotate,
                autoRotateSpeed: data.autoRotateSpeed as number,
                autoRotateDirection: data.autoRotateDirection as RotateDirection,
                arEnabled: toggles.viewInArButton,
                arZoneLeft: data.viewInArButtonXCoordinate as number || 0,
                arZoneTop: data.viewInArButtonYCoordinate as number || 0,
                //  data.customButton as CustomArButton,
                // ctaMessage: data.customButtonLabel as string,
                arPlacement: data.arPlacement as ArPlacement === "On the floor" ? "floor" : "wall",
                //  ar: toggles.customBanner,
                //    bann:  data.customBannerTitle as string,
                //    sub:  data.customBannerSubtitle as string,
                ctaMessage: data.customBannerCtaLabel as string,
                staticLoad: objectUrl
            }

            if (!adForm._id) return
            const res = await updateAd(adForm._id, update, accessToken)
            console.log("SUCCESSFUL UPDATE ", res)
            setResultAd(res.data.data as AdSchema)
            setShowSuccessModal(true)
        } catch (e) {
            console.error("Error updating banner", e)
            setShowErrorModal(true)
        }
    }

    const submitProgress = async () => {
        updateAdjustments(
            data.modelWidth as number,
            data.modelHeight as number,
            data.modelXCoordinate as number,
            data.modelYCoordinate as number,
            data.cameraDistance as number,
            data.cameraVerticalAngle as number,
            data.cameraHorizontalAngle as number,
            toggles.zooming,
            toggles.shadows,
            shadow.intensity,
            shadow.edgeSoftness,
            toggles.autoRotate,
            data.autoRotateSpeed as number,
            data.autoRotateDirection as RotateDirection,
            toggles.viewInArButton,
            data.viewInArButtonXCoordinate as number,
            data.viewInArButtonYCoordinate as number,
            data.customButton as CustomArButton,
            data.customButtonLabel as string,
            data.arPlacement as ArPlacement,
            toggles.customBanner,
            data.customBannerTitle as string,
            data.customBannerSubtitle as string,
            data.customBannerCtaLabel as string
        )

        history.push(nextStep)
    }

    const goBack = async () => {
        history.push(previousStep)
    }

    const renderTabs = (tab: string) => {
        switch (tab) {
            case "Model Settings":
                return <>
                    <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                        <h3>
                            3D Model Size and Position
                        </h3>
                        <Button variant={"primary"} onClick={toggleModelModal}>
                            Position Model
                        </Button>
                    </SideFormRow>
                    {/* <SideFormRow>
                        <FieldWrapper maxWidth="initial">
                            <ValidatedInput
                                id="modelXCoordinate"
                                name="modelXCoordinate"
                                placeholder="X Coordinate"
                                value={data.modelXCoordinate && data.modelXCoordinate >= 0 ? data.modelXCoordinate : ""}
                                minValue={0}
                                validations={[required("X Coordinate"), minValue(0, "X Coordinate")]}
                                onValueChange={handleValueChange}
                                onValidChange={handleValidChange}
                                onKeyUp={onKeyUp}
                                type="number"
                            />
                            <ValidatedInput
                                id="modelYCoordinate"
                                name="modelYCoordinate"
                                placeholder="Y Coordinate"
                                value={data.modelYCoordinate && data.modelYCoordinate >= 0 ? data.modelYCoordinate : ""}
                                minValue={0}
                                validations={[required("Y Coordinate"), minValue(0, "Y Coordinate")]}
                                onValueChange={handleValueChange}
                                onValidChange={handleValidChange}
                                onKeyUp={onKeyUp}
                                type="number"
                            />
                        </FieldWrapper>
                    </SideFormRow>
                    <SideFormRow>
                        <FieldWrapper maxWidth="initial">
                            <ValidatedInput
                                id="modelWidth"
                                name="modelWidth"
                                placeholder="Model Width"
                                value={data.modelWidth && data.modelWidth >= 0 ? data.modelWidth : ""}
                                minValue={0}
                                validations={[required("Model width"), minValue(0, "Width")]}
                                onValueChange={handleValueChange}
                                onValidChange={handleValidChange}
                                onKeyUp={onKeyUp}
                                type="number"
                            />
                            <ValidatedInput
                                id="modelHeight"
                                name="modelHeight"
                                placeholder="Model Height"
                                value={data.modelHeight && data.modelHeight >= 0 ? data.modelHeight : ""}
                                minValue={0}
                                validations={[required("Model height"), minValue(0, "Model height")]}
                                onValueChange={handleValueChange}
                                onValidChange={handleValidChange}
                                onKeyUp={onKeyUp}
                                type="number"
                            />
                        </FieldWrapper>
                    </SideFormRow> */}
                    <SideFormRowTitle>Camera Controls</SideFormRowTitle>
                    <SideFormRow className="sliderBreakRow" style={{ justifyContent: 'end', borderBottom: '1px solid #DDDEE0' }}>
                        <div className="sliderBreak2">
                            <SliderInput
                                title={"Camera Distance"}
                                tooltip={"Use this to set the zoom of the 3D model. We recommend leaving this at the max so that none of the model is cut off at the model box boundary."}
                                right={true}
                                min={0}
                                max={105}

                                step="1"
                                value={data.cameraDistance as number}
                                sendValue={(val: number) => handleValueChange("cameraDistance", val)}
                            />
                        </div>
                        <div className="sliderBreak2">
                            <SliderInput
                                title={"Camera Vertical Angle"}
                                tooltip={"This is how high the camera will be, since the camera looks at the 3D object always, the higher you go, the more you’ll be looking down on the model. We recommend leaving this as is but you can move this to suit the perspective of a background image."}
                                right={true}
                                min={0}
                                max={180}
                                step="1"
                                value={data.cameraVerticalAngle as number}
                                sendValue={(val: number) => handleValueChange("cameraVerticalAngle", val)}
                            />
                        </div>
                        <div className="sliderBreak2">
                            <SliderInput
                                title={"Camera Horizontal Angle"}
                                tooltip={"This is the angle that the camera will first look at the 3D model, consider changing this if you want your viewer to see a different side of the 3D model when it first appears."}
                                right={true}
                                min={-90}
                                max={90}
                                step="1"
                                value={data.cameraHorizontalAngle as number}
                                sendValue={(val: number) => handleValueChange("cameraHorizontalAngle", val)}
                            />
                        </div>
                        <div></div>
                    </SideFormRow>
                    <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                        <div className="title-wrapper">
                            <h3>
                                Do you want to enable zooming?
                            </h3>
                            <Tooltip text={`Allowing users to zoom your 3D model is a great way for them to see the detail in the model. However, the zoom will be limited to the model box so it will get cut-off. We only recommend using this feature when the model box is the full size of the Swirl, or, if the model box matches a specific visual boundary in the background image.`} 
                             center={true}/>
                        </div>
                        <Toggle
                            on={toggles.zooming ? true : false}
                            sendToggle={toggleZooming}
                            onText={"ENABLED"}
                            offText={"DISABLED"}
                        />
                    </SideFormRow>
                    <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                        <div className="title-wrapper">
                            <h3>
                                Do you want to enable shadows?
                            </h3>
                            <Tooltip text={`You can toggle on or off the shadows. Normally, shadows add to the visual cue that the object is real so they should remain on. However, some objects do not need to be placed on or near a floor and in cases like that shadows may seem unusal.`} 
                            center={true}/>
                        </div>
                        <Toggle
                            on={toggles.shadows ? true : false}
                            sendToggle={toggleShadows}
                            onText={"ENABLED"}
                            offText={"DISABLED"}
                        />
                    </SideFormRow>
                    {toggles.shadows &&
                        <SideFormRow className="sliderBreakRow" style={{ justifyContent: 'end', borderBottom: '1px solid #DDDEE0' }}>
                            <div className="sliderBreak1">
                                <SliderInput
                                    title={"Shadow Intensity"}
                                    min={0}
                                    max={1}
                                    step={"0.1"}
                                    value={shadow.intensity}
                                    sendValue={(val: number) => {
                                        setShadow(s => ({
                                            ...s,
                                            intensity: val
                                        }))
                                    }}
                                />
                            </div>
                            <div className="sliderBreak1">
                                <SliderInput
                                    title={"Shadow Edge Softness"}
                                    min={0}
                                    max={1}
                                    step={"0.1"}
                                    value={shadow.edgeSoftness}
                                    sendValue={(val: number) => {
                                        setShadow(s => ({
                                            ...s,
                                            edgeSoftness: val
                                        }))
                                    }}
                                />
                            </div>
                            <div></div>
                        </SideFormRow>
                    }
                    <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                        <div className="title-wrapper">
                            <h3>
                                Do you want to enable auto-rotate?
                            </h3>
                            <Tooltip text={`A slow rotation informs the user that the visual is a 3D model and helps tempt them to interact with it. We recommend leaving this feature enabled. You can adjust the rate but a model rotating too quickly or too slowly may not have the most pleasant effect. The default speed seems to be a good balance.`} 
                            center={true} />
                        </div>
                        <Toggle
                            on={toggles.autoRotate ? true : false}
                            sendToggle={toggleAutoRotate}
                            onText={"ENABLED"}
                            offText={"DISABLED"}
                        />
                    </SideFormRow>
                    {toggles.autoRotate &&
                        <>
                            <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                                <h3>Rotate Speed</h3>
                                <SliderInput
                                    containerStyle={{ maxWidth: '50%' }}
                                    title={"Rotate Speed"}
                                    min={0}
                                    max={10}
                                    step={"1"}
                                    value={data.autoRotateSpeed as number}
                                    sendValue={(val: number) => {
                                        setData(s => ({
                                            ...s,
                                            autoRotateSpeed: val
                                        }))
                                    }}
                                />
                            </SideFormRow>
                            <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                                <h3>Rotate Direction</h3>
                                <Dropdown
                                    options={rotateDirectionOptions}
                                    value={data.autoRotateDirection as string}
                                    sendValue={(val: string) => handleValueChange("autoRotateDirection", val)}
                                />
                            </SideFormRow>
                        </>
                    }
                </>
            case "AR Experience Settings":
                return <>
                    <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                        <div className="title-wrapper">
                            <h3>
                                Do you want to enable a “View in AR” button?
                            </h3>
                            <Tooltip text={`Enable this feature if you wish to provide viewers with an additional AR experience. This will give them the opportunity to place the 3D model in thier own space as if it were really there with them. We recommend turning this feature on for any Swirl.  When enabled, you’ll want to adjust the position of the button that activates the AR experience as well as other details of the AR experience.`} 
                            under={true}/>
                        </div>
                        <Toggle
                            on={toggles.viewInArButton ? true : false}
                            sendToggle={toggleViewInArButton}
                            onText={"ENABLED"}
                            offText={"DISABLED"}
                        />
                    </SideFormRow>
                    {toggles.viewInArButton &&
                        <>
                            <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                                <div className="title-wrapper">
                                    <h3>
                                        "View in AR" Button Position and Location
                                    </h3>
                                    <Tooltip text={`This button must be placed within the 3D model box in order to be seen. We recommend placing it so that it does not obscure the 3D model as much as possible.  This is the button a user will click to activate the AR experience with your 3D model.`} 
                                    center={true}/>
                                </div>
                                <Button variant={"primary"} onClick={toggleButtonModal}>
                                    Set Button Position
                                </Button>
                            </SideFormRow>
                             {/* <SideFormRow>
                               <FieldWrapper maxWidth="initial">
                                    <ValidatedInput
                                        id="arXCoordinate"
                                        name="viewInArButtonXCoordinate"
                                        placeholder="X Coordinate"
                                        value={data.viewInArButtonXCoordinate && data.viewInArButtonXCoordinate >= 0 ? data.viewInArButtonXCoordinate : ""}
                                        minValue={0}
                                        validations={toggles.viewInArButton ? [required("X Coordinate"), minValue(0, "X Coordinate")] : []}
                                        onValueChange={handleValueChange}
                                        onValidChange={handleValidChange}
                                        onKeyUp={onKeyUp}
                                        type="number"
                                    />
                                    <ValidatedInput
                                        id="arYCoordinate"
                                        name="viewInArButtonYCoordinate"
                                        placeholder="Y Coordinate"
                                        value={data.viewInArButtonYCoordinate && data.viewInArButtonYCoordinate >= 0 ? data.viewInArButtonYCoordinate : ""}
                                        minValue={0}
                                        validations={toggles.viewInArButton ? [required("Y Coordinate"), minValue(0, "Y Coordinate")] : []}
                                        onValueChange={handleValueChange}
                                        onValidChange={handleValidChange}
                                        onKeyUp={onKeyUp}
                                        type="number"
                                    />
                                </FieldWrapper>
                             </SideFormRow>*/}
                            <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                                <div className="title-wrapper">
                                    <h3>
                                        Image or Custom Text Button?
                                    </h3>
                                    <Tooltip text={`Not recommended to do a custom text button.`} 
                                    center={true}/>
                                </div>
                                <Dropdown
                                    options={customButtonOptions}
                                    value={data.customButton as string}
                                    sendValue={(val: string) => {
                                        if (val === "text") {
                                            if (data.customButtonLabel === "") {
                                                handleValidChange(true)
                                                setData(data => ({
                                                    ...data,
                                                    customButtonLabel: adForm.customButtonLabel ? adForm.customButtonLabel : DefaultValues.customButtonLabel
                                                }))
                                            }
                                        }
                                        // }
                                        handleValueChange("customButton", val)
                                    }
                                    }
                                />
                            </SideFormRow>
                            {data.customButton === "text" && <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                                <h3>
                                    Button Label
                                </h3>
                                <ValidatedInput
                                    id="customButtonLabel"
                                    name="customButtonLabel"
                                    placeholder="Button Label"
                                    value={data.customButtonLabel || ""}
                                    validations={toggles.viewInArButton && data.customButton === "text" ? [required("Button Label"), maxLength(255)] : []}
                                    onValueChange={handleValueChange}
                                    onValidChange={handleValidChange}
                                    onKeyUp={onKeyUp}
                                    type="text"
                                    containerStyle={{
                                        width: '50%'
                                    }}
                                />
                            </SideFormRow>
                            }
                            {/* <SideFormRow style={{ zIndex: 2 }}>
                                <h3>
                                    AR Placement
                                </h3>
                                <Dropdown
                                    options={arPlacementOptions}
                                    value={data.arPlacement as string}
                                    sendValue={(val: string) => handleValueChange("arPlacement", val)}
                                />
                            </SideFormRow> */}
                        </>
                    }
                    {toggles.viewInArButton && <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                        <div className="title-wrapper">
                            <h3>
                                Do you want to enable a custom banner?
                            </h3>
                            <Tooltip text={`This banner will show information to the user while they are engaged in the AR experience. It’s a great opportunity to remind users of the name and some details of the object.`} 
                            center={true}/>
                        </div>
                        <Toggle
                            on={toggles.customBanner ? true : false}
                            sendToggle={toggleCustomBanner}
                            onText={"ENABLED"}
                            offText={"DISABLED"}
                        />
                    </SideFormRow>
                    }
                    {toggles.viewInArButton && toggles.customBanner &&
                        <>
                            <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                                <div className="title-wrapper">
                                    <h3>
                                        Title
                                    </h3>
                                    <Tooltip text={`Try shortening long product names.`} 
                                    center={true}/>
                                </div>
                                <ValidatedInput
                                    id="customBannerTitle"
                                    name="customBannerTitle"
                                    placeholder="Title"
                                    value={data.customBannerTitle}
                                    validations={toggles.customBanner ? [required("Banner Title"), maxLength(BANNER_TITLE_MAX)] : []}
                                    onValueChange={handleValueChange}
                                    onValidChange={handleValidChange}
                                    onKeyUp={onKeyUp}
                                    type="text"
                                    containerStyle={{
                                        width: '50%'
                                    }}
                                />
                            </SideFormRow>
                            <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                                <div className="title-wrapper">
                                    <h3>
                                        Subtitle
                                    </h3>
                                    <Tooltip text={`Add any additional details here.`} 
                                    center={true}/>
                                </div>
                                <ValidatedInput
                                    id="customBannerSubtitle"
                                    name="customBannerSubtitle"
                                    placeholder="Subtitle"
                                    value={data.customBannerSubtitle}
                                    validations={toggles.customBanner ? [required("Banner Subtitle"), maxLength(BANNER_SUBTITLE_MAX)] : []}
                                    onValueChange={handleValueChange}
                                    // onValidChange={handleValidChange}
                                    onKeyUp={onKeyUp}
                                    type="text"
                                    containerStyle={{
                                        width: '50%'
                                    }}
                                />
                            </SideFormRow>
                            {/* <SideFormRow>
                                <h3>
                                    Price
                                </h3>
                                <ValidatedInput
                                    id="customBannerPrice"
                                    name="customBannerPrice"
                                    placeholder="Price"
                                    value={data.customBannerPrice || ""}
                                    // validations={toggles.customBanner ? [required("Banner Price")] : []}
                                    onValueChange={handleValueChange}
                                    // onValidChange={handleValidChange}
                                    onKeyUp={onKeyUp}
                                    minValue={0}
                                    step="0.01"
                                    type="number"
                                    containerStyle={{
                                        width: '50%'
                                    }}
                                />
                            </SideFormRow> */}
                            {data.destinationLink && <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                                <div className="title-wrapper">
                                    <h3>
                                        Banner Button Label
                                    </h3>
                                    <Tooltip text={`Add a custom label to a button in the custom banner, this will allow users to navigate to your click URL from within the AR experience.`} 
                                    center={true}/>
                                </div>
                                <ValidatedInput
                                    id="customBannerCtaLabel"
                                    name="customBannerCtaLabel"
                                    placeholder="Label"
                                    value={data.customBannerCtaLabel}
                                    validations={toggles.customBanner ? [required("Button Label"), maxLength(BANNER_CTA_LABEL_MAX)] : []}
                                    onValueChange={handleValueChange}
                                    onValidChange={handleValidChange}
                                    onKeyUp={onKeyUp}
                                    type="text"
                                    containerStyle={{
                                        width: '50%'
                                    }}
                                />
                            </SideFormRow>}
                            <SideFormRow style={{ borderBottom: '1px solid #DDDEE0' }}>
                            <   div className="title-wrapper">
                                    <h3>
                                        Preview your custom banner
                                    </h3>
                                    <Tooltip text={`View a preview of the cutom banner, take not of the length of your titles and how that affects the layout. In IOS, titles and subtitles will be cut off at the button.  In Android longer titles are allowed but, there is no custom button.`} 
                                    center={true}/>
                                </div>
                                <Button variant={"primary"} onClick={toggleBannerModal}>
                                    Preview Banner
                                </Button>
                            </SideFormRow>
                        </>
                    }
                </>
            default:
                return <></>
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    }

    return (
        <AdFormPageWrapper>
            <div className="headerScroll">
                <AdFormHeader
                    currentStep={CURRENT_STEP}
                    totalSteps={AdForm.totalSteps}
                    title="Position and adjust your model"
                    updateTitle="Reposition and readjust your model"
                    subtitle="Adjust your model's size and position so it fits on your banner"
                    nextTitle="Reviewing and creating your banner"
                    stepOneCheck={2}
                    stepTwoCheck={2}
                    stepThreeCheck={2}
                    stepFourCheck={2}
                    stepFiveCheck={1}
                    updating={adForm.updating}
                    id={adForm._id}
                />
                <Progress currentStep={CURRENT_STEP} totalSteps={AdForm.totalSteps} updating={adForm.updating} />
            </div>
            <AdFormBody>
                <FlexWrappedPage>
                    <PreviewContainer>
                        <DivBox>
                            <p className="bannerPreviewTitle">Banner Preview</p>
                        </DivBox>   
                        <ImageViewer
                            ref={childRef2}
                            fileUrl={adForm.background}
                            transparent={adForm.transparent}
                            width={adForm.width}
                            height={adForm.height}
                            model={{
                                width: data.modelWidth as number,
                                height: data.modelHeight as number,
                                xCoordinate: data.modelXCoordinate as number,
                                yCoordinate: data.modelYCoordinate as number,
                                details: adForm.model,
                                zoom: toggles.zooming,
                                autoRotate: toggles.autoRotate,
                                autoRotateSpeed: data.autoRotateSpeed as number,
                                autoRotateDirection: data.autoRotateDirection as "left" | "right",
                                shadowIntensity: shadow.intensity,
                                shadowEdgeSoftness: shadow.edgeSoftness,
                                cameraControls: true,
                                cameraDistance: data.cameraDistance as number,
                                cameraVerticalAngle: data.cameraVerticalAngle as number,
                                cameraHorizontalAngle: data.cameraHorizontalAngle as number
                            }}
                            showViewInArButton={toggles.viewInArButton}
                            viewInArButton={{
                                xCoordinate: data.viewInArButtonXCoordinate as number,
                                yCoordinate: data.viewInArButtonYCoordinate as number,
                                type: customButton,
                                label: customButtonLabel
                            }}
                        />
                        {/* <ExpandButton onClick={makeImageFullScreen}>
                            <div><img src={ExpandIcon} alt="expand" /></div>
                        </ExpandButton> */}
                        <ResetButton onClick={resetSettings}>
                            Reset All Settings
                        </ResetButton>
                    </PreviewContainer>
                    <SideFormWrapper>
                        <Tabs
                            options={options}
                            select={handleTabSelection}
                            selected={tab}
                        />
                        <br />
                        {renderTabs(tab)}
                    </SideFormWrapper>
                </FlexWrappedPage>
                <a onClick={scrollToTop} className="topPageLink">Top of Page</a>
            </AdFormBody>
            <div className="footerScroll">
            <AdFormFooter >
                {/* {!adForm.updating ? <StepButton
                    text="Back"
                    callback={goBack}
                    variant="secondary"
                /> : <div></div>} */}
                {/* <StepButton
                    text={adForm.updating ? "Update" : "Review my banner"}
                    callback={adForm.updating ? saveProgress : submitProgress}
                    variant="primary"
                    disabled={disabled}
                /> */}
                <StepButton
                    text="Back"
                    callback={goBack}
                    variant="back"
                />
                <Button
                    className="normalButton"
                    onClick={submitProgress}
                    variant="primary"
                    disabled={disabled}
                > Review my banner <img src={forwardArrow} alt="" style={{ marginLeft: 8 }}/></Button>
                <Button
                    className="smallerButton"
                    onClick={submitProgress}
                    variant="primary"
                    disabled={disabled}
                > Review <img src={forwardArrow} alt="" style={{ marginLeft: 8 }}/></Button>
            </AdFormFooter>
            </div>
            {showModelModal &&
                <ImageModal
                    callback={confirmations}
                    handleClose={cancellations}
                >
                    <ModalTitle>
                        Resize and Reposition your model
                    </ModalTitle>
                    <ImageViewer
                        ref={childRef2}
                        fileUrl={adForm.background}
                        transparent={adForm.transparent}
                        width={adForm.width}
                        height={adForm.height}
                        model={{
                            width: data.modelWidth as number,
                            height: data.modelHeight as number,
                            xCoordinate: data.modelXCoordinate as number,
                            yCoordinate: data.modelYCoordinate as number,
                            details: adForm.model,
                            zoom: false,
                            autoRotate: false,
                            cameraControls: false,
                            dims: modelDims,
                            sendModelDims: receiveModelDims,
                            pos: modelPos,
                            sendModelPos: receiveModelPos
                        }}
                        showViewInArButton={toggles.viewInArButton}
                        viewInArButton={{
                            xCoordinate: data.viewInArButtonXCoordinate as number,
                            yCoordinate: data.viewInArButtonYCoordinate as number,
                            type: customButton,
                            label: customButtonLabel,
                            // dims: arDims,
                            // sendArDims: receiveArDims,
                            // pos: arPos,
                            // sendArPos: receiveArPos
                        }}
                    />
                    <ModalInstructions>
                        <h3>Actions Guide</h3>
                        <div className="row">
                            <img src={DragIcon} alt="drag" /> <p>Drag to reposition your model in your background</p>
                        </div>
                        <div className="row">
                            <img src={ExpandIconBlue} alt="expand" />  <p>Click and drag the corners to resize your model</p>
                        </div>
                    </ModalInstructions>
                </ImageModal>
            }
            {showButtonModal &&
                <ImageModal
                    callback={confirmations}
                    handleClose={cancellations}
                >
                    <ModalTitle>
                        Resize and Reposition your View in AR Button
                    </ModalTitle>
                    <ImageViewer
                        ref={childRef2}
                        fileUrl={adForm.background}
                        transparent={adForm.transparent}
                        width={adForm.width}
                        height={adForm.height}
                        model={{
                            width: data.modelWidth as number,
                            height: data.modelHeight as number,
                            xCoordinate: data.modelXCoordinate as number,
                            yCoordinate: data.modelYCoordinate as number,
                            details: adForm.model,
                            zoom: false,
                            autoRotate: false,
                            cameraControls: false,
                            // dims: modelDims,
                            // sendModelDims: receiveModelDims,
                            // pos: modelPos,
                            // sendModelPos: receiveModelPos
                        }}
                        showViewInArButton={toggles.viewInArButton}
                        viewInArButton={{
                            xCoordinate: data.viewInArButtonXCoordinate as number,
                            yCoordinate: data.viewInArButtonYCoordinate as number,
                            type: customButton,
                            label: customButtonLabel,
                            // dims: arDims,
                            // sendArDims: receiveArDims,
                            pos: arPos,
                            sendArPos: receiveArPos
                        }}
                    />
                    <ModalInstructions>
                        <h3>Actions Guide</h3>
                        <div className="row">
                            <img src={DragIcon} alt="drag" /> <p>Drag to reposition your button in your background</p>
                        </div>
                        {/* <div className="row">
                            <img src={ExpandIconBlue} alt="expand" />  <p>Click and drag the corners to resize your model</p>
                        </div> */}
                    </ModalInstructions>
                </ImageModal>
            }
            {showBannerModal &&
                <BannerModal
                    callback={() => setShowBannerModal(false)}
                    handleClose={() => setShowBannerModal(false)}
                    title={data.customBannerTitle as string}
                    subtitle={data.customBannerSubtitle as string}
                    ctaLabel={data.customBannerCtaLabel as string}
                    buttonEnabled={data.destinationLink != ""}
                />
            }
            {showSuccessModal
                && <Modal
                    title="Success! You've updated your banner."
                    text={`You can access ${resultAd?.adName} in My Banners.`}
                    callback={goToAd}
                    callbackText={"Go to My Banner"}
                    handleClose={() => setShowSuccessModal(false)}
                    ad={resultAd}
                />}
            {showErrorModal && <Modal
                title="Sorry! Something went wrong."
                text={`Something has gone wrong along the way, and your banner hasn’t been updated. Please try again.`}
                callback={saveProgress}
                callbackText={"Try Again"}
                handleClose={() => setShowErrorModal(false)}
                showGoBack={true}
            />}
        </AdFormPageWrapper>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        adForm: state.adForm
        
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        clearAdForm: async () => dispatch(makeAction(Actions.CLEAR_AD_FORM)),
        updateAdjustments: async (
            modelWidth: number,
            modelHeight: number,
            xCoordinate: number,
            yCoordinate: number,
            cameraDistance: number,
            cameraVerticalAngle: number,
            cameraHorizontalAngle: number,
            zooming: boolean,
            shadows: boolean,
            shadowIntensity: number,
            shadowEdgeSoftness: number,
            autoRotate: boolean,
            autoRotateSpeed: number,
            autoRotateDirection: RotateDirection,
            viewInArButton: boolean,
            viewInArButtonXCoordinate: number,
            viewInArButtonYCoordinate: number,
            customButton: CustomArButton,
            customButtonLabel: string,
            arPlacement: ArPlacement,
            customBanner: boolean,
            customBannerTitle: string,
            customBannerSubtitle: string,
            customBannerCtaLabel: string

        ) => dispatch(setAdAdjustments(
            modelWidth,
            modelHeight,
            xCoordinate,
            yCoordinate,
            cameraDistance,
            cameraVerticalAngle,
            cameraHorizontalAngle,
            zooming,
            shadows,
            shadowIntensity,
            shadowEdgeSoftness,
            autoRotate,
            autoRotateSpeed,
            autoRotateDirection,
            viewInArButton,
            viewInArButtonXCoordinate,
            viewInArButtonYCoordinate,
            customButton,
            customButtonLabel,
            arPlacement,
            customBanner,
            customBannerTitle,
            customBannerSubtitle,
            customBannerCtaLabel
        )),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdFormAdjustments)