import React, { useEffect } from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react"
import { URLs } from "Values/Constants"
import { AuthToken } from "@declarations"
import jwtDecode from "jwt-decode"

type Props = {
    children: JSX.Element
}

const AuthWrapper = (props: Props) => {
    
    const { children } = props
    const { getAccessTokenSilently } = useAuth0();

    const getPermission = async () =>{
        try{
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })
            const tkn: AuthToken = jwtDecode(accessToken)
            const permission = tkn.permissions?.includes("studio:swirl")
            !permission && window.location.assign('https://studio-test.threedy.ai');
        } catch(err){
            console.error("Error on retrieving permission", err)
        }
    }

    useEffect(()=>{
        getPermission()
    },[])

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export default withAuthenticationRequired(AuthWrapper, {
    onRedirecting: () => <></>
})

