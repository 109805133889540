import styled from "styled-components";


export const ActionRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    // min-width: 600px;

    width: 100%;
    .details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: 32px;

        h3 {
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 6px;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            margin: 0;
        }
    }
`