import Faqs from "Components/FAQs/faqs.component"
import PageLayout from "Components/PageLayout/page-layout.component"
import SupportForm from "Components/SupportForm/supportForm.components"
import { Banner, BannerText } from "Styles/library/banner"


const Support = () => {

    return (
        <PageLayout>
            <Banner>
                <BannerText>
                    <h1>Support and FAQs</h1>
                    <p>Hello, how can we help you today?</p>
                </BannerText>
            </Banner>
            <Faqs />
            <SupportForm/>
        </PageLayout>
    )
}

export default Support