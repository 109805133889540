import { AdSchema } from "@declarations"
import { ErrorTag } from "Components/Forms/shared.styles"
import React, { useState } from "react"
import { Button } from "Styles/library/button"
import { generateEmbedLink } from "Utils/embedLink"
import { ModalBox, ModalWrapper } from "./modal.styles"

type Props = {
    title: string
    text: string
    callback: () => void
    callbackText: string
    callback2?: () => void
    callback2Text?: string
    handleClose: () => void
    ad?: AdSchema
    buttonVariant?: "primary" | "secondary" | "alternate" | "tertiary" | "danger" | "danger-fill"
    showGoBack?: boolean
    callback2Variant?: "primary" | "secondary" | "alternate" | "tertiary" | "danger" | "danger-fill"
}

const Modal = (props: Props) => {

    const { title, text, callback, callbackText, callback2, callback2Text, handleClose, buttonVariant, showGoBack, callback2Variant, ad } = props

    const handleOutsideClose = (e: React.MouseEvent<HTMLElement>) => {
        const id = e.currentTarget.id
        if (id === "modal-wrapper") {
            handleClose()
        }
    }

    const [autoActivate, setAutoActivate] = useState<boolean>(false)

    const toggleAutoRotate = () => {
        setAutoActivate(!autoActivate)
    }

    return (
        <ModalWrapper id="modal-wrapper" onClick={handleOutsideClose}>
            <ModalBox onClick={e => e.stopPropagation()}>
                <h1>{title}</h1>
                <p>{text}</p>
                {ad &&
                    <>
                        <p style={{ marginBottom: 0 }}>Embed link:</p>
                        {!autoActivate && <ErrorTag>* By default, the embedded banner will require a user to click in order to activate the 3D model</ErrorTag>}
                        <p style={{ background: "rgb(0,0,0,0.1)", padding: '16px', borderRadius: '8px' }}>{generateEmbedLink(ad, autoActivate)}</p>
                        <label>
                            <input
                                type="checkbox"
                                checked={autoActivate}
                                onChange={toggleAutoRotate}
                                style={{
                                    marginRight: '1rem'
                                }}
                            />
                            Add auto-activate functionality: note, this may have a performance impact on the webpage where it is embedded
                        </label>
                        {autoActivate && <ErrorTag>* Please use only 1 autoloading embed on any webpage to avoid significant impact to the performance of the webpage where it is embedded</ErrorTag>}

                        <br />
                    </>
                }
                <Button variant={buttonVariant || 'primary'} onClick={callback} style={{ height: '42px' }}>{callbackText}</Button>
                {callback2 && callback2Text &&
                    <><br />
                        <Button variant={callback2Variant || buttonVariant || 'primary'} onClick={callback2} style={{ height: '42px' }}>{callback2Text}</Button>
                    </>
                }
                {showGoBack && <>
                    <br />
                    <Button variant="secondary" onClick={handleClose} style={{ height: '42px' }}>Go Back</Button>
                </>
                }
            </ModalBox>
        </ModalWrapper>
    )
}

export default Modal