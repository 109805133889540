export const PREVIEW_IMAGE_ID = "preview-image"

export const makeImageFullScreen = () => {
    const divObj = document.getElementById("preview-image") as HTMLElement & {
        mozRequestFullScreen(): Promise<void>;
        webkitRequestFullscreen(): Promise<void>;
        msRequestFullscreen(): Promise<void>;
    };
    //Use the specification method before using prefixed versions
    console.log(divObj)
    if (divObj?.requestFullscreen) {
        divObj.requestFullscreen();
    }
    else if (divObj?.msRequestFullscreen) {
        divObj.msRequestFullscreen();
    }
    else if (divObj?.mozRequestFullScreen) {
        divObj.mozRequestFullScreen();
    }
    else if (divObj?.webkitRequestFullscreen) {
        divObj.webkitRequestFullscreen();
    }
    else {
        console.log("Fullscreen API is not supported");
    }

}