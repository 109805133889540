import { useAuth0 } from "@auth0/auth0-react"
import { AdRow, AdSchema, AuthToken, Folder, TableActionKey } from "@declarations"
import { createAd, searchAdsByCreator, updateAd } from "Api/Api/ads"
import { createFolder, FolderSubmission, searchFolderContents, searchFolders, searchFoldersByName, updateFolder, updateFolderName } from "Api/Api/folder"
import { SearchInput } from "Components/AdForm/Shared/side-form.styles"
import CreateFolderModal from "Components/Modals/CreateFolderModal/create-folder-modal.component"
import { ContentBody } from "Components/PageLayout/page-layout.styles"
import Table from "Components/Table/Table"
import jwtDecode from "jwt-decode"
import { useCallback, useEffect, useState } from "react"
import { Button } from "Styles/library/button"
import { SectionTitle } from "Styles/library/titles"
import { generateEmbedLink } from "Utils/embedLink"
import { Links, URLs } from "Values/Constants"
import { Header } from "./my-ads.styles"
import ChevronRight from 'Assets/images/arrows/chevron-right.svg'
import { useHistory, useLocation } from "react-router"
import { Link } from "react-router-dom"
import { AxiosResponse } from "axios"
import _debounce from 'lodash/debounce'
import Modal from 'Components/Modals/Modal/modal.component'

const MyAdsC = () => {
    const history = useHistory();
    const [pageNum, setPageNum] = useState<number>(1);
    const [active, setActive] = useState<number>(1)

    const { getAccessTokenSilently } = useAuth0();

    const [myAds, setMyAds] = useState<AdSchema[]>([])
    const [myAdRows, setMyAdRows] = useState<AdRow[]>([])
    const [folderAncestors, setFolderAncestors] = useState<string[] | null>(null)

    const [duplicateSuccess, setDuplicateSuccess] = useState(false)
    const [duplicateFailure, setDuplicateFailure] = useState(false)

    const [archiveSuccess, setArchiveSuccess] = useState(false)
    const [archiveFailure, setArchiveFailure] = useState(false)

    const [moveToSuccess, setMoveToSuccess] = useState(false)
    const [moveToFailure, setMoveToFailure] = useState(false)

    const [tableActionItems, setTableActionItems] = useState({
        key: "" as TableActionKey,
        adId: "",
        path: "",
        destination: [] as string[] || null,
        folder: false,
    })

    const [showFolderModal, setShowFolderModal] = useState<boolean>(false)

    const toggleFolderModal = () => {
        setShowFolderModal(!showFolderModal)
    }

    const [updatingFolder, setUpdatingFolder] = useState({ name: "", path: "" })

    const [showRenameFolderModal, setShowRenameFolderModal] = useState<boolean>(false)

    const toggleRenameFolderModal = () => {
        setShowRenameFolderModal(!showRenameFolderModal)
    }

    const location = useLocation()
    useEffect(() => {
        const p = location.pathname
        const ancestorsFull = p.split("/").slice(2)
        const ancestors = ancestorsFull.map(a => a.replaceAll(',', ''))
        if (ancestors.length) {
            setFolderAncestors(ancestors)
        } else {
            setFolderAncestors(null)
        }
        clearSearch()
        setPageNum(1)
        setActive(1)
    }, [location])

    const handleUpdateFolderName = async (newName: string) => {

        try {
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })
            const tkn: AuthToken = jwtDecode(accessToken)
            const creatorClientId = tkn["https://thdy/app_md"].client_id || null

            const update: Record<string, any> = {
                "_id.name": newName
            }
            if (!updatingFolder.name || typeof updatingFolder.path === "undefined") return
            const res = await updateFolderName(updatingFolder.name, updatingFolder.path, creatorClientId, update, accessToken)
            console.log("SUCCESSFUL UPDATE ", res)
            // if we moved at all, we moved out of current directory, so simply filter out successfully moved folder
            const rows = [...myAdRows].filter(a => a.name !== updatingFolder.name)
            const a = res.data.data as Folder
            rows.push({
                name: a._id.name,
                id: "--",
                path: a._id.path,
                createdDate: a.createdDate || "",
                lastModifiedDate: a.updatedDate || "",
                folder: true
            })
            setMyAdRows(rows)
        } catch (e) {
            console.error("Error updating folder", e)
        }
    }

    const [search, setSearch] = useState<string>("")
    const [triggerSearch, setTriggerSearch] = useState<boolean>(false)
    const debouncedSearch = useCallback(_debounce(() => setTriggerSearch(true), 500), [])

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        setSearch(val)
        debouncedSearch()
        setPageNum(1)
        setActive(1)
    }
    const clearSearch = () => {
        setSearch("")
    }

    useEffect(() => {

        const getAds = async () => {
            if (search && !triggerSearch) return

            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const tkn: AuthToken = jwtDecode(accessToken)
            const creatorClientId = tkn["https://thdy/app_md"].client_id || null

            console.log("folderAncestors=", folderAncestors)

            //setting folder search on init load
            let newancestors = null
            if (!folderAncestors) {
                const p = location.pathname
                newancestors = p.split("/").slice(2)
                newancestors = newancestors.length === 0 ? null : newancestors.join(",")
            }
            console.log("newancestors=", newancestors)

            // get folders
            const ancestors = folderAncestors === null ? newancestors : `${folderAncestors.join(",")}`
            let res0: AxiosResponse<any>
            if (search) {
                res0 = await searchFoldersByName(search, creatorClientId, accessToken)
            } else {
                res0 = await searchFolderContents(creatorClientId, ancestors, accessToken)
            }

            let result0: AdRow[] = []
            for (const a of res0.data as Folder[]) {
                result0.push({
                    name: a._id.name,
                    id: "--",
                    path: a._id.path,
                    createdDate: a.createdDate || "",
                    lastModifiedDate: a.updatedDate || "",
                    folder: true
                })
            }

            // get ads

            let res: AxiosResponse<any>
            if (search) {
                res = await searchAdsByCreator(accessToken, creatorClientId, undefined, search)
            } else if (ancestors) {
                res = await searchAdsByCreator(accessToken, creatorClientId, ancestors, undefined)
            } else {
                res = await searchAdsByCreator(accessToken, creatorClientId, "rootfolder", undefined)
            }

            console.log("ALL ADS RES ", res)
            let result: AdRow[] = []
            for (const a of res.data.data as AdSchema[]) {
                // if (a.deleted) continue
                result.push({
                    name: a.adName,
                    id: a._id || "",
                    path: a.folderPath,
                    createdDate: a.createdDate || "",
                    lastModifiedDate: a.updatedDate || "",
                    folder: false
                })
            }
            setMyAds(res.data.data as AdSchema[])
            setMyAdRows([...result0, ...result])

            setTriggerSearch(false)
        }

        getAds()

    }, [folderAncestors, getAccessTokenSilently, search, triggerSearch])


    const receiveTableAction = async (key: TableActionKey, adId: string, path?: string | null, destination?: string[] | null, folder?: boolean) => {

        setTableActionItems({
            key: key,
            adId: adId,
            path: path ? path : "",
            destination: destination ? destination : [] as string[],
            folder: folder ? folder : false,
        })

        if (key === "duplicate") {

            const ad = myAds.find(a => a._id === adId)
            if (!ad) return

            try {
                // GET AUTH0 TOKEN
                const accessToken = await getAccessTokenSilently({
                    audience: URLs.threedyAuth0Audience,
                    scope: "read:current_user",
                })

                const duplicate = { ...ad }
                delete duplicate._id
                duplicate.adName = duplicate?.adName + " copy"
                duplicate.createdDate = undefined
                duplicate.updatedDate = undefined

                const res = await createAd(duplicate, accessToken)
                console.log("DUPLICATE RES", res)
                const newAd: AdSchema = res.data.data
                const newRow: AdRow = {
                    name: newAd.adName,
                    id: newAd._id || "",
                    path: newAd.folderPath,
                    createdDate: newAd.createdDate || "",
                    lastModifiedDate: newAd.updatedDate || "",
                    folder: false
                }

                setMyAds(a => [...a, newAd])
                setMyAdRows(a => [...a, newRow])
                setDuplicateSuccess(true)

            } catch (e) {
                console.error("ERROR duplicating banner", e)
                setDuplicateFailure(true)
            }
        } else if (key === "share") {
            const ad = myAds.find(a => a._id === adId)
            console.log("SHARING BANNER ", ad)
            if (ad) {
                const link = generateEmbedLink(ad, false)
                navigator.clipboard.writeText(link)
            }
        } else if (key === "archive") {
            try {
                // GET AUTH0 TOKEN
                const accessToken = await getAccessTokenSilently({
                    audience: URLs.threedyAuth0Audience,
                    scope: "read:current_user",
                })

                const tkn: AuthToken = jwtDecode(accessToken)
                const creatorClientId = tkn["https://thdy/app_md"].client_id || null

                // check if Archives folder exists or create

                const existsRes = await searchFolders('Archives', null, creatorClientId, accessToken)

                console.log('ARCHIVES EXISTS ? ', existsRes)
                if (!existsRes.data?.length) {
                    // create folder

                    console.log('CREATING ARCHIVES FOLDER')
                    const tkn: AuthToken = jwtDecode(accessToken)
                    const clientId = tkn["https://thdy/app_md"].client_id || null

                    const submission: FolderSubmission = {
                        _id: {
                            name: 'Archives',
                            path: null,
                            creatorClientId: clientId
                        },
                    }
                    const createRes = await createFolder(submission, accessToken)

                    const newFolder: Folder = createRes.data.data
                    const newRow: AdRow = {
                        name: newFolder._id.name,
                        id: "--",
                        path: newFolder._id.path,
                        createdDate: newFolder.createdDate || "",
                        lastModifiedDate: newFolder.updatedDate || "",
                        folder: true
                    }
                    console.log('NEW ROW ', newRow);

                    setMyAdRows(rows => [...rows, newRow])

                }

                if (folder) {
                    const update: Record<string, any> = {
                        "_id.path": ',Archives,'
                    }
                    if (!adId || typeof path === "undefined") return
                    const res = await updateFolder(adId, path, creatorClientId, update, accessToken)
                    console.log("SUCCESSFUL UPDATE ", res)
                    // if we moved at all, we moved out of current directory, so simply filter out successfully moved folder
                    setMyAdRows(rows => rows.filter(a => a.name !== adId))
                } else {
                    const update: Partial<Record<keyof AdSchema, any>> = {
                        folderPath: ',Archives,'
                    }
                    if (!adId) return
                    const res = await updateAd(adId, update, accessToken)
                    console.log("SUCCESSFUL UPDATE ", res)
                    // if we moved at all, we moved out of current directory, so simply filter out successfully moved ad
                    setMyAds(ads => ads.filter(a => a._id !== adId))
                    setMyAdRows(rows => rows.filter(a => a.id !== adId))
                }

                setArchiveSuccess(true)

                return

                // const update: Partial<Record<keyof AdSchema, any>> = {
                //     deleted: true
                // }

                // const res = await updateAd(adId, update, accessToken)
                // console.log("RES from archiving ad", res)

                // const filteredAds = myAds.filter(a => a._id !== adId)
                // setMyAds(filteredAds)
                // const filteredRows = myAdRows.filter(a => a.id !== adId)
                // setMyAdRows(filteredRows)
            }
            catch (e) {
                console.error("ERROR archiving banner", e)
                setArchiveFailure(true)
            }
        } else if (key === "moveTo") {

            try {
                // GET AUTH0 TOKEN
                const accessToken = await getAccessTokenSilently({
                    audience: URLs.threedyAuth0Audience,
                    scope: "read:current_user",
                })
                const tkn: AuthToken = jwtDecode(accessToken)
                const creatorClientId = tkn["https://thdy/app_md"].client_id || null

                if (folder) {
                    const update: Record<string, any> = {
                        "_id.path": destination === null ? destination : `,${destination?.join(",")},`
                    }
                    if (!adId || typeof path === "undefined") return
                    const res = await updateFolder(adId, path, creatorClientId, update, accessToken)
                    console.log("SUCCESSFUL UPDATE ", res)
                    // if we moved at all, we moved out of current directory, so simply filter out successfully moved folder
                    setMyAdRows(rows => rows.filter(a => a.name !== adId))
                } else {
                    const update: Partial<Record<keyof AdSchema, any>> = {
                        folderPath: destination === null ? destination : `,${destination?.join(",")},`
                    }
                    if (!adId) return
                    const res = await updateAd(adId, update, accessToken)
                    console.log("SUCCESSFUL UPDATE ", res)
                    // if we moved at all, we moved out of current directory, so simply filter out successfully moved ad
                    setMyAds(ads => ads.filter(a => a._id !== adId))
                    setMyAdRows(rows => rows.filter(a => a.id !== adId))
                }

                setMoveToSuccess(true)

            } catch (e) {
                console.error("Error updating", e)
                setMoveToFailure(true)
            }

        } else if (key === "rename" && folder) {
            console.log("RENAME ", adId, path, folder)

            setUpdatingFolder({ name: adId, path: path as string })
            setShowRenameFolderModal(true)

        } else if (key === "showfolder" && search) {
            console.log("SHOWFOLDER search = ", search)
            const ad = myAds.find(a => a._id === adId)
            if (!ad) return
            let linksArray = ad?.folderPath?.split(",")
            let link = Links.myAds
            linksArray?.forEach((item, index) => { if (item) { link = link + "/" + item } })
            history.push(link)
        }
    }

    const handleCreateFolder = async (name: string) => {
        try {

            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const tkn: AuthToken = jwtDecode(accessToken)
            const clientId = tkn["https://thdy/app_md"].client_id || null

            const submission: FolderSubmission = {
                _id: {
                    name: name,
                    path: folderAncestors === null ? folderAncestors : `,${folderAncestors.join(",")},`,
                    creatorClientId: clientId
                }
            }
            const res = await createFolder(submission, accessToken)
            const newFolder: Folder = res.data.data
            const newRow: AdRow = {
                name: newFolder._id.name,
                id: "--",
                path: newFolder._id.path,
                createdDate: newFolder.createdDate || "",
                lastModifiedDate: newFolder.updatedDate || "",
                folder: true
            }

            setMyAdRows([...myAdRows, newRow])

        } catch (e) {
            console.error("ERROR creating folder", e)
        }
    }

    useEffect(() => {
        console.log('MY AD ROWS ', myAdRows)
    }, [myAdRows])

    return (
        <ContentBody>
            <SectionTitle style={{ padding: 0 }}>
                {folderAncestors === null
                    ? <h1>All banners and folders</h1>
                    :
                    <>
                        <Link to={Links.myAds}>All Banners</Link>
                        <img src={ChevronRight} alt="arrow" />
                        {(folderAncestors as string[]).map((f: string, idx: number) => {
                            if (idx === folderAncestors.length - 1) {
                                return <h1 key={f + idx}>{f}</h1>
                            }
                            return <div key={f + idx}>
                                <Link to={`${Links.myAds}${folderAncestors.filter((_: string, index: number) => index < idx).map(fo => fo ? `/${fo}` : '')}/${f}`}>{f}</Link>
                                <img src={ChevronRight} alt="arrow" />
                            </div>
                        })}
                    </>
                }
            </SectionTitle>
            <Header>
                <SearchInput maxWidth={496} value={search} onChange={handleSearch} placeholder={"Search all banners..."} />
                <Button variant="primary" onClick={toggleFolderModal}>Create Folder</Button>
            </Header>
            <Table
                title=""
                showTitle={false}
                limit={8}
                pagination={true}
                headers={["name", "id", "createdDate", "lastModifiedDate"] as Array<keyof AdRow>}
                data={myAdRows
                    // .filter(r => r.id.toLowerCase().includes(search.toLowerCase()) || r.name.toLowerCase().includes(search.toLowerCase()))
                    .sort((a: AdRow, b: AdRow) => (a.folder === b.folder) ? 0 : a.folder ? -1 : 1)
                }
                sortable={["name", "createdDate", "lastModifiedDate"] as Array<keyof AdRow>}
                sendTableAction={receiveTableAction}
                ads={myAds}
                searchOrigin={search}
                pageNum={pageNum}
                setPageNum={setPageNum}
                active={active}
                setActive={setActive}
            />
             {duplicateSuccess &&
                <Modal
                    title={`Success!`}
                    text={`3D banner was duplicated.`}
                    callback={() => {
                        setDuplicateSuccess(false)
                    }}
                    callbackText={`Close`}
                    handleClose={() => setDuplicateSuccess(false)}
                    buttonVariant="primary"
                    showGoBack={false}
                />}
            {duplicateFailure &&
                <Modal
                    title={`Duplication Failed`}
                    text={`Error trying to duplicate 3D banner.`}
                    callback={() => {
                        setDuplicateFailure(false)
                        receiveTableAction(tableActionItems.key, tableActionItems.adId, tableActionItems.path, tableActionItems.destination, tableActionItems.folder)
                    }}
                    callbackText={`Try Again`}
                    handleClose={() => setDuplicateFailure(false)}
                    buttonVariant="danger-fill"
                    showGoBack={true}
                />}
            {archiveSuccess &&
                <Modal
                    title={`Success!`}
                    text={`${tableActionItems.folder ? "Folder" : "3D banner"} was archived.`}
                    callback={() => {
                        setArchiveSuccess(false)
                    }}
                    callbackText={`Close`}
                    handleClose={() => setArchiveSuccess(false)}
                    buttonVariant="primary"
                    showGoBack={false}
                />}
            {archiveFailure &&
                <Modal
                    title={`Archiving Failed`}
                    text={`Error trying to archive ${tableActionItems.folder ? "folder" : "3D banner"}.`}
                    callback={() => {
                        setArchiveFailure(false)
                        receiveTableAction(tableActionItems.key, tableActionItems.adId, tableActionItems.path, tableActionItems.destination, tableActionItems.folder)
                    }}
                    callbackText={`Try Again`}
                    handleClose={() => setArchiveFailure(false)}
                    buttonVariant="danger-fill"
                    showGoBack={true}
                />}
            {moveToSuccess &&
                <Modal
                title={`Success!`}
                text={`${tableActionItems.folder ? "Folder" : "3D banner"} was moved.`}
                callback={() => {
                    setMoveToSuccess(false)
                    history.push(Links.myAds + "/" + tableActionItems.destination)
                }}
                callbackText={`Go to Folder`}
                callback2={() => {
                    setMoveToSuccess(false)
                }}
                callback2Text={`Close`}
                callback2Variant="secondary"
                handleClose={() => setMoveToSuccess(false)}
                buttonVariant="primary"
                showGoBack={false}
                />}
             {moveToFailure &&
                <Modal
                    title={`Move To Failed`}
                    text={`Error trying to move ${tableActionItems.folder ? "folder" : "3D banner"}.`}
                    callback={() => {
                        setMoveToFailure(false)
                        receiveTableAction(tableActionItems.key, tableActionItems.adId, tableActionItems.path, tableActionItems.destination, tableActionItems.folder)
                    }}
                    callbackText={`Try Again`}
                    handleClose={() => setMoveToFailure(false)}
                    buttonVariant="danger-fill"
                    showGoBack={true}
                />}
            {showFolderModal &&
                <CreateFolderModal
                    callback={(folderName: string) => {
                        let checkedFolderName = ""
                        for (let i = 0; i < folderName.length; i++) {
                            if (folderName.charAt(i) === "/" || folderName.charAt(i) === "\\") {
                                checkedFolderName += "-"
                            } else {
                                checkedFolderName += folderName.charAt(i)
                            }
                        }
                        handleCreateFolder(checkedFolderName)
                        toggleFolderModal()
                    }}
                    handleClose={toggleFolderModal}
                    h2="Create Folder"
                    p="here"
                    existingFolders={myAdRows.filter(a => a.folder === true).map(a => a.name)}
                />}
            {showRenameFolderModal &&
                <CreateFolderModal
                    callback={(folderName: string) => {
                        let checkedFolderName = ""
                        for (let i = 0; i < folderName.length; i++) {
                            if (folderName.charAt(i) === "/" || folderName.charAt(i) === "\\") {
                                checkedFolderName += "-"
                            } else {
                                checkedFolderName += folderName.charAt(i)
                            }
                        }
                        handleUpdateFolderName(checkedFolderName)
                        toggleRenameFolderModal()
                    }}
                    handleClose={toggleRenameFolderModal}
                    h2="Rename Folder"
                    p="here"
                    existingFolders={myAdRows.filter(a => a.folder === true).map(a => a.name)}
                    placeholderValue={updatingFolder.name}
                />}
        </ContentBody>
    )
}

export default MyAdsC