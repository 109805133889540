import styled from "styled-components";

export const ImageSelectionSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 650px;
    margin: -12px 24px;
`

export const ImageTilesSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 650px;
    margin-bottom: 48px;

    h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 24px;
    }

`
export const ImageTileRow = styled.div`
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(192px, auto));
    align-items: center;
    justify-content: center;
    width: 100%;

    .selected {
        border: 2px solid #353E5A;
    } 

    .notSelected {
        border: 1px solid #858993;
    }
`

export const ImageTileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

type BackgroundProps = {
    selected: boolean
}

export const ImageTileButton = styled.button<BackgroundProps>`
    height: 160px;
    width: 192px;
    
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    /* Tiny */
    
    box-shadow: 0px 3px 12px rgba(185, 185, 185, 0.2);
    overflow: hidden;

    ${({ selected }) => {
        if (selected) {
            return `
            border: 2px solid #353E5A;
            box-shadow:0px 1px 7px 1px #333;
            `
        } else {
            return `
            border: 1px solid #858993;
            `
        }
    }}

    &:disabled {
        background: transparent;
        border: 1px dashed #858993;
        pointer-events: none;
        opacity: 100%;
    }

    h3 {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #717171;
        margin-top: 8px;
    }

    .template-img {
        width: 100%;
        height: auto;
    }
`