import { AdSchema, AppState, ArPlacement, AuthToken, PageProps, RotateDirection } from "@declarations"
import { Link, useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { store } from "Store/store"
import { useState } from "react"
import AdFormPageWrapper from "Components/AdForm/Page/ad-form-page-wrapper.component"
import AdFormHeader from "Components/AdForm/Header/ad-form-header.component"
import AdFormFooter from "Components/AdForm/Footer/ad-form-footer.component"
import StepButton from "Components/AdForm/StepButton/step-button.component"
import { AdForm, Links, URLs } from "Values/Constants"
import AdFormBody from "Components/AdForm/Body/ad-form-body.component"
import ImageViewer from "Components/ImageViewer/image-viewer.component"
import { FlexWrappedPage } from "Styles/library/page"
import { ReviewBox, ReviewRow, ReviewSection } from "Components/AdForm/Shared/review-form.styles"
import { Actions, makeAction } from "Store/Actions/ActionTypes"
import { PreviewContainer } from "Components/ImageViewer/image-viewer.styles"
import { SideFormWrapper } from "Components/AdForm/Shared/side-form.styles"
import Modal from "Components/Modals/Modal/modal.component"
import { createAd } from "Api/Api/ads"
import { useAuth0 } from "@auth0/auth0-react"
import jwtDecode from "jwt-decode"
import { useRef } from 'react';
import { updateAd } from "Api/Api/ads"
import { DivContainer, DivBox } from "Components/AdForm/Body/ad-form-body.styles"
import { Button } from "Styles/library/button"
import Progress from "../../Components/AdForm/Progress/progress.component"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    previousStep: string
    nextStep: string
}

const CURRENT_STEP = 6

const AdFormReview = (props: Props) => {
    const childRef = useRef<any>(null);

    const { previousStep, adForm, clearForm } = props

    const history = useHistory();

    const { getAccessTokenSilently } = useAuth0();

    const [pendingSubmission, setPendingSubmission] = useState<boolean>(false)

    const [resultAd, setResultAd] = useState<AdSchema>()

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)

    const [showCreateErrorModal, setShowCreateErrorModal] = useState<boolean>(false)

    const [showUpdateErrorModal, setShowUpdateErrorModal] = useState<boolean>(false)

    const [showUpdateSuccessModal, setShowUpdateSuccessModal] = useState<boolean>(false)

    const saveProgress = async () => {
        setPendingSubmission(true)
        try {
            setShowUpdateErrorModal(false)
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })
            const objectUrl = await childRef.current.submitBlobFile(accessToken)
            const update: Partial<Record<keyof AdSchema, any>> = {
                expwidth: adForm.modelWidth as number,
                expheight: adForm.modelHeight as number,
                expleft: adForm.modelXCoordinate as number,
                exptop: adForm.modelYCoordinate as number,
                camInitialDist: adForm.cameraDistance as number,
                camHeightAngle: adForm.cameraVerticalAngle as number,
                paramInitialAngle: adForm.cameraHorizontalAngle as number,
                camDisableZoom: !adForm.zooming,
                paramShadows: adForm.shadows,
                shadowIntensity: adForm.shadowIntensity,
                shadowSoftness: adForm.shadowEdgeSoftness,
                autoRotate: adForm.autoRotate,
                autoRotateSpeed: adForm.autoRotateSpeed as number,
                autoRotateDirection: adForm.autoRotateDirection as RotateDirection,
                arEnabled: adForm.viewInArButton,
                arZoneLeft: adForm.viewInArButtonXCoordinate as number || 0,
                arZoneTop: adForm.viewInArButtonYCoordinate as number || 0,
                arPlacement: adForm.arPlacement as ArPlacement === "On the floor" ? "floor" : "wall",
                ctaMessage: adForm.customBannerCtaLabel as string,
                staticLoad: objectUrl,
                //models
                usdzLoc: adForm.model.models.usdz.url,
                glbLoc: adForm.model.models.glb.url,
                threedyModelName: adForm.model.title,
                threedyModelSKU: adForm.model.sku,
                consumerClientId: adForm.model.client_category_id
            }

            if (!adForm._id) return
            const res = await updateAd(adForm._id, update, accessToken)
            console.log("SUCCESSFUL UPDATE ", res)
            setResultAd(res.data.data as AdSchema)
            setShowUpdateSuccessModal(true)
        } catch (e) {
            console.error("Error updating ad", e)
            setShowUpdateErrorModal(true)
        } finally {
            setPendingSubmission(false)
        }
    }

    const submitAd = async () => {
        setPendingSubmission(true)
        try {
            setShowCreateErrorModal(false)
            // GET AUTH0 TOKEN
            const accessToken = await getAccessTokenSilently({
                audience: URLs.threedyAuth0Audience,
                scope: "read:current_user",
            })

            const tkn: AuthToken = jwtDecode(accessToken)
            console.log("TKN", tkn)
            const clientId = tkn["https://thdy/app_md"].client_id || null
            console.log("childRef.current=", childRef.current)

            const objectUrl = await childRef.current.submitBlobFile(accessToken)
            const submission: AdSchema = {
                adName: adForm.title, // step 1, basic details
                clickMacro: adForm.clickMacro,
                usdzLoc: adForm.model.models.usdz.url, // step 4, model
                glbLoc: adForm.model.models.glb.url, // step 4, model
                adHeight: adForm.height, // step 2, dimensions
                adWidth: adForm.width, // step 2, dimensions
                creativeBg: adForm.background, // step 3, background image
                transparent: adForm.transparent,
                arPlacement: adForm.arPlacement === "On the floor" ? "floor" : "wall", // step 5, adjustments, view in AR button
                expwidth: adForm.modelWidth, // step 5, adjustments, edit model (stretch)
                expheight: adForm.modelHeight, // step 5, adjustments, edit model (stretch)
                exptop: adForm.modelYCoordinate, // step 5, adjustments, edit model (drag)
                expleft: adForm.modelXCoordinate, // step 5, adjustments, edit model (drag)
                threedyModelName: adForm.model.title,
                threedyModelSKU: adForm.model.sku,
                camInitialDist: adForm.cameraDistance, // step 5, adjustments, camera controls
                camHeightAngle: adForm.cameraVerticalAngle, // step 5, adjustments, camera controls
                camDisableZoom: !adForm.zooming, // step 5, adjustments, enable zoom
                paramShadows: adForm.shadows, // step 5, adjustments, enable shadows
                autoRotate: adForm.autoRotate, // step 5, adjustments, enable autorotate
                paramInitialAngle: adForm.cameraHorizontalAngle, // step 5, adjustments, camera controls
                // arZonePCTop: adForm.viewInArButtonYCoordinate, // nowhere in form really—exact px noted below, not percentage
                // arZonePCLeft: adForm.viewInArButtonXCoordinate, // nowhere inform really-exact px noted below, not percentage
                // arZoneStyle: string, // nowhere in form except for the preview. does that styling need to be sent in with the form?
                arBannersEnabled: adForm.bannersEnabled,
                arCustomBanner: adForm.customBanner,
                arEnabled: adForm.viewInArButton,
                arCustomButton: adForm.customButton === "text",
                arButtonLabel: adForm.customButton === "image" ? "" : adForm.customButtonLabel, // clear text if image selected. ad tech uses presence of text to determine
                objTitle: adForm.customBannerTitle,
                objSubTitle: adForm.customBannerSubtitle,
                ctaMessage: adForm.customBannerCtaLabel, // step 5, adjustments, custom banner
                arZoneTop: adForm.viewInArButtonYCoordinate, // step 5, adjustments, view in ar button, set button position (drag) 
                arZoneLeft: adForm.viewInArButtonXCoordinate, // step 5, adjustments, view in ar button, set button position (drag) 
                activeURL: adForm.destinationLink, // step 1, basic details
                // trackingLive: true, // nowhere in the form
                // filepath: "", // nowhere in the form
                shadowIntensity: adForm.shadowIntensity, // step 5, adjustments, shadows
                shadowSoftness: adForm.shadowEdgeSoftness, // step 5, adjustments, shadows
                autoRotateSpeed: adForm.autoRotateSpeed, // step 5, adjustments, autorotate
                autoRotateDirection: adForm.autoRotateDirection, // step 5, adjustments, autorotate
                // envImage: "", // nowhere in the form
                staticLoad: objectUrl, // nowhere in the form
                // devMode: false // nowhere in the form,
                creatorClientId: clientId,
                consumerClientId: adForm.model.client_category_id,
                folderPath: adForm.folderPath
            }
            const res = await createAd(submission, accessToken)
            console.log("CREATE BANNER SUCCESSFUL", res)
            setResultAd(res.data.data as AdSchema)
            setShowSuccessModal(true)
        } catch (e) {
            console.error("Error creating banner")
            setShowCreateErrorModal(true)
        } finally {
            setPendingSubmission(false)
        }
    }
    const goToAd = () => {
        if (adForm._id) {
            clearForm()
            history.push(Links.ad.constructor(adForm._id))
        }
    }
    const submitProgress = () => {
        clearForm()
        if (resultAd?._id) {
            history.push(Links.ad.constructor(resultAd._id))
        }
    }

    const submitProgress2 = () => {
        clearForm()
        // history.push(Links.myAds)
        let linksArray = adForm?.folderPath?.split(",")
        let link = Links.myAds
        linksArray?.forEach((item, index) => { if (item) { link = link + "/" + item } })
        history.push(link)
    }

    const goBack = async () => {
        history.push(previousStep)
    }

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    }

    return (
        <AdFormPageWrapper>
            <div className="headerScroll">
                <AdFormHeader
                    currentStep={CURRENT_STEP}
                    totalSteps={AdForm.totalSteps}
                    title="Does everything look correct?"
                    reviewPage={true}
                    updating={adForm.updating}
                    id={adForm._id}
                />
                <Progress currentStep={CURRENT_STEP} totalSteps={AdForm.totalSteps} updating={adForm.updating} />
            </div>
            <AdFormBody>
                <FlexWrappedPage>
                    <DivContainer>
                        <DivBox>
                            <p className="bannerPreviewTitle">Banner Preview</p>
                        </DivBox>
                        <PreviewContainer>
                            <ImageViewer
                                captureBlob={true}
                                ref={childRef}
                                fileUrl={adForm.background}
                                transparent={adForm.transparent}
                                width={adForm.width}
                                height={adForm.height}
                                model={{
                                    width: adForm.modelWidth as number,
                                    height: adForm.modelHeight as number,
                                    xCoordinate: adForm.modelXCoordinate as number,
                                    yCoordinate: adForm.modelYCoordinate as number,
                                    details: adForm.model,
                                    zoom: adForm.zooming,
                                    autoRotate: adForm.autoRotate,
                                    autoRotateSpeed: adForm.autoRotateSpeed,
                                    autoRotateDirection: adForm.autoRotateDirection,
                                    shadowIntensity: adForm.shadowIntensity,
                                    shadowEdgeSoftness: adForm.shadowEdgeSoftness,
                                    cameraControls: true,
                                    cameraDistance: adForm.cameraDistance,
                                    cameraVerticalAngle: adForm.cameraVerticalAngle,
                                    cameraHorizontalAngle: adForm.cameraHorizontalAngle
                                }}
                                showViewInArButton={adForm.viewInArButton}
                                viewInArButton={{
                                    xCoordinate: adForm.viewInArButtonXCoordinate,
                                    yCoordinate: adForm.viewInArButtonYCoordinate,
                                    type: adForm.customButton
                                }}
                            />
                            {/* <ExpandButton onClick={makeImageFullScreen}>
                                <div><img src={ExpandIcon} alt="expand" /></div>
                            </ExpandButton> */}
                        </PreviewContainer>
                    </DivContainer>
                    {!adForm.updating &&
                        <SideFormWrapper style={{ zIndex: 2}}>
                            <ReviewSection>
                                <ReviewBox>
                                    <h2>Banner Details and Size</h2>
                                    <ReviewRow>
                                        <h3>Banner Title</h3>
                                        <h4>{adForm.title}</h4>
                                        <Link className="editLink" to={Links.adForm.basicDetails}>Edit</Link>
                                    </ReviewRow>
                                    <ReviewRow>
                                        <h3>Click URL</h3>
                                        <h4>{adForm.destinationLink}</h4>
                                        <Link className="editLink" to={Links.adForm.basicDetails}>Edit</Link>
                                    </ReviewRow>
                                    <ReviewRow>
                                        <h3>Dimensions</h3>
                                        <h4>{adForm.width} x {adForm.height}</h4>
                                        <Link className="editLink" to={Links.adForm.dimensions}>Edit</Link>
                                    </ReviewRow>
                                </ReviewBox>
                                <ReviewBox>
                                    <h2>Background and Model</h2>
                                    <ReviewRow>
                                        <h3>Background</h3>
                                        <ImageViewer fileUrl={adForm.background} transparent={adForm.transparent} width={adForm.width} height={adForm.height} />
                                        <Link className="editLink" to={Links.adForm.background}>Edit</Link>
                                    </ReviewRow>
                                    <ReviewRow>
                                        <h3>Model Name</h3>
                                        <h4>{adForm.model.title}</h4>
                                        <Link className="editLink" to={Links.adForm.model}>Edit</Link>
                                    </ReviewRow>
                                    <ReviewRow>
                                        <h3>Model SKU</h3>
                                        <h4>{adForm.model.sku}</h4>
                                        <Link className="editLink" to={Links.adForm.model}>Edit</Link>
                                    </ReviewRow>
                                </ReviewBox>
                            </ReviewSection>
                        </SideFormWrapper>
                    }
                </FlexWrappedPage>
                <a onClick={scrollToTop} className="topPageLink">Top of Page</a>
            </AdFormBody>
            <div className="footerScroll">
                <AdFormFooter>
                    <StepButton
                        text="Back"
                        callback={goBack}
                        variant="back"
                    />
                    {/* <StepButton
                        text={"Create my banner"}
                        callback={submitAd}
                        variant="alternate"
                        disabled={false}
                    /> */}
                    <Button
                        className="normalButton"
                        onClick={adForm.updating ? saveProgress : submitAd}
                        variant="alternate"
                        disabled={pendingSubmission}
                    > {adForm.updating ? "Update my banner" : "Create my banner"}</Button>
                    <Button
                        className="smallerButton"
                        onClick={adForm.updating ? saveProgress : submitAd}
                        variant="alternate"
                        disabled={pendingSubmission}
                    > {adForm.updating ? "Update" : "Create"}</Button>
                </AdFormFooter>
            </div>
            {showSuccessModal
                && <Modal
                    title="Success! You've created a new banner."
                    text={`You can now access ${adForm.title} in My Banners. Embed your banner onto a website to begin generating impressions and views.`}
                    callback={submitProgress}
                    callbackText={"Go to My Banner"}
                    callback2={submitProgress2}
                    callback2Text={"View All Banners"}
                    handleClose={submitProgress}
                    ad={resultAd}
                />}
            {showUpdateSuccessModal
                && <Modal
                    title="Success! You've updated your banner."
                    text={`You can access ${resultAd?.adName} in My Banners.`}
                    callback={goToAd}
                    callbackText={"Go to My Banner"}
                    handleClose={goToAd}
                    ad={resultAd}
                />}
            {showCreateErrorModal && <Modal
                title="Sorry! Something went wrong."
                text={`Something has gone wrong along the way, and your banner hasn’t been created. Please try again.`}
                callback={submitAd}
                callbackText={"Try Again"}
                handleClose={() => setShowCreateErrorModal(false)}
                showGoBack={true}
            />}
            {showUpdateErrorModal && <Modal
                title="Sorry! Something went wrong."
                text={`Something has gone wrong along the way, and your banner hasn’t been updated. Please try again.`}
                callback={saveProgress}
                callbackText={"Try Again"}
                handleClose={() => setShowUpdateErrorModal(false)}
                showGoBack={true}
            />}
        </AdFormPageWrapper>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        adForm: state.adForm
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        clearForm: () => dispatch(makeAction(Actions.CLEAR_AD_FORM))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdFormReview)