import { Analytics, DateRange } from "@declarations";
import { getARClicks, getImpressionsCount, getInteractionTime, getTimelineData, getTimelineDataArClicks, getTimelineDataArCTR, getTimelineDataArExps, getTimelineDataCTR, getVerbCounts } from "Api/xApi/query-builder";
import { queryXapi } from "Api/xApi/query-xapi";
import { store } from "Store/store";
import { Actions, makeAction } from "./ActionTypes";

// LOADING
const setLoading = (property: keyof Analytics.Loading, on: boolean) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        const currentLoading = getState().analyticsLoading

        const loading: Analytics.Loading = {
            ...currentLoading,
            [property]: on
        }
        dispatch(makeAction(Actions.SET_ANALYTICS_LOADING, loading))
    }

export const getSummaryData = (id: string, dates: DateRange) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        await dispatch(setLoading("summaryData", true))

        try {
            const queries = []

            const query1 = getVerbCounts(id, dates)
            queries.push(query1)

            const query2 = getInteractionTime(id, dates)
            queries.push(query2)

            const query3 = getARClicks(id, dates)
            queries.push(query3)

            const query4 = getImpressionsCount(id, dates)
            queries.push(query4)

            const res = await Promise.all(queries.map(q => queryXapi(q)))
            const [res1, res2, res4] = res

            // const impressions = res1 ? res1[0]?.Start[0]?.count : 0
            const impressions = res4 && res4[0]?.total_impressions
            const clicks = res1 ? res1[0]?.Clicked[0]?.count : 0
            const interactions = res1 ? res1[0]?.Interacted[0]?.count : 0
            const totalInteractionDuration = res2 ? res2[0]?.totalDuration : 0
            const longestInteractionDuration = res2 ? res2[0]?.longestDuration.seconds : 0
            const averageInteractionDuration = totalInteractionDuration/interactions;
            //const arExperienced = res3 ? res3[0]?.count : 0 // RC this tallies clicks, and is not used currently, there are issues with the loopup in swirl (2 lines down)
            const arExperienced = res1 ? res1[0]?.Opened[0]?.count : 0
            //const arClicked = res3 ? res3[0]?.count : 0 // RC should be removed or fixed and displayed
            const interactionRate = impressions > 0 ? ((interactions / impressions) * 100) : 0

            const currentState = getState().analytics
            const data: Analytics.Data = {
                ...currentState,
                impressions: {
                    ...currentState.impressions,
                    total: impressions
                },
                clicks: {
                    ...currentState.clicks,
                    total: clicks
                },
                ctr: {
                    ...currentState.ctr,
                    total: impressions > 0 ? ((clicks / impressions) * 100) : 0,
                },
                interactions: {
                    ...currentState.interactions,
                    total: interactions,
                    avgDuration: averageInteractionDuration,
                    totalDuration: totalInteractionDuration,
                    avgRate: interactionRate
                },
                arExp: {
                    ...currentState.arExp,
                    total: arExperienced
                }
            }
            dispatch(makeAction(Actions.SET_ANALYTICS_DATA, data))

        }
        catch (e) {
            console.error("Error getting total clicks", e)
        }


        await dispatch(setLoading("summaryData", false))
    }

export const getImpressionsOverTime = (id: string, dates: DateRange) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        await dispatch(setLoading("impressions", true))

        try {
            const query = getTimelineData("loaded", id, dates)
            const res: Analytics.TimelineSlice[] = await queryXapi(query)

            const currentState = getState().analytics
            const data: Analytics.Data = {
                ...currentState,
                impressions: {
                    ...currentState.impressions,
                    overTime: res
                }
            }

            dispatch(makeAction(Actions.SET_ANALYTICS_DATA, data))
        }
        catch (e) {
            console.error("Error getting impressions over time", e)
        }

        await dispatch(setLoading("impressions", false))
    }

export const getClicksOverTime = (id: string, dates: DateRange) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        await dispatch(setLoading("clicks", true))

        try {
            const query = getTimelineData("clicked", id, dates)
            const res: Analytics.TimelineSlice[] = await queryXapi(query)

            const currentState = getState().analytics
            const data: Analytics.Data = {
                ...currentState,
                clicks: {
                    ...currentState.clicks,
                    overTime: res
                }
            }

            dispatch(makeAction(Actions.SET_ANALYTICS_DATA, data))
        }
        catch (e) {
            console.error("Error getting clicks over time", e)
        }

        await dispatch(setLoading("clicks", false))
    }


export const getCtrOverTime = (id: string, dates: DateRange) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        await dispatch(setLoading("ctr", true))

        try {
            const query = getTimelineDataCTR(id, dates)
            const res: Analytics.TimelineSlice[] = await queryXapi(query)

            const currentState = getState().analytics
            const data: Analytics.Data = {
                ...currentState,
                ctr: {
                    ...currentState.ctr,
                    overTime: res
                }
            }

            dispatch(makeAction(Actions.SET_ANALYTICS_DATA, data))
        }
        catch (e) {
            console.error("Error getting CTR over time", e)
        }

        await dispatch(setLoading("ctr", false))
    }

export const getInteractionsOverTime = (id: string, dates: DateRange) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        await dispatch(setLoading("interactions", true))

        try {
            const query = getTimelineData("interacted", id, dates)
            const res: Analytics.TimelineSlice[] = await queryXapi(query)

            const currentState = getState().analytics
            const data: Analytics.Data = {
                ...currentState,
                interactions: {
                    ...currentState.interactions,
                    overTime: res
                }
            }

            dispatch(makeAction(Actions.SET_ANALYTICS_DATA, data))
        }
        catch (e) {
            console.error("Error getting interactions over time", e)
        }

        await dispatch(setLoading("interactions", false))
    }

export const getARExpsOverTime = (id: string, dates: DateRange) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        await dispatch(setLoading("arExp", true))

        try {
            const query = getTimelineDataArExps(id, dates)
            const res: Analytics.TimelineSlice[] = await queryXapi(query)

            const currentState = getState().analytics
            const data: Analytics.Data = {
                ...currentState,
                arExp: {
                    ...currentState.arExp,
                    expOverTime: res
                }
            }

            dispatch(makeAction(Actions.SET_ANALYTICS_DATA, data))
        }
        catch (e) {
            console.error("Error getting ar exps over time", e)
        }

        await dispatch(setLoading("arExp", false))
    }


export const getARClicksOverTime = (id: string, dates: DateRange) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        await dispatch(setLoading("arExp", true))

        try {
            const query = getTimelineDataArClicks(id, dates)
            const res: Analytics.TimelineSlice[] = await queryXapi(query)

            const currentState = getState().analytics
            const data: Analytics.Data = {
                ...currentState,
                arExp: {
                    ...currentState.arExp,
                    clicksOverTime: res
                }
            }

            dispatch(makeAction(Actions.SET_ANALYTICS_DATA, data))
        }
        catch (e) {
            console.error("Error getting ar clicks over time", e)
        }

        await dispatch(setLoading("arExp", false))
    }


export const getARCtrOverTime = (id: string, dates: DateRange) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        await dispatch(setLoading("arExp", true))

        try {
            const query = getTimelineDataArCTR(id, dates)
            const res: Analytics.TimelineSlice[] = await queryXapi(query)

            const currentState = getState().analytics
            const data: Analytics.Data = {
                ...currentState,
                arExp: {
                    ...currentState.arExp,
                    ctrOverTime: res
                }
            }

            dispatch(makeAction(Actions.SET_ANALYTICS_DATA, data))
        }
        catch (e) {
            console.error("Error getting ar ctr over time", e)
        }

        await dispatch(setLoading("arExp", false))
    }


    export const getImpressionData = (id: string, dates: DateRange) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        try {
            
            const query = getImpressionsCount(id, dates)
            const res = await queryXapi(query)
            console.log("janet in analyticactions, getImpressionData, res=", res)
            const impressions = res && res[0]?.total_impressions

            const currentState = getState().analytics
            const data: Analytics.Data = {
                ...currentState,
                impressions: {
                    ...currentState.impressions,
                    total: impressions
                }
            }
            dispatch(makeAction(Actions.SET_ANALYTICS_DATA, data))

        }
        catch (e) {
            console.error("Error getting total impressions", e)
        }
    }
