import MenuIcon from 'Assets/images/icons/menu-icon.svg'
import { useEffect, useState } from 'react'
import { Container, Menu, MenuRow } from './table-actions.styles'
import DuplicateIcon from 'Assets/images/icons/duplicate-icon.svg'
import ShareIcon from 'Assets/images/icons/share-icon.svg'
import MoveToIcon from 'Assets/images/icons/move-to-folder-icon.svg'
import RenameIcon from 'Assets/images/icons/edit-icon.svg'
import ArchiveIcon from 'Assets/images/icons/archive-icon.svg'
import useOutsideClick from 'Utils/useOutsideClick'
import MoveToFolderModal from 'Components/Modals/MoveToFolderModal/move-to-folder-modal.component'
import ShareAdModal from 'Components/Modals/ShareAdModal/share-ad-modal.component'
import Modal from 'Components/Modals/Modal/modal.component'
import { AdSchema, TableAction } from '@declarations'
import { generateEmbedLink } from 'Utils/embedLink'
import { useLocation } from 'react-router'

type Props = {
    adId: string
    ad: AdSchema
    path: string | null
    sendTableAction: TableAction
    folder: boolean
    searchOrigin: string
}

const TableActions = (props: Props) => {

    const { adId, ad, path, sendTableAction, folder, searchOrigin } = props

    const [showMenu, setShowMenu] = useState<boolean>(false)

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }

    const [showMoveToModal, setShowMoveToModal] = useState<boolean>(false)

    const openMoveToModal = () => {
        setShowMoveToModal(true)
        setShowMenu(false)
    }


    const [showShareModal, setShareModal] = useState<boolean>(false)

    const openShareModal = () => {
        setShareModal(true)
        setShowMenu(false)
    }

    const [showArchiveModal, setArchiveModal] = useState<boolean>(false)

    const openArchiveModal = () => {
        setArchiveModal(true)
        setShowMenu(false)
    }

    const { ref: menu, isComponentVisible } = useOutsideClick()

    useEffect(() => {
        if (!isComponentVisible) {
            setShowMenu(false)
        }
    }, [isComponentVisible])

    const [inArchives, setInArchives] = useState<boolean>(false)

    const location = useLocation()
    useEffect(() => {
        const p = location.pathname
        const ancestors = p.split("/").slice(2)
        if (ancestors[0] === 'Archives' || (folder && adId === "Archives")) {
            setInArchives(true)
        } else {
            setInArchives(false)
        }
    }, [adId, folder, location])

    const [autoRotate, setAutoRotate] = useState<boolean>(false)

    const toggleAutoRotate = () => {
        setAutoRotate(!autoRotate)
    }

    return (
        <Container ref={menu}>
            <button onClick={toggleMenu}>
                <img src={MenuIcon} alt="menu" />
            </button>
            {showMenu &&
                <Menu>
                    {/* janet revised */}
                    {!folder && !!searchOrigin && <MenuRow onClick={() => { sendTableAction("showfolder", adId); setShowMenu(!showMenu) }}>  <img src={DuplicateIcon} alt="showfolder" />Show In Folder</MenuRow>}
                    {!folder && <MenuRow onClick={() => { sendTableAction("duplicate", adId); setShowMenu(!showMenu); }}>  <img src={DuplicateIcon} alt="duplicate" />Duplicate</MenuRow>}
                    {!folder && <MenuRow onClick={openShareModal}><img src={ShareIcon} alt="share" />Embed</MenuRow>}
                    <MenuRow onClick={openMoveToModal}><img src={MoveToIcon} alt="move to folder" />Move to...</MenuRow>
                    {folder && <MenuRow onClick={() => sendTableAction("rename", adId, path, undefined, folder)}><img src={RenameIcon} alt="rename" />Rename</MenuRow>}
                    {!inArchives && <MenuRow onClick={openArchiveModal}><img src={ArchiveIcon} alt="archive" />Archive</MenuRow>}
                </Menu>
            }
            {showMoveToModal &&
                <MoveToFolderModal
                    handleClose={() => setShowMoveToModal(false)}
                    callback={(destination: string[] | null) => {
                        sendTableAction("moveTo", adId, path, destination, folder)
                        setShowMoveToModal(false)
                    }}
                    filename={adId}
                />}
            {showShareModal &&
                <ShareAdModal
                    handleClose={() => setShareModal(false)}
                    callback={() => {
                        if (ad && ad._id) {
                            const link = generateEmbedLink(ad, autoRotate)
                            navigator.clipboard.writeText(link)
                        }
                    }}
                    toggle={autoRotate}
                    sendToggle={toggleAutoRotate}
                    h2="Embed Banner"
                    h3="Get Embed Link"
                    p={ad && ad._id ? generateEmbedLink(ad, autoRotate) : ''}
                />}
            {showArchiveModal &&
                <Modal
                    title={`You’re about to archive this ${folder ? 'folder' : '3D banner'}.
                    Are you sure?`}
                    text={`This ${folder ? 'folder' : '3D banner'} will be moved to the archived folder. Any embeds will no longer be active, and all shared access will be lost.
                    You can restore this ${folder ? 'folder' : 'banner'} from your archive folder.`}
                    callback={() => {
                        sendTableAction("archive", adId, path, undefined, folder)
                        setArchiveModal(false)
                    }}
                    callbackText={`Yes, archive this ${folder ? 'folder' : 'banner'}`}
                    handleClose={() => setArchiveModal(false)}
                    buttonVariant="danger-fill"
                    showGoBack={true}
                />
                // <ArchiveAdModal
                //     handleClose={() => setArchiveModal(false)}
                //     callback={() => setArchiveModal(false)}
                // />
            }
        </Container >
    )
}

export default TableActions