import PageFooter from "Components/PageFooter/page-footer.component"
import PageHeader from "Components/PageHeader/page-header.component"
import { ReactElement } from "react"
import { Layout, LayoutBody } from "./page-layout.styles"

type Props = {
    children: ReactElement | ReactElement[]
    preview?: boolean
}

const PageLayout = (props: Props) => {

    const { children, preview } = props

    return (
        <Layout>
            <PageHeader preview={preview}/>
            <LayoutBody>
                {children}
            </LayoutBody>
            <PageFooter preview={preview} />
        </Layout>
    )

}

export default PageLayout