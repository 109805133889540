import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`

export const Menu = styled.div`
    position: absolute;
    top: 2.5rem;
    right: 21.5px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 3px 12px rgba(185, 185, 185, 0.2);
    padding: 22px;
    z-index: 100;
    width: 265px;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    &:after {
        content: "";
        position: absolute;
        position: absolute;
        right: 5.5%;
        bottom: 100%;
        width: 0;
        height: 0;
        border-bottom: solid 10px white;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
    }
`

export const MenuRow = styled.button`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */


    /* Text Colours/Bodycopy */

    color: #353E5A;

    padding: 14px 0;

    img {
        margin-right: 12px;
        width: 20px;
        height: auto;
    }
`