import { Analytics, ReduxAction } from "@declarations";
import { Actions } from "Store/Actions/ActionTypes";

const initialState: Analytics.Data = {
    impressions: {
        total: 0,
        overTime: []
    },
    clicks: {
        total: 0,
        overTime: []
    },
    ctr: {
        total: 0,
        overTime: []
    },
    interactions: {
        total: 0,
        avgRate: 0,
        totalDuration: 0,
        avgDuration: 0,
        overTime: []
    },
    arExp: {
        total: 0,
        expOverTime: [],
        clicksOverTime: [],
        ctrOverTime: [],
        ctr: 0
    }
}

export const analytics = (state: Analytics.Data = initialState, { type, payload }: ReduxAction): Analytics.Data => {
    switch (type) {
        case Actions.SET_ANALYTICS_DATA:
            return payload as Analytics.Data
        case Actions.CLEAR_ANALYTICS_DATA:
            return initialState
        default:
            return state
    }
}