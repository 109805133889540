import styled from 'styled-components';
import { Box } from 'Styles/library/box';

export const Container = styled.div`
    padding: 3.5rem 0;
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items:center;
`;

export const FAQList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // max-width: 64.5rem;
    // margin: 2rem 3rem;
    // padding: 2rem 3rem;
    padding: 2rem 106px;


    @media screen and (max-width: 1000px) {
        padding: 2rem 24px;
    }
`;

type FAQBarProps = {
    expanded: boolean;
}

export const FAQBar = styled(Box) <FAQBarProps>`
    margin: 0.5rem 0;
    min-height: 69px;
    transition: all ease 0.3s;

    &:hover {
        transform: translateY(-4px);
        box-shadow: 0px 4px 16px 5px rgba(0, 0, 0, 0.2);
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    button {
        background: transparent;
        border: none;
        padding: ${({ expanded }) => expanded ? "1.5rem 3rem 1rem;" : "1.5rem 3rem;"}; 
    }

    button:focus {
        outline: none;
    }

    .chevron {
        width: 1rem;
        height: auto;
        margin-top: 0.35rem;
        transform:  ${({ expanded }) => expanded ? "rotate(180deg)" : ""};
        transition: all 0.3s;
        filter: ${({ theme }) => theme.effects.iconFilter};
    }

    h4 {
        color: ${({ theme }) => theme.colors.textPrimary};
        font: ${({ theme }) => theme.fonts.h4Regular};
        margin: 0;
        margin-right: 1rem;
        text-align: left;
    }

    .q-container {
        overflow: hidden;
    }

    .q-expander {
        margin-top: ${({ expanded }) => expanded ? "0" : "-150%"};
        transition: all 0.3s;
    }

    p {
        font: ${({ theme }) => theme.fonts.body1Regular};
        text-align: left;
        margin: 0;
        padding: 0rem 3rem 1.5rem;
    }
`;