import { useEffect, useState } from "react"
import { Bar, Pbar } from "./progress.styles"

type Props = {
    currentStep: number
    totalSteps: number
    updating: boolean
}

const Progress = (props: Props) => {

    const { currentStep, totalSteps, updating } = props

    const [width, setWidth] = useState<string>("")

    useEffect(() => {
        if (updating) {
            setWidth(`${100}%`)
            return
        }
        let percent = currentStep / totalSteps * 100
        if (percent === 100) {
            percent = 100 - 5
        } else if (percent > 100) {
            percent = 100
        }
        setWidth(`${percent}%`)
    }, [currentStep, totalSteps, updating])

    return (
        <Bar>
            <Pbar width={width} />
        </Bar>
    )
}

export default Progress