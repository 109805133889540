import { AdSchema } from "@declarations"
import axios from "axios"
import useSWR from "swr"

export const getHealthCheck = (token: string): any => {
    const path = ""
    return axios.get(path, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const GetAllAds = () => {
    const path = "ads"
    return useSWR(path);
}

export const getAllAds = (token: string) => {
    const path = "ads"
    return axios.get(path, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}
//janet: this is where searching folderpath is at
export const searchAdsByCreator = (token: string, creatorClientId: number | null, folderPath?: string | null, name?: string) => {
    const path = `ads/searchByClient?creatorClientId=${creatorClientId}${folderPath ? '&path=' + folderPath : ''}${name ? '&name=' + name : ''}`
    return axios.get(path, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const searchAds = (id: string, token?: string) => {
    const path = `ads/search?id=${id}`
    return axios.get(path, {
        // headers: {
        //     "Authorization": 'Bearer ' + token
        // }
    })
}


export const createAd = (submission: AdSchema, token: string) => {
    const path = "ads"
    return axios.post(path, submission, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const updateAd = (id: string, data: Partial<Record<keyof AdSchema, any>>, token: string) => {
    const path = `ads/${id}`
    return axios.patch(path, data, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const deleteAd = (id: string) => {
    const path = `ads/${id}`
    return axios.delete(path)
}