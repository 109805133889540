import { useEffect } from "react"
import { useState } from "react"
import { Charts } from "../Values/Constants"

const useChartWidth = () => {

    const [width, setWidth] = useState<number>(0)

    useEffect(() => {
        const updateSize = () => {
            const w = window.innerWidth - Charts.chart_width_difference
            setWidth(w)
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    return width
}

export default useChartWidth