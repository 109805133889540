import { AdSchema } from "@declarations"
import { URLs } from "Values/Constants"

export const generateEmbedLink = (ad: AdSchema, auto?: boolean, preview?: boolean) => {

    const width = ad.adWidth
    const height = ad.adHeight

    const clickMacro = ad.clickMacro
    const url = `${ad.activeURL}${preview ? '?s=1' : ''}`
    const src = encodeURI(`${URLs.url}/v3p06/experience.html?i=${ad._id}&clicktracker=${clickMacro}​​​​​​${url ? `&url=${url}` : ''}&live=1${auto ? '&a=1' : ''}`)

    return `<div style="position:relative;padding-top:min(${height}px, 
        calc((${height}/${width}) * 100%));"><iframe id="ntar-live-interactive" 
        style="overflow:hidden;width:100%;max-width:${width}px;height:100%;
        max-height:${height}px;margin-top:0;margin-right:auto;margin-bottom:0px;
        margin-left:auto;position:absolute;top:0;left:0;right:0;bottom:0" allowtransparency="true" 
        scrolling="no" frameborder="0" src="${src}">
        </iframe></div>
    `
}