import { ReactElement } from "react"
import { Body } from "./ad-form-body.styles"

type Props = {
    children: ReactElement | ReactElement[]
}

const AdFormBody = (props: Props) => {

    const { children }  = props

    return (
        <Body>
            {children}
        </Body>
    )
}

export default AdFormBody