import { Ad, AppState, PageProps } from "@declarations"
import MyAdsC from "Components/MyAds/my-ads.component"
import PageLayout from "Components/PageLayout/page-layout.component"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { makeAction, Actions } from "Store/Actions/ActionTypes"
import { initialAdState } from "Store/Reducers/ad-form"
import { store } from "Store/store"
import { Banner, BannerText } from "Styles/library/banner"
import { Button } from "Styles/library/button"
import { Links } from "Values/Constants"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {

}
const MyAds = (props: Props) => {

    const { setAdFolder } = props

    const history = useHistory()

    const location = useLocation()

    const goToCreate = async () => {
        const p = location.pathname
        const ancestors = p.split("/").slice(2)
        let folderPath = null
        if (ancestors.length) {
            folderPath = `,${ancestors.join(",")},`
        }
        await setAdFolder(folderPath)
        history.push(Links.adForm.basicDetails)
    }

    return (
        <PageLayout>
            <Banner>
                <BannerText>
                    <h1>My Banners</h1>
                    <p>View and manage all my created banners</p>
                </BannerText>
                <Button variant="secondary" onClick={goToCreate}>
                    Create a New Banner
                </Button>
            </Banner>
            <br />
            <MyAdsC />
        </PageLayout>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        adForm: state.adForm
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        setAdFolder: async (ancestors: string | null) => {
            const ad: Ad = {
                ...initialAdState,
                folderPath: ancestors
            } as Ad
            dispatch(makeAction(Actions.SET_AD_FORM, ad))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAds)