import styled from 'styled-components'

type LabelProps = {
    focus: boolean;
    value: string | number | undefined;
}

export const Label = styled.label<LabelProps>`
    position: absolute;
    pointer-events: none;
    left: 1rem;
    color:${({ focus, value, theme }) => {
        if (focus) return theme.colors.inputLabelFocus;
        else if (value) return theme.colors.inputLabelActive;
        else return theme.colors.inputLabelInactive;
    }};
    font: ${({ theme }) => theme.fonts.body1Medium};
    top: ${({ focus, value }) => focus || value ? '0.5rem' : '1.25rem'};
    font-size: ${({ focus, value }) => focus || value ? '0.875rem' : ''};
    transition: 0.2s ease all;
`;

export const ErrorTag = styled.p`
    color: ${({ theme }) => `${theme.colors.textError} !important`};
    align-self: flex-start;
    margin: 0.25rem 0 !important;
    text-align: left !important;
`;