import axios from "axios";

export const uploadImage = (url: string, file: File | null) => {
    return fetch(url, {
        method: "PUT",
        body: file
    })
}

export const getUploadUrl = (token: string, contenttype: string) => {
    const path = `/files/captureblob?contenttype=${encodeURIComponent(contenttype)}`
    return axios.get(path, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}