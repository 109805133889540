import MenuTile from "Components/MenuTile/menu-tile.component"
import PageLayout from "Components/PageLayout/page-layout.component"
import { Banner, BannerText } from "Styles/library/banner"
import { Menu } from "Styles/library/menu"
import { Links } from "Values/Constants"
import CreateNewAd from "Assets/images/menu/create-new-ad.svg"
import ManageMyAds from "Assets/images/menu/manage-my-ads.svg"
import SupportFAQs from "Assets/images/menu/support-and-faqs.svg"

const Home = () => {

    return (
        <PageLayout>
            <Banner>
                <BannerText>
                    <h1>Hello</h1>
                    <p>Get started on creating a new banner, or manage your existing ones</p>
                </BannerText>
            </Banner>
            <Menu>
                <MenuTile text="Create a New Banner" imageSrc={CreateNewAd} link={Links.adForm.basicDetails} />
                <MenuTile text="Manage My Banners" imageSrc={ManageMyAds} link={Links.myAds} />
                <MenuTile text="Support and FAQs" imageSrc={SupportFAQs} link={Links.support} />
            </Menu>
        </PageLayout>
    )
}

export default Home