import React, { useState } from "react"
import { Button } from "Styles/library/button"
import { ModalBox, ModalContent, ModalWrapper } from "Styles/library/modal"
import { DividedHeader } from "../Modal/modal.styles"
import FolderIcon from 'Assets/images/icons/folder-icon.svg'
import CloseIcon from 'Assets/images/icons/close-icon.svg'
import { ActionRow } from "./share-ad-modal.styles"
import { ErrorTag } from "Components/Forms/shared.styles"

type Props = {
    callback: () => void
    handleClose: () => void
    toggle?: boolean,
    sendToggle?: () => void
    h2: string
    h3: string
    p: string
    disabled?: boolean
}

const ShareAdModal = (props: Props) => {

    const { callback, handleClose, h2, h3, p, toggle, sendToggle, disabled = false } = props

    const handleOutsideClose = (e: React.MouseEvent<HTMLElement>) => {
        const id = e.currentTarget.id
        if (id === "modal-wrapper") {
            handleClose()
        }
    }

    const [copied, setCopied] = useState<boolean>(false)

    return (
        <ModalWrapper id="modal-wrapper" onClick={handleOutsideClose}>
            <ModalBox onClick={e => e.stopPropagation()} dropPadding={true}>
                <DividedHeader>
                    <div className="title-wrapper">
                        <img src={FolderIcon} alt="folder" />
                        <h2>{h2}</h2>
                    </div>
                    <button className="close-wrapper" onClick={handleClose}>
                        <img src={CloseIcon} alt="close" />
                        <h3>Close</h3>
                    </button>
                </DividedHeader>
                <ModalContent>
                    <ActionRow>
                        <div className="details">
                            <h3>{h3}</h3>
                            {!toggle && sendToggle && <ErrorTag style={{ background: "none" }}>* By default, the embedded banner will require a user to click in order to activate the 3D model</ErrorTag>}
                            <p>{p}</p>
                        </div>
                    </ActionRow>
                    {toggle !== undefined && sendToggle !== undefined && <>
                        <br />
                        <ActionRow>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={toggle}
                                    onChange={sendToggle}
                                    style={{
                                        marginRight: '1rem'
                                    }}
                                />
                                Add auto-activate functionality: note, this may have a performance impact on the webpage where it is embedded
                            </label>
                        </ActionRow>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {toggle && <ErrorTag>* Please use only 1 autoloading embed on any webpage to avoid significant impact to the performance of the webpage where it is embedded</ErrorTag>}

                        </div>

                    </>
                    }
                    <br />
                    <ActionRow>
                        <Button variant="primary" onClick={() => {
                            callback()
                            setCopied(true)
                        }}>
                            {copied ? "Copied!" : "Copy Link"}
                        </Button>
                    </ActionRow>
                </ModalContent>
            </ModalBox>
        </ModalWrapper>
    )
}

export default ShareAdModal