import { useEffect, useState } from "react"
import dropArrow from "Assets/images/icons/drop-arrow.png"
import "./Dropdown.css"
import useOutsideClick from "Utils/useOutsideClick"
import { capitalizeFirstLetter } from "Utils/strings"

type Props = {
    options: string[],
    value: string,
    sendValue: (val: string) => void,
}

const Dropdown = (props: Props) => {

    const { options, value, sendValue } = props
    const [showDropdown, setShowDropdown] = useState<boolean>(false)

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    const selectOption = (o: string) => {
        sendValue(o)
        setShowDropdown(false)
    }

    const { ref, isComponentVisible } = useOutsideClick();

    useEffect(() => {
        if (!isComponentVisible) {
            setShowDropdown(false)
        }
    }, [isComponentVisible])

    return (
        <div className="dropdown-wrapper" ref={ref}>
            <>
                <div className={showDropdown ? "selected-dropdown dropdown" : "dropdown"}>
                    <button onClick={toggleDropdown}>
                        <h4>{capitalizeFirstLetter(value)}</h4>
                        <img src={dropArrow} alt="open selection" className={showDropdown ? 'selected-arrow' : 'arrow'} />
                    </button>
                    {showDropdown &&
                        <div className="dropdown-list">
                            {options.map(o => (
                                <button key={`option-${o}`} onClick={() => selectOption(o)}>
                                    {capitalizeFirstLetter(o)}
                                </button>
                            ))}
                        </div>
                    }
                </div>
            </>
        </div>
    )
}


export default Dropdown