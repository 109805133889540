import { Ad, ArPlacement, CustomArButton, Model, RotateDirection } from "@declarations"
import { store } from "Store/store"
import { Actions, makeAction } from "./ActionTypes"

export const setAdBasicDetails = (title: string, destinationLink: string, clickMacro: string) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        const currentState = getState().adForm

        const data: Ad = {
            ...currentState,
            title: title,
            // idTag: idTag,
            destinationLink: destinationLink,
            clickMacro: clickMacro
        }

        dispatch(makeAction(Actions.SET_AD_FORM, data))
    }

export const setAdDimensions = (width: number, height: number) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        const currentState = getState().adForm

        const data: Ad = {
            ...currentState,
            width: width,
            height: height
        }

        dispatch(makeAction(Actions.SET_AD_FORM, data))
    }

export const setAdBackground = (background: string, transparent: boolean) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        const currentState = getState().adForm

        const data: Ad = {
            ...currentState,
            background: background,
            transparent: transparent
        }


        dispatch(makeAction(Actions.SET_AD_FORM, data))
    }

export const setStaticLoad = (staticLoad: string) =>
async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    const currentState = getState().adForm

    const data: Ad = {
        ...currentState,
        staticLoad: staticLoad
    }

    dispatch(makeAction(Actions.SET_AD_FORM, data))
}
export const setAdModel = (width: number, height: number, model: Model, modelX: number, modelY: number) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        const currentState = getState().adForm

        const data: Ad = {
            ...currentState,
            modelWidth: width,
            modelHeight: height,
            model: model,
            modelXCoordinate: modelX,
            modelYCoordinate: modelY
        }

        dispatch(makeAction(Actions.SET_AD_FORM, data))
    }

export const setAdAdjustments = (
    modelWidth: number,
    modelHeight: number,
    modelXCoordinate: number,
    modelYCoordinate: number,
    cameraDistance: number,
    cameraVerticalAngle: number,
    cameraHorizontalAngle: number,
    zooming: boolean,
    shadows: boolean,
    shadowIntensity: number,
    shadowEdgeSoftness: number,
    autoRotate: boolean,
    autoRotateSpeed: number,
    autoRotateDirection: RotateDirection,
    viewInArButton: boolean,
    viewInArButtonXCoordinate: number,
    viewInArButtonYCoordinate: number,
    customButton: CustomArButton,
    customButtonLabel: string,
    arPlacement: ArPlacement,
    customBanner: boolean,
    customBannerTitle: string,
    customBannerSubtitle: string,
    customBannerCtaLabel: string
) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        const currentState = getState().adForm

        const data: Ad = {
            ...currentState,
            modelWidth: modelWidth,
            modelHeight: modelHeight,
            modelXCoordinate: modelXCoordinate,
            modelYCoordinate: modelYCoordinate,
            cameraDistance: cameraDistance,
            cameraVerticalAngle: cameraVerticalAngle,
            cameraHorizontalAngle: cameraHorizontalAngle,
            zooming: zooming,
            shadows: shadows,
            shadowIntensity: shadowIntensity,
            shadowEdgeSoftness: shadowEdgeSoftness,
            autoRotate: autoRotate,
            autoRotateSpeed: autoRotateSpeed,
            autoRotateDirection: autoRotateDirection,
            viewInArButton: viewInArButton,
            viewInArButtonXCoordinate: viewInArButtonXCoordinate,
            viewInArButtonYCoordinate: viewInArButtonYCoordinate,
            customButton: customButton,
            customButtonLabel: customButtonLabel,
            arPlacement: arPlacement,
            customBanner: customBanner,
            customBannerTitle: customBannerTitle,
            customBannerSubtitle: customBannerSubtitle,
            customBannerCtaLabel: customBannerCtaLabel
        }

        dispatch(makeAction(Actions.SET_AD_FORM, data))

    }