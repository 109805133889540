import styled from "styled-components";

export const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    flex: 1;

    .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        h3 {
            margin-right: 0px !important;
        }
    }    
`

export const SliderTitle = styled.h5`
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 100% */

    letter-spacing: 0.3px;
    text-transform: uppercase;

    /* Text Colours/Headline 2 */

    color: #717171;
    margin-bottom: 12px;
`

export const InputsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    width: 100%;
`

export const Slider = styled.input`

    padding: 0 !important;
    margin: 12px 0;
    -webkit-appearance: none;
    margin-right: 15px;
    width: 100%;
    height: 3px;
    // border-top: 1px solid #848484;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background-image: linear-gradient(#0038A8, #0038A8);
    background-size: 50% 100%;
    background-repeat: no-repeat;
    
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #0038A8;
        border: 2px solid #0038A8;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
        margin-left: 1px;
    }

    &::-moz-range-thumb {
        -webkit-appearance: none;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: #0038A8;
        border: 2px solid #0038A8;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
    }

    &::-ms-thumb {
        -webkit-appearance: none;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: #0038A8;
        border: 2px solid #0038A8;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
    }

    &::-webkit-slider-thumb:hover {
        background: #002e8a;
    }

    &::-moz-range-thumb:hover {
        background: #002e8a;
    }

    &::-ms-thumb:hover {
        background: #002e8a;
    }

    &::-webkit-slider-thumb:focus {
        border: 4px solid red;
    }

    &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    &::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    &::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }
`

export const TextInput = styled.input`
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    /* Neutral Colours/Main */

    border: 1px solid #858993;
    box-sizing: border-box;
    border-radius: 6px;

    text-align: center;

    // hide arrows if number type
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    // /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
`