import styled from "styled-components";

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 200;
    min-width: 100vw;
    min-height: 101vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
`;


export const ModalBox = styled.div`
    background: ${({ theme }) => theme.colors.modalBackground};
    border-radius: ${({ theme }) => theme.shapes.borderRadiusPrimary};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);

    width: 100%;
    max-width: 43.75rem;

    max-height: 96vh;
    overflow-y: auto;

    padding: 64px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 1rem;
        text-align: center;

    }

    p {
        text-align: center;
        margin-bottom: 2rem;
        word-break: break-word;
    }
`

export const DividedHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #353E5A;

    width: 100%;
    padding: 36px;
    border-bottom: 1px solid grey;

    .title-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 32px;

        img {
            margin-right: 16px;
        }

        h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            margin: 0;
        }
    }

    .close-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
            margin-right: 6px;
        }

        h3 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            margin: 0;
        }
    }
`