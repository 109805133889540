
const adFormLinkBase = "/banner-form/"

const AdFormLinks = {
    basicDetails: adFormLinkBase + "basic-details",
    dimensions: adFormLinkBase + "dimensions",
    background: adFormLinkBase + "background",
    model: adFormLinkBase + "model",
    adjustments: adFormLinkBase + "adjustments",
    review: adFormLinkBase + "review"
}

export const Links = {
    home: "/",
    homeFromSuite: "/fromsuite",
    myAds: "/my-banners",
    ad: {
        definition: "/banner/:id",
        constructor: (id: string) => `/banner/${id}`,
    },
    adPreview: {
        definition: "/banner-preview/:id",
        constructor: (id: string) => `/banner-preview/${id}`,
    },
    adForm: AdFormLinks,
    support: "/support"
}

export const AdForm = {
    totalSteps: 5
}

const prod = process.env.REACT_APP_ENVIRONMENT === "production"
const stage = process.env.REACT_APP_ENVIRONMENT === "staging"
const dev = process.env.REACT_APP_ENVIRONMENT === "development"

export const URLs = {
    url: prod ? "https://swirl.nextechar.com" : stage ? "https://stage.swirl.nextechar.com" : "https://dev.swirl.nextechar.com",
    api: prod ? "https://api.swirl.nextechar.com" : stage ? "https://api.stage.swirl.nextechar.com" : dev ? "https://api.dev.swirl.nextechar.com" : "http://localhost:3001",
    s3Bucket: prod ? "https://assets.swirl.nextechar.com/" : stage ? "https://assets.stage.swirl.nextechar.com/" : "https://assets.dev.swirl.nextechar.com/",
    threedyAuth0Token: prod ? "https://aarwild-dev.auth0.com/oauth/token" : "https://threedy.auth0.com/oauth/token",
    threedyAuth0Audience: 'https://pipeline-api.aarwild.com',
    threedyApi: prod ? "https://portal.threedy.ai/api" : "https://portal-test.threedy.ai/api"
}

export const Auth = {
    domain: prod ? "auth.threedy.ai" : "threedy.auth0.com",
    clientId: prod ? "VexrIIfZTFxSjTPSkbG3SF5IO2ANZt1R" : "ZmTEFLuuTGQZ7y1f445RHMT3O0Cip4hC",
    apiClientId: prod ? "bgKLW2AKqI710RTfEYxj7T890SFzYmse" : "Oogl4viI9I912ALmoiF4xmcD9zpmK314"
}

export const xAPI = {
    endPoint: "https://nexttech.lrs.io/ads-prod/xapi/statements/aggregate",
    user: "ntaradsstandalone",
    pass: "2%n-mns?e+QT+PcA"
}

export const Charts = {
    chart_width_difference: 300
}

export const DefaultValues = {
    customButtonLabel: "View in AR",
    customBannerSubTitle: "View this in your space",
    customBannerCtaLabel: "Buy Now"
}