import { Analytics, ReduxAction } from "@declarations";
import { Actions } from "Store/Actions/ActionTypes";

const initialState: Analytics.Loading = {
    impressions: false,
    clicks: false,
    ctr: false,
    interactions: false,
    arExp: false,
    summaryData: false
}

export const analyticsLoading = (state: Analytics.Loading = initialState, { type, payload }: ReduxAction): Analytics.Loading => {
    switch (type) {
        case Actions.SET_ANALYTICS_LOADING:
            return payload as Analytics.Loading
        default:
            return state
    }
}