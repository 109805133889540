import styled from "styled-components";

export const Menu = styled.div`
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(300px, auto));
    padding: 108px;


    @media screen and (max-width: 1000px) {
        padding: 24px;
    }
`