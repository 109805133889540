import styled from "styled-components";

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 200;
    min-width: 100vw;
    min-height: 101vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
`;

type BoxProps = {
    dropPadding?: boolean
}

export const ModalBox = styled.div<BoxProps>`
    background: ${({ theme }) => theme.colors.modalBackground};
    border-radius: ${({ theme }) => theme.shapes.borderRadiusPrimary};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);

    // width: 100%;
    // max-width: 808px;

    max-height: 96vh;
    overflow-y: auto;

  
    padding: ${({ dropPadding }) => dropPadding ? "" : "32px"};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;


    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 1rem;

    }

    p {
        text-align: center;
        margin-bottom: 2rem;
    }
`

export const ModalContent = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`

export const ModalTitle = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 133% */

    text-align: center;

    /* Text Colours/ Headline */

    color: #030E31;

`

export const ModalInstructions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;

    h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        /* identical to box height, or 144% */


        /* Text Colours/ Headline */

        color: #030E31;
        margin: 24px 0 16px;
    }

    .row {
        display: flex;
        align-item: center;
        justify-content: flex-start;

        img {
            margin-right: 18px;
            margin-bottom: 18px;
        }

        &:last-child {
            margin-bottom: -16px;
        }
    
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height, or 137% */


        /* Text Colours/Bodycopy */

        color: #353E5A;
        margin-bottom: 18px;
    }
`
export const BottomActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 46px;
    width: 100%;
`