import styled from "styled-components";
import SearchIcon from 'Assets/images/icons/search-icon.svg'

export const SideFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: max-content;
`

export const SideFormRowTitle = styled.h2`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height, or 144% */


    /* Text Colours/Bodycopy */

    color: #353E5A;

    margin: 32px 0 0;
    align-self: flex-start;
`

export const SideFormRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0;
    
    width: 750px;
    
    @media screen and (max-width: 1300px) {
        width: 100%;
    }

    & > *:not(:last-child) {
        margin-right: 32px;
    }

    h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        /* identical to box height, or 144% */


        /* Text Colours/Bodycopy */

        color: #353E5A;

        margin-right: 20px;
        margin-bottom: 0;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        h3 {
            margin-right: 0px !important;
        }
    }

    h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        /* identical to box height, or 144% */


        /* Text Colours/Bodycopy */

        color: #353E5A;
    }

    animation: section-appear .5s forwards linear;

    @keyframes section-appear {
        0% {
            opacity: 0;
            max-height: 0;
        }
        100% {
            opacity: 1;
            max-height: 1000px;
        }
      }
      
`
export const ModelsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    max-width: 704px;
`

type SearchProps = {
    maxWidth?: number
}

export const SearchInput = styled.input<SearchProps>`
    background: url(${SearchIcon}) no-repeat 2%;
    padding: 12px 22px 12px 38px;
    background-color: #FFFFFF;
    border: 1px solid #858993;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    color: #353E5A;
    // margin-bottom: 32px;
    width: 100%;
    ${({ maxWidth }) => {
        if (maxWidth) {
            return `max-width: ${maxWidth}px;`
        }
    }}

    &:focus {
        border: 1.5px solid #0038A8;
    }
`

export const ResultsText = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 100% */

    letter-spacing: 0.3px;
    text-transform: uppercase;

    /* Text Colours/Headline 2 */

    color: #717171;
`

export const ModelListContainer = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(344px, auto));
`

type ModelListProps = {
    selected: boolean
}

export const ModelListItem = styled.button<ModelListProps>`
    padding: 16px 24px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    grid-gap: 16px;

    ${({ selected }) => {
        if (selected) {
            return `
            background: #F2F2F3;
            border: 1px solid #353E5A;
            `
        } else {
            return `
            background: #FFFFFF;
            border: 1px solid #DDDEE0;
        `
        }
    }}


    box-sizing: border-box;
    border-radius: 8px;

    &:hover {
        border: 1px solid #353E5A;
    }

    img {
        width: 80px;
        height: 80px;
    }

    .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #353E5A;
        // max-width: 200px;
        max-width: 200px;

        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            /* identical to box height, or 137% */

            text-align: center;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        p{
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height, or 129% */

            text-align: center;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
`

export const SectionTitleWrapper = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 2rem;

    h4 {
        margin-bottom: 0 !important;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    animation: section-appear .5s forwards linear;

    @keyframes section-appear {
        0% {
            opacity: 0;
            max-height: 0;
        }
        100% {
            opacity: 1;
            max-height: 1000px;
        }
      }
`