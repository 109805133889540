import styled from "styled-components";

export const Banner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 106px;
    color: white;
    flex-wrap: wrap;


    @media screen and (max-width: 1000px) {
        padding: 24px 24px;
    }

    background: ${({ theme }) => theme.colors.bannerBackground};

`

export const BannerText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    * {
        color: ${({ theme }) => theme.colors.bannerTextColor};
    }

    a {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
    }

    h1 {
      font: ${({ theme }) => theme.fonts.bannerH1};
      margin-bottom: 12px;
    }

    p {
        font: ${({ theme }) => theme.fonts.bannerP};
        margin-bottom: 0;
    }
`

export const BannerBackWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 21px;

    img {
        height: 16px;
        width: auto;
        margin-right: 16px;
    }

    h6 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        margin: 0;
    }
`