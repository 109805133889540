import React, { useState } from "react"
import { Button } from "Styles/library/button"
import { ModalBox, ModalContent, ModalWrapper } from "Styles/library/modal"
import { DividedHeader } from "../Modal/modal.styles"
import FolderIcon from 'Assets/images/icons/folder-icon.svg'
import CloseIcon from 'Assets/images/icons/close-icon.svg'
import { ActionRow } from "./create-folder-modal.styles"
import { FormValidator, maxLength, required } from "Components/Forms/form-validation"
import { Form } from "@declarations"
import ValidatedUniqueInput from "Components/Forms/UniqueInput/unique-input.component"

type Props = {
    callback: (folderName: string) => void
    handleClose: () => void
    h2: string
    p: string
    existingFolders: string[]
    placeholderValue?: string
}

const CreateFolderModal = (props: Props) => {

    const { callback, handleClose, h2, existingFolders, placeholderValue } = props

    const handleOutsideClose = (e: React.MouseEvent<HTMLElement>) => {
        const id = e.currentTarget.id
        if (id === "modal-wrapper") {
            handleClose()
        }
    }

    const [data, setData] = useState<Form.Data>({
        folderName: placeholderValue ? placeholderValue : ""
    })

    // Form helpers
    const handleValueChange = (name: Form.Field, value: string | number | undefined) => {
        setData(data => ({
            ...data,
            [name]: (value as string).trim()
        }))
    }

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (disabled || !data.folderName) return;
        if (e.key === "Enter") callback(data.folderName as string)
    }

    const [disabled, setDisabled] = useState<boolean>(true);

    const handleValidChange = FormValidator(setDisabled);
    // end of form helpers


    return (
        <ModalWrapper id="modal-wrapper" onClick={handleOutsideClose}>
            <ModalBox onClick={e => e.stopPropagation()} dropPadding={true}>
                <DividedHeader>
                    <div className="title-wrapper">
                        <img src={FolderIcon} alt="folder" />
                        <h2>{h2}</h2>
                    </div>
                    <button className="close-wrapper" onClick={handleClose}>
                        <img src={CloseIcon} alt="close" />
                        <h3>Close</h3>
                    </button>
                </DividedHeader>
                <ModalContent>

                    <ActionRow>
                        <ValidatedUniqueInput
                            id="folderName"
                            name="folderName"
                            placeholder="Folder Name"
                            value={data.folderName}
                            validations={[required("Folder Name"), maxLength(255)]}
                            onValueChange={handleValueChange}
                            onValidChange={handleValidChange}
                            onKeyUp={onKeyUp}
                            type="text"
                            existingValues={existingFolders}
                        />
                    </ActionRow>
                    <ActionRow style={{ justifyContent: 'flex-end' }}>
                        <Button variant="primary" disabled={disabled} onClick={() => {
                            if (data.folderName) callback((data.folderName as string).trim())
                        }}>
                            {h2}
                        </Button>
                    </ActionRow>
                </ModalContent>
            </ModalBox>
        </ModalWrapper>
    )
}

export default CreateFolderModal