import { Analytics } from "@declarations"

const Verbs: Record<Analytics.VerbKey, string> = {
    started: "http://activitystrea.ms/schema/1.0/start",
    loaded: "https://webar.nextechar.com/xAPI/verbs/loaded",
    interacted: "http://activitystrea.ms/schema/1.0/interact",
    opened: "http://activitystrea.ms/schema/1.0/open",
    clicked: "https://webar.nextechar.com/xAPI/verbs/clicked"
}

const Extensions = {
    durationSeconds: "https://ads*`*nextechar*`*com/extension/durationSeconds",
    adLocation: "https://arecomm*`*nextechar*`*com/extension/adlocation"
}

export const xApiKeys = {
    Verbs,
    Extensions
}

export const xApiStatementKeys = {
    verbId: "statement.verb.id",
    objectId: "statement.object.id",
    timestamp: "statement.timestamp",
    adIdBase: "https://ads.nextechar.com/"
}