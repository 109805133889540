import BillboardIcon from 'Assets/images/icons/billboard-icon.svg'
import HalfPageIcon from 'Assets/images/icons/half-page-icon.svg'
import LargeLeaderboardIcon from 'Assets/images/icons/large-leaderboard-icon.svg'
import LeaderboardIcon from 'Assets/images/icons/leaderboard-icon.svg'
import MediumBannerIcon from 'Assets/images/icons/medium-banner-icon.svg'
import SkyscraperIcon from 'Assets/images/icons/skyscraper-icon.svg'

export const dimensionPresets = [
    {
        img: MediumBannerIcon,
        title: "Med. Banner",
        width: 300,
        height: 250
    },
    {
        img: LeaderboardIcon,
        title: "Leaderboard",
        width: 728,
        height: 90
    },
    {
        img: LargeLeaderboardIcon,
        title: "Lar. Leaderboard",
        width: 970,
        height: 90
    },
    {
        img: SkyscraperIcon,
        title: "Skyscraper",
        width: 160,
        height: 600
    },
    {
        img: HalfPageIcon,
        title: "Half Page",
        width: 300,
        height: 600
    },
    {
        img: BillboardIcon,
        title: "Billboard",
        width: 970,
        height: 250
    },
]