import styled from "styled-components"

type BoxProps = {
    selected: boolean
}

export const Box = styled.button<BoxProps>`
    background: #FFFFFF;
    /* Neutral Colours/Lighter */

    border: ${({ selected }) => selected ? "2px solid #353E5A" : "1px solid #DDDEE0"}; 
    box-sizing: border-box;
    /* Tiny */

    box-shadow: 0px 3px 12px rgba(185, 185, 185, 0.2);
    border-radius: 8px;

    padding: 21px 24px;

    min-height: 90px;

    display: grid;
    grid-gap: 2px;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "icon text"
      "icon value";

    img {
        grid-area: icon;
        width: 48px;
        height: auto;
        margin-right: 14px;
    }

    h3,p {
        margin: 0;
        text-align: left;
    }

    h3 {
        grid-area: value;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
    }
    
    p {
        grid-area: text;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 130%;
    }
`