export const getDefaultModelDimensions = (adWidth: number, adHeight: number): number => {
    return Math.min(adWidth, adHeight) / 2
}

export const centerModelCoordinates = (adWidth: number, adHeight: number): { x: number, y: number } => {

    const result = {
        x: 0,
        y: 0
    }

    const dimension = getDefaultModelDimensions(adWidth, adHeight)

    result.x = (adWidth / 2) - (dimension / 2)
    result.y = (adHeight / 2) - (dimension / 2)

    return result
}

// relative to ad square
export const centerButtonCoordinates = (adWidth: number, adHeight: number): { x: number, y: number } => {

    const result = {
        x: 0,
        y: 0
    }

    const dimension = getDefaultModelDimensions(adWidth, adHeight)

    result.x = (adWidth / 2) - (dimension / 2.5)
    result.y = (adHeight / 2) - (dimension / 8)

    return result
}

// relative to model
export const centerButtonCoordinatesInModel = (modelWidth: number, modelHeight: number): { x: number, y: number } => {

    const result = {
        x: 0,
        y: 0
    }

    // const dimension = getDefaultModelDimensions(modelWidth, modelHeight)

    result.x = (modelWidth / 2) - (modelWidth / 2.5)//- (dimension / 2.5)
    result.y = (modelHeight / 2) - (modelHeight / 6) //- (dimension / 8)

    return result
}