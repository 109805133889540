import { Button } from "Styles/library/button"
import backArrow from "Assets/images/icons/backArrow.png"
import forwardArrow from "Assets/images/icons/forwardArrow.png"


type Props = {
    text: string
    callback: () => void
    variant: "primary" | "secondary" | "alternate" | "back"
    disabled?: boolean
}
const StepButton = (props: Props) => {

    const { text, callback, variant, disabled } = props

    return (
        <Button variant={variant} onClick={callback} disabled={disabled}>
            {variant == "back" ? <img src={backArrow} alt="" style={{ marginRight: 8 }}/> : null}
            {text}
            {variant == "primary" && text != 'Update' ? <img src={forwardArrow} alt="" style={{ marginLeft: 8 }}/> : null}
        </Button >
    )
}

export default StepButton