import { TabButton, TabRow } from "./tabs.styles"

type Props = {
    options: string[]
    select: (option: string) => void
    selected: string
}

const Tabs = (props: Props) => {

    const { options, select, selected } = props

    return (
        <TabRow>
            {options.map(o => (
                <TabButton
                    key={o}
                    onClick={() => select(o)}
                    selected={selected === o}
                >
                    {o}
                </TabButton>
            ))}
        </TabRow>
    )
}

export default Tabs