import { PreviewRow, ReviewRow } from "Components/AdForm/Shared/review-form.styles"
import { ContentBody } from "Components/PageLayout/page-layout.styles"
import { Details, PreviewImage } from "./ad-details.styles"
import { AdSchema, PageProps, AppState, Ad, Analytics } from '@declarations'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { DefaultValues, Links } from "Values/Constants"
import { Button } from "Styles/library/button"
import { Actions, makeAction } from "Store/Actions/ActionTypes"
import { store } from "Store/store"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    ad?: AdSchema,
    impressions: Analytics.Impressions, 
}

const AdDetails = (props: Props) => {

    const { ad, setAdForm, impressions } = props

    const attributes: Record<string, any> = {}

    if (ad?.camDisableZoom) {
        attributes["disable-zoom"] = true
    }
    if (ad?.autoRotate) {
        attributes["auto-rotate"] = true
    }
    if (ad?.autoRotateSpeed && ad?.autoRotateDirection) {
        if (ad?.autoRotateDirection === "left") {
            attributes["rotation-per-second"] = `-${ad?.autoRotateSpeed}deg`
        } else {
            attributes["rotation-per-second"] = `${ad?.autoRotateSpeed}deg`
        }
    }
    if (ad?.shadowIntensity) {
        attributes["shadow-intensity"] = ad?.shadowIntensity
    }
    if (ad?.shadowSoftness) {
        attributes["shadow-softnesss"] = ad?.shadowSoftness
    }
    attributes["camera-orbit"] = `${ad?.paramInitialAngle}deg ${ad?.camHeightAngle}deg ${ad?.camInitialDist}%`

    const history = useHistory()

    const goToEdit = async (destination: keyof typeof Links.adForm) => {
        if (!ad) return
        await setAdForm(ad)
        history.push(Links.adForm[destination])
    }

    return (
        <ContentBody>
            <Details>
                <header>
                    <h3>Basic Details</h3>
                    {impressions.total? <h3>Settings Disabled on Live Banner</h3> : <Button variant="alternate" onClick={() => goToEdit("basicDetails")}>Edit</Button>}
                </header>
                <div >
                    <ReviewRow>
                        <h3>Banner Title</h3>
                        <h4>{ad?.adName}</h4>
                        <div></div>
                    </ReviewRow>
                    <ReviewRow>
                        <h3>Click URL</h3>
                        <h4>{ad?.activeURL}</h4>
                        <div></div>
                    </ReviewRow>
                    {/* <ReviewRow>
                        <h3>Click Macro</h3>
                        <h4>{ad?.clickMacro}</h4>
                        <div></div>
                    </ReviewRow> */}
                </div>
            </Details>
            <Details>
                <header>
                    <h3>Dimensions</h3>
                    {impressions.total ? <h3>Settings Disabled on Live Banner</h3> : <Button variant="alternate" onClick={() => goToEdit("dimensions")}>Edit</Button>}
                </header>
                <div>
                    <ReviewRow>
                        <h3>Banner Size</h3>
                        <h4>{`${ad?.adWidth}x${ad?.adHeight}`}</h4>
                        <div></div>
                    </ReviewRow>
                </div>
            </Details>
            <Details>
                <header>
                    <h3>Background</h3>
                    <Button variant="alternate" onClick={() => goToEdit("background")}>Edit</Button>
                </header>
                <div>
                    <ReviewRow>
                        <h3>Background</h3>
                        <h4>{ad?.creativeBg}</h4>
                        <div></div>
                    </ReviewRow>
                    
                    <PreviewRow >
                        {(ad?.transparent || ad?.creativeBg) ? <PreviewImage width={ad?.adWidth} height={ad?.adHeight} src={ad?.transparent ? 'https://assets.swirl.nextechar.com/templates/transparentBackground.png' : ad.creativeBg} alt="preview" /> : null}
                    </PreviewRow>
                </div>
            </Details>
            <Details>
                <header>
                    <h3>3D Model</h3>
                    <Button variant="alternate" onClick={() => goToEdit("model")}>Edit</Button>
                </header>
                <div>
                    <ReviewRow>
                        <h3>Model Name</h3>
                        <h4>{ad?.threedyModelName}</h4>
                        <div></div>
                    </ReviewRow>
                    <ReviewRow>
                        <h3>Model SKU</h3>
                        <h4>{ad?.threedyModelSKU}</h4>
                        <div></div>
                    </ReviewRow>
                    <ReviewRow>
                        <h3>Model Settings</h3>
                        <h4><Button onClick={() => goToEdit("adjustments")} variant="secondary">Edit Settings</Button> </h4>
                        <div></div>
                    </ReviewRow>
                    <PreviewRow>
                        {ad && ad.glbLoc &&
                            <model-viewer
                                src={ad.glbLoc}
                                style={{
                                    width: `${ad.expwidth}px`,
                                    height: `${ad.expheight}px`,
                                    // left: `${ad.expleft}px`,
                                    // top: `${ad.exptop}px`,
                                }}
                                poster={""}
                                {...attributes}
                                camera-controls
                                loading="eager"
                                alt="3d ad?"
                            />
                        }
                    </PreviewRow>
                </div>
            </Details>
        </ContentBody>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        adForm: state.adForm
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        setAdForm: async (schema: AdSchema) => {
            console.log("SCHEMA", schema)
            const ad: Ad = {
                updating: true,
                _id: schema._id,
                title: schema.adName,
                // idTag: "",
                destinationLink: schema.activeURL || "",
                clickMacro: schema.clickMacro,
                width: schema.adWidth,
                height: schema.adHeight,
                background: schema.creativeBg,
                transparent: schema.transparent,
                model: {
                    models: {
                        usdz: {
                            url: schema.usdzLoc
                        },
                        glb: {
                            url: schema.glbLoc
                        }
                    },
                    job_id: schema._id,
                    title: schema.threedyModelName,
                    sku: schema.threedyModelSKU,
                    client_category_id: schema.consumerClientId
                },
                modelWidth: schema.expwidth,
                modelHeight: schema.expheight,
                modelXCoordinate: schema.expleft,
                modelYCoordinate: schema.exptop,
                cameraDistance: schema.camInitialDist,
                cameraVerticalAngle: schema.camHeightAngle,
                cameraHorizontalAngle: schema.paramInitialAngle,
                zooming: !schema.camDisableZoom,
                shadows: schema.paramShadows || false,
                shadowIntensity: schema.shadowIntensity || 1,
                shadowEdgeSoftness: schema.shadowSoftness || 1,
                autoRotate: schema.autoRotate,
                autoRotateSpeed: schema.autoRotateSpeed || 5,
                autoRotateDirection: schema.autoRotateDirection as "left" | "right",
                viewInArButton: schema.arEnabled,
                viewInArButtonXCoordinate: schema.arZoneLeft || 0,
                viewInArButtonYCoordinate: schema.arZoneTop || 0,
                customButton: schema.arCustomButton ? "text" : "image",
                customButtonLabel: schema.arButtonLabel,
                arPlacement: schema.arPlacement === "floor" ? "On the floor" : "On the wall",
                bannersEnabled: schema.arBannersEnabled,
                customBanner: schema.arCustomBanner,
                customBannerTitle: schema.objTitle,
                customBannerSubtitle: schema.objSubTitle || DefaultValues.customBannerSubTitle,
                // customBannerPrice: schema.objPrice ? Number(schema.objPrice) : 0,
                customBannerCtaLabel: schema.ctaMessage || DefaultValues.customBannerCtaLabel,
                folderPath: schema.folderPath,
                staticLoad: schema.staticLoad
            }

            dispatch(makeAction(Actions.SET_AD_FORM, ad))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdDetails)