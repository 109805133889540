import styled from "styled-components";

export const ToggleContainer = styled.div`
    dispLay: flex;
    align-items: center;
    justify-content: center;
`

type ToggleBoxProps = {
    on: boolean
}

export const ToggleTitle=styled.p<ToggleBoxProps>`
    color: ${({ on }) => on ? '#0038A8' : '#858993'}; 
    margin: 0 1rem 0 0;

    transition: all 0.3s ease;
`

export const ToggleBox = styled.button<ToggleBoxProps>`

    display: flex;
    align-items: center;
    justify-content: ${({ on }) => on ? 'flex-end' : 'flex-start'};
    background: ${({ on }) => on ? '#0038A8' : '#858993'}; 
    border-radius: 20px;
    padding: 0 2.5px;
    width: 40px;
    height: 20px;
    transition: all 0.3s ease;

`

export const ToggleTab = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    height: 15px;
    width: 15px;
`