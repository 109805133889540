import styled from "styled-components";

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
    @media screen and (max-width: 336px) {
        margin-top: 40px;
    }
`
export const SiteMap = styled.div`
    background: #030E31;
    padding: 60px 106px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 600px) {
        flex-wrap: wrap-reverse;
        align-items: flex-end;
        padding: 24px 24px;
    }
`
export const SiteLinks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #FFFFFF;
    }

    a {
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: #FFFFFF;
    }

    h2, a {
        margin-bottom: 24px;
    }
`

export const SiteBrand = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    @media screen and (max-width: 1000px) {
        & > img {
            width: 200px;
        }
    }
`

export const Footer = styled.footer`
    height: 106px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px 106px;
    flex-wrap: wrap;

    @media screen and (max-width: 1000px) {
        padding: 24px 24px;
    }
`
export const TermsSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    a, span {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        /* identical to box height */
        
        display: flex;
        align-items: center;
        text-align: center;
        
        /* Primary Colour/Main */
        
        color: #353E5A;
    }

    a {
        margin: 0 8px;
    }

    p {
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;

        /* Primary Colour/Main */

        color: #353E5A;
        margin: 0;
    }
`

export const SocialMediaSection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    a {
        margin: 0 15px;
    }
`

