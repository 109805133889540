import styled from "styled-components"

export const SectionTitle = styled.div`

    width: 100%;
    // max-width: 64.5rem;
    // padding: 0 3rem;
    padding: 0 106px;
    display: flex;
    align-items: center;

    * {
       display: flex;
       align-items: center;
    }
    

    @media screen and (max-width: 1000px) {
      padding: 0 24px;
   }

     h1, button, a {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        /* identical to box height, or 133% */
        
        
        /* Text Colours/ Headline */
        
        color: #030E31;
        margin: 0;
        padding: 0;
        margin-right: 8px;
     }
     button, a {
      color: #0038A8;
     }
     img {
        height: 100%;
        width: auto;
        margin-right: 8px;
     }
`