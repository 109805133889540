import { URLs } from "Values/Constants";


export const templateCreativeBgs: Record<string, any[]> = {
    "300x250": [
        {
            title: "back yard grass 01",
            folderUrl: `${URLs.s3Bucket}templates/back+yard+grass+01.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/back+yard+grass+01.png`
        },
        {
            title: "back yard grass 02",
            folderUrl: `${URLs.s3Bucket}templates/back+yard+grass+02.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/back+yard+grass+02.png`
        },
        {
            title: "back yard grass 03",
            folderUrl: `${URLs.s3Bucket}templates/back+yard+grass+03.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/back+yard+grass+03.png`
        },
        {
            title: "back yard patio 01",
            folderUrl: `${URLs.s3Bucket}templates/back+yard+patio+01.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/back+yard+patio+01.png`
        },
        {
            title: "back yard patio 02",
            folderUrl: `${URLs.s3Bucket}templates/back+yard+patio+02.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/back+yard+patio+02.png`
        },
        {
            title: "color background 01",
            folderUrl: `${URLs.s3Bucket}templates/color+background+01.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/color+background+01.png`
        },
        {
            title: "color background 02",
            folderUrl: `${URLs.s3Bucket}templates/color+background+02.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/color+background+02.png`
        },
        {
            title: "dining table 01",
            folderUrl: `${URLs.s3Bucket}templates/dining+table+01.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/dining+table+01.png`
        },
        {
            title: "dining table 02",
            folderUrl: `${URLs.s3Bucket}templates/dining+table+02.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/dining+table+02.png`
        },
        {
            title: "dining table 02",
            folderUrl: `${URLs.s3Bucket}templates/dining+table+02.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/dining+table+02.png`
        },
        {
            title: "dining table 03",
            folderUrl: `${URLs.s3Bucket}templates/dining+table+03.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/dining+table+03.png`
        },
        {
            title: "dining table 04",
            folderUrl: `${URLs.s3Bucket}templates/dining+table+04.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/dining+table+04.png`
        },
        {
            title: "living room 01",
            folderUrl: `${URLs.s3Bucket}templates/living+room+01.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/living+room+01.png`
        },
        {
            title: "living room 02",
            folderUrl: `${URLs.s3Bucket}templates/living+room+02.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/living+room+02.png`
        },
        {
            title: "living room 03",
            folderUrl: `${URLs.s3Bucket}templates/living+room+03.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/living+room+03.png`
        },
        {
            title: "living room 04",
            folderUrl: `${URLs.s3Bucket}templates/living+room+04.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/living+room+04.png`
        },
        {
            title: "living room 05",
            folderUrl: `${URLs.s3Bucket}templates/living+room+05.zip`,
            bgImageUrl: `${URLs.s3Bucket}templates/living+room+05.png`
        },
    ]
}