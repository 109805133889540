import { combineReducers } from 'redux'

import { adForm } from './ad-form'
import { analyticsDates } from './analytics-dates';
import { analytics } from './analytics';
import { analyticsLoading } from './analytics-loading';

const rootReducer = combineReducers({
    adForm,
    analyticsDates,
    analytics,
    analyticsLoading
});

export default rootReducer
