import { Analytics, DateRange } from "@declarations"
import { VeracityDate } from "Utils/xApiQueries/dates"
import { xApiKeys, xApiStatementKeys } from "Values/xApiKeys"

export const getVerbCounts = (id: string, dates: DateRange) => {
    const verbs: string[] = [];

    (Object.keys(xApiKeys.Verbs) as Array<Analytics.VerbKey>).forEach((key: Analytics.VerbKey) => {
        verbs.push(xApiKeys.Verbs[key])
    })

    const request = [
        {
            "$match": {
                "statement.object.id": {
                    "$in": [xApiStatementKeys.adIdBase + id]
                },
                "statement.verb.id": {
                    "$in": [...verbs]
                },
                "statement.timestamp": {
                    "$gte": VeracityDate(new Date(dates.start)),
                    "$lt": VeracityDate(new Date(dates.end))
                }
            }
        },
        {
            "$addFields": {
                "EXT": "$statement.object.definition"
            }
        },
        {
            "$addFields": {
                "EXT": "$EXT.extensions"
            }
        },
        {
            "$group": {
                "_id": {
                    "ad": "$statement.object.id",
                    "verb": "$statement.verb.id"
                },
                "count": {
                    "$sum": 1
                }
            }
        },
        {
            "$facet": {
                "Start": [
                    {
                        "$match": {
                            "_id.verb": xApiKeys.Verbs.started,
                        }
                    },
                    {
                        "$group": {
                            "_id": "$_id.verb",
                            "count": {
                                "$sum": "$count"
                            },
                        }
                    }
                ],
                "Loaded": [
                    {
                        "$match": {
                            "_id.verb": xApiKeys.Verbs.loaded,
                        }
                    },
                    {
                        "$group": {
                            "_id": "$_id.verb",
                            "count": {
                                "$sum": "$count"
                            },
                        }
                    }
                ],
                "Interacted": [
                    {
                        "$match": {
                            "_id.verb": xApiKeys.Verbs.interacted,
                        }
                    },
                    {
                        "$group": {
                            "_id": "$_id.verb",
                            "count": {
                                "$sum": "$count"
                            },
                        }
                    }
                ],
                "Opened": [
                    {
                        "$match": {
                            "_id.verb": xApiKeys.Verbs.opened,
                        }
                    },
                    {
                        "$group": {
                            "_id": "$_id.verb",
                            "count": {
                                "$sum": "$count"
                            },
                        }
                    }
                ],
                "Clicked": [
                    {
                        "$match": {
                            "_id.verb": xApiKeys.Verbs.clicked,
                        }
                    },
                    {
                        "$group": {
                            "_id": "$_id.verb",
                            "count": {
                                "$sum": "$count"
                            },
                        }
                    }
                ],
            }
        }
    ]

    return request
}


// get Impressions separately, seems that the number is far under the real value when lumped into the allVerbs lookup. May be a query limit on the Mongo DB
export const getImpressionsCount = (id: string, dates: DateRange) => {

    const request = [
        {
            "$match": {
                "statement.verb.id": {
                    "$in": [xApiKeys.Verbs.started]
                },
                "statement.object.id": {
                    "$in": [xApiStatementKeys.adIdBase + id]
                },
                "statement.timestamp": {
                    "$gte": VeracityDate(new Date(dates.start)),
                    "$lt": VeracityDate(new Date(dates.end))
                }
            }
        },
        {
            "$count": "total_impressions"
        }
    ]

    return request

}

export const getInteractionTime = (id: string, dates: DateRange) => {

    const request = [
        {
            "$match": {
                "statement.verb.id": {
                    "$in": [xApiKeys.Verbs.interacted]
                },
                "statement.object.id": {
                    "$in": [xApiStatementKeys.adIdBase + id]
                },
                "statement.timestamp": {
                    "$gte": VeracityDate(new Date(dates.start)),
                    "$lt": VeracityDate(new Date(dates.end))
                }
            }
        },
        {
            "$addFields": {
                "EXT": "$statement.object.definition"
            }
        },
        {
            "$addFields": {
                "EXT": "$EXT.extensions"
            }
        },
        {
            "$group": {
                "_id": {
                    "ad": "$statement.object.id",
                    "verb": "$statement.verb.id"
                },
                "count": {
                    "$sum": 1
                },
                "durationTotal": {
                    "$sum": `$EXT.${xApiKeys.Extensions.durationSeconds}`
                },
                "duration": {
                    "$push": {
                        "seconds": `$EXT.${xApiKeys.Extensions.durationSeconds}`
                    }
                }
            }
        },
        {
            "$project": {
                "_id": 0,
                "ad": "$_id.ad",
                "verb": "$_id.verb",
                "count": "$count",
                "totalDuration": "$durationTotal",
                "longestDuration": {
                    "$max": "$duration"
                }
            }
        }
    ]

    return request
}

export const getARClicks = (id: string, dates: DateRange) => {

    const request = [
        {
            "$match": {
                "statement.verb.id": {
                    "$in": [xApiKeys.Verbs.clicked]
                },
                "statement.object.id": {
                    "$in": [xApiStatementKeys.adIdBase + id]
                },
                "statement.timestamp": {
                    "$gte": VeracityDate(new Date(dates.start)),
                    "$lt": VeracityDate(new Date(dates.end))
                }
            }
        },
        {
            "$addFields": {
                "EXT": "$statement.object.definition"
            }
        },
        {
            "$addFields": {
                "EXT": "$EXT.extensions"
            }
        },
        {
            "$group": {
                "_id": {
                    "ad": "$statement.object.id",
                    "verb": "$statement.verb.id",
                    "adlocation": `$EXT.${xApiKeys.Extensions.adLocation}`
                },
                "count": {
                    "$sum": 1
                },
            }
        },
        {
            "$match": {
                "_id.adlocation": {
                    "$in": ["In-AR"]
                }
            }
        },
        {
            "$project": {
                "_id": 0,
                "ad": "$_id.ad",
                "verb": "$_id.verb",
                "location": "$_id.adlocation",
                "count": "$count",
            }
        }
    ]

    return request
}

export const getTimelineData = (verb: Analytics.VerbKey, id: string, dates: DateRange) => {

    const request = [
        {
            "$match": {
                "statement.verb.id": {
                    "$in": [
                        xApiKeys.Verbs[verb]
                    ]
                },
                "statement.object.id": {
                    "$in": [
                        xApiStatementKeys.adIdBase + id
                    ]
                },
                "statement.timestamp": {
                    "$gte": VeracityDate(new Date(dates.start)),
                    "$lt": VeracityDate(new Date(dates.end))
                }
            }
        },
        {
            "$addFields": {
                "EXT": "$statement.object.definition"
            }
        },
        {
            "$addFields": {
                "EXT": "$EXT.extensions"
            }
        },
        {
            "$group": {
                "_id": {
                    "$dateToString": {
                        "format": "%Y-%m-%dT00:00:00.000Z",
                        "date": "$statement.timestamp"
                    },
                },
                "count": {
                    "$sum": 1
                },
            }
        },
        {
            "$addFields": {
                "isoTime": {
                    "$toDate": "$_id"
                }
            }
        },
        {
            "$sort": {
                "isoTime": 1
            }
        },
        {
            "$bucketAuto": {
                "groupBy": "$_id",
                "buckets": 30,
                "output": {
                    "count": {
                        "$sum": "$count"
                    },
                }
            }
        },
        {
            "$addFields": {
                "_id": "$_id.min"
            }
        },
        {
            "$project": {
                "_id": 0,
                "instance": "$_id",
                "count": "$count",
            }
        }
    ]

    return request
}

export const getTimelineDataCTR = (id: string, dates: DateRange) => {

    const request = [
        {
            "$match": {
                "statement.verb.id": {
                    "$in": [
                        // xApiKeys.Verbs.loaded,
                        // xApiKeys.Verbs.clicked,
                        "https://webar.nextechar.com/xAPI/verbs/loaded",
                        "https://webar.nextechar.com/xAPI/verbs/clicked"
                    ]
                },
                "statement.object.id": {
                    "$in": [
                        xApiStatementKeys.adIdBase + id
                    ]
                },
                "statement.timestamp": {
                    "$gte": VeracityDate(new Date(dates.start)),
                    "$lt": VeracityDate(new Date(dates.end))
                }
            }
        },
        {
            "$addFields": {
                "EXT": "$statement.object.definition"
            }
        },
        {
            "$addFields": {
                "EXT": "$EXT.extensions"
            }
        },
        {
            "$group": {
                "_id": {
                    "date": {
                        "$dateToString": {
                            "format": "%Y-%m-%dT00:00:00.000Z",
                            "date": "$statement.timestamp"
                        }
                    },
                    "verb": "$statement.verb.id"
                },
                "count": {
                    "$sum": 1
                },
            }
        },
        {
            "$group": {
                "_id": "$_id.date",
                "sum": {
                    "$sum": "$count"
                },
                "counts": {
                    "$push": {
                        "verb": "$_id.verb",
                        "count": "$count"
                    }
                }
            }
        },
        {
            "$unwind": {
                "path": "$counts",
            }
        },
        {
            "$project": {
                "_id": "$_id",
                "counts": "$counts.count",
                "verb": "$counts.verb",
                "sum": "$sum",
                "percent": {
                    "$multiply": [
                        {
                            "$cond": [
                                {
                                    "$eq": [
                                        {
                                            "$subtract": [
                                                "$sum",
                                                "$counts.count"
                                            ]
                                        },
                                        0
                                    ]
                                },
                                0,
                                {
                                    "$divide": [
                                        "$counts.count",
                                        {
                                            "$subtract": [
                                                "$sum",
                                                "$counts.count"
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        100
                    ]
                }
            }
        },
        {
            "$match": {
                "verb": {
                    "$in": [
                        "https://webar.nextechar.com/xAPI/verbs/clicked"
                    ]
                }
            }
        },
        {
            "$project": {
                "_id": 0,
                "instance": "$_id",
                "count": {
                    "$round": [
                        "$percent",
                        2
                    ],
                }
            }
        },
        {
            "$sort": {
                "instance": 1
            }
        },
    ]

    return request

}

export const getTimelineDataArExps = (id: string, dates: DateRange) => {

    const request = [
        {
            "$match": {
                "statement.verb.id": {
                    "$in": [
                        "http://activitystrea.ms/schema/1.0/open"
                    ]
                },
                "statement.object.id": {
                    "$in": [
                        xApiStatementKeys.adIdBase + id
                    ]
                },
                "statement.timestamp": {
                    "$gte": VeracityDate(new Date(dates.start)),
                    "$lt": VeracityDate(new Date(dates.end))
                },
            }
        },
        {
            "$addFields": {
                "EXT": "$statement.object.definition"
            }
        },
        {
            "$addFields": {
                "EXT": "$EXT.extensions"
            }
        },
        {
            "$group": {
                "_id": {
                    "$dateToString": {
                        "format": "%Y-%m-%dT00:00:00.000Z",
                        "date": "$statement.timestamp"
                    },
                },
                "count": {
                    "$sum": 1
                },
            }
        },
        {
            "$addFields": {
                "isoTime": {
                    "$toDate": "$_id"
                }
            }
        },
        {
            "$sort": {
                "isoTime": 1
            }
        },
        {
            "$bucketAuto": {
                "groupBy": "$_id",
                "buckets": 30,
                "output": {
                    "count": {
                        "$sum": "$count"
                    },
                }
            }
        },
        {
            "$addFields": {
                "_id": "$_id.min"
            }
        },
        {
            "$project": {
                "_id": 0,
                "instance": "$_id",
                "count": "$count",
            }
        }
    ]

    return request
}

export const getTimelineDataArClicks = (id: string, dates: DateRange) => {

    const request = [
        {
            "$match": {
                "statement.verb.id": {
                    "$in": [
                        "https://webar.nextechar.com/xAPI/verbs/clicked"
                    ]
                },
                "statement.object.id": {
                    "$in": [
                        xApiStatementKeys.adIdBase + id
                    ]
                },
                "statement.timestamp": {
                    "$gte": VeracityDate(new Date(dates.start)),
                    "$lt": VeracityDate(new Date(dates.end))
                },
            }
        },
        {
            "$addFields": {
                "EXT": "$statement.object.definition"
            }
        },
        {
            "$addFields": {
                "EXT": "$EXT.extensions"
            }
        },
        {
            "$match": {
                "EXT.https://arecomm*`*nextechar*`*com/extension/adlocation": {
                    "$in": [
                        "In-AR"
                    ]
                }
            }
        },
        {
            "$group": {
                "_id": {
                    "$dateToString": {
                        "format": "%Y-%m-%dT00:00:00.000Z",
                        "date": "$statement.timestamp"
                    },
                },
                "count": {
                    "$sum": 1
                },
            }
        },
        {
            "$addFields": {
                "isoTime": {
                    "$toDate": "$_id"
                }
            }
        },
        {
            "$sort": {
                "isoTime": 1
            }
        },
        {
            "$bucketAuto": {
                "groupBy": "$_id",
                "buckets": 30,
                "output": {
                    "count": {
                        "$sum": "$count"
                    },
                }
            }
        },
        {
            "$addFields": {
                "_id": "$_id.min"
            }
        },
        {
            "$project": {
                "_id": 0,
                "instance": "$_id",
                "count": "$count",
            }
        }
    ]

    return request
}

export const getTimelineDataArCTR = (id: string, dates: DateRange) => {

    const request = [
        {
            "$match": {
                "statement.verb.id": {
                    "$in": [
                        "http://activitystrea.ms/schema/1.0/open",
                        "https://webar.nextechar.com/xAPI/verbs/clicked"
                    ]
                },
                "statement.object.id": {
                    "$in": [
                        xApiStatementKeys.adIdBase + id
                    ]
                },
                "statement.timestamp": {
                    "$gte": VeracityDate(new Date(dates.start)),
                    "$lt": VeracityDate(new Date(dates.end))
                }
            }
        },
        {
            "$addFields": {
                "EXT": "$statement.object.definition"
            }
        },
        {
            "$addFields": {
                "EXT": "$EXT.extensions"
            }
        },
        {
            "$group": {
                "_id": {
                    "date": {
                        "$dateToString": {
                            "format": "%Y-%m-%dT00:00:00.000Z",
                            "date": "$statement.timestamp"
                        }
                    },
                    "verb": "$statement.verb.id",
                    "ext": "$EXT.https://arecomm*`*nextechar*`*com/extension/adlocation"
                },
                "count": {
                    "$sum": 1
                },
            }
        },
        {
            "$group": {
                "_id": "$_id.date",
                "sum": {
                    "$sum": "$count"
                },
                "counts": {
                    "$push": {
                        "verb": "$_id.verb",
                        "ext": "$_id.ext",
                        "count": "$count"
                    }
                }
            }
        },
        {
            "$unwind": {
                "path": "$counts",
            }
        },
        {
            "$project": {
                "_id": "$_id",
                "counts": "$counts.count",
                "verb": "$counts.verb",
                "ext": "$counts.ext",
                "sum": "$sum",
                "percent": {
                    "$multiply": [
                        {
                            "$cond": [
                                {
                                    "$eq": [
                                        {
                                            "$subtract": [
                                                "$sum",
                                                "$counts.count"
                                            ]
                                        },
                                        0
                                    ]
                                },
                                0,
                                {
                                    "$divide": [
                                        "$counts.count",
                                        {
                                            "$subtract": [
                                                "$sum",
                                                "$counts.count"
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        100
                    ]
                }
            }
        },
        {
            "$match": {
                "verb": {
                    "$in": [
                        "https://webar.nextechar.com/xAPI/verbs/clicked"
                    ]
                },
                "ext": {
                    "$in": [
                        "In-AR"
                    ]
                }
            }
        },
        {
            "$project": {
                "_id": 0,
                "instance": "$_id",
                "count": {
                    "$round": [
                        "$percent",
                        2
                    ],
                }
            }
        },
        {
            "$sort": {
                "instance": 1
            }
        }
    ]

    return request
}
