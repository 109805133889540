import { PageProps, AppState } from "@declarations"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Actions, makeAction } from "Store/Actions/ActionTypes"
import { store } from "Store/store"
import { Links } from "Values/Constants"
import { Header, ReviewHeader } from "./ad-form-header.styles"
import swirlLogo from 'Assets/images/logos/SwirlLogo.png'
import swirlIcon from 'Assets/images/logos/SwirlIcon.png'
import XWhiteIcon from 'Assets/images/icons/x-white.svg'

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    currentStep: number
    totalSteps: number
    title: string
    updateTitle?: string
    subtitle?: string
    nextTitle?: string
    stepOneCheck?: number
    stepTwoCheck?: number
    stepThreeCheck?: number
    stepFourCheck?: number
    stepFiveCheck?: number
    reviewPage?: boolean
    updating: boolean
    id?: string
}

const AdFormHeader = (props: Props) => {

    const { currentStep, totalSteps, title, updateTitle, subtitle, nextTitle, stepOneCheck, stepTwoCheck, stepThreeCheck, stepFourCheck, stepFiveCheck, reviewPage, clearAdForm, updating, id } = props

    const history = useHistory()

    const exitForm = async () => {
        await clearAdForm()
        if (updating && id) {
            history.push(Links.ad.constructor(id))
        } else {
            history.push(Links.home)
        }
    }

    const goHome = async () => {
        await clearAdForm()
        history.push(Links.home)
    }

    if (reviewPage) {
        return (
            <ReviewHeader>
                <button onClick={goHome}>
                    <img src={swirlLogo} className="img1" style={{width: "222px", height: "62px"}} alt="logo" />
                    <img src={swirlIcon} className="img2" style={{width: "82px", height: "62px"}} alt="logo" />
                </button>
                <p className="reviewText">{title}</p>
                <button onClick={exitForm}>
                    <img src={XWhiteIcon} alt="exit" />
                </button>
            </ReviewHeader>
        )
    } else if (updating) {
        return (
            <ReviewHeader>
                <button onClick={goHome}>
                    <img src={swirlLogo} className="img1" style={{width: "222px", height: "62px"}} alt="logo" />
                    <img src={swirlIcon} className="img2" style={{width: "82px", height: "62px"}} alt="logo" />
                </button>
                <p className="reviewText">{updateTitle}</p>
                <button onClick={exitForm}>
                    <img src={XWhiteIcon} alt="exit" />
                </button>
            </ReviewHeader>
        )
    }

    return (
        <Header>
            <div className="middleColumn"> 
                <div className="rowOne">
                    <div className="stepTitle">
                        <p className="title">{currentStep <= totalSteps && !updating
                            ? <span>Step {currentStep}: &nbsp;</span>
                            : null}
                            {title}
                        </p>
                        <p className="subtitle">{subtitle}</p>
                    </div>
                    <div className="upNextBox">
                        <p className="upNext">Up Next:</p>
                        <p className="nextTitle">{nextTitle}</p>
                    </div>
                </div>
                <div className="rowTwo">
                    <div id="1" className={stepOneCheck == 1 ? "circleSelected" : stepOneCheck == 2 ? "circleChecked" : "circle"}>1</div>
                    <p className={stepOneCheck == 1 ? "stepNameSelected" : "stepName"}>Details</p>
                    <div className="divider"></div>
                    <div className={stepTwoCheck == 1 ? "circleSelected" : stepTwoCheck == 2 ? "circleChecked" : "circle"}>2</div>
                    <p className={stepTwoCheck == 1 ? "stepNameSelected" : "stepName"}>Banner Size</p>
                    <div className="divider"></div>
                    <div className={stepThreeCheck == 1 ? "circleSelected" : stepThreeCheck == 2 ? "circleChecked" : "circle"}>3</div>
                    <p className={stepThreeCheck == 1 ? "stepNameSelected" : "stepName"}>Add Background</p>
                    <div className="divider"></div>
                    <div className={stepFourCheck == 1 ? "circleSelected" : stepFourCheck == 2 ? "circleChecked" : "circle"}>4</div>
                    <p className={stepFourCheck == 1 ? "stepNameSelected" : "stepName"}>Add 3D Model</p>
                    <div className="divider"></div>
                    <div className={stepFiveCheck == 1 ? "circleSelected" : stepFiveCheck == 2 ? "circleChecked" : "circle"}>5</div>
                    <p className={stepFiveCheck == 1 ? "stepNameSelected" : "stepName"}>Model Adjustments</p>
                </div>
            </div>
            <button className="rightColumn" onClick={exitForm}>
                <img src={XWhiteIcon} alt="exit" />
            </button>
        </Header>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        adForm: state.adForm
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        clearAdForm: async () => dispatch(makeAction(Actions.CLEAR_AD_FORM)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdFormHeader)
