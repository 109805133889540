import { Theme } from '@declarations';
import "@fontsource/poppins";

export const DefaultTheme: Theme = {
    colors: {
        blue: '#D3E1F6',
        text: '#FFFFFF',
        headerText: '#1A2E63',
        grey: '#F8F8F8',
        button: '#0C5178',
        buttonHover: '#107198',
        line: '#D5DDE9',
        lineInactive: '#686868',
        lineInactive2: '#959595',

        pageHeaderBackground: '#030E31',
        pageHeaderColor: "#ffffff",

        buttonDisabled: 'opacity: 0.7',
        buttonPrimaryColor: '#ffffff',
        buttonPrimaryBackground: '#353E5A',
        buttonPrimaryBorder: '1px solid #353E5A',
        buttonPrimaryHover: 'filter: brightness(0.9)',

        buttonSecondaryBackground: '#ffffff',
        buttonSecondaryColor: '#353E5A',
        buttonSecondaryBorder: '1px solid #353E5A',
        buttonSecondaryHover: 'filter: brightness(0.9)',

        buttonAlternateBackground: '#0038A8',
        buttonAlternateColor: '#ffffff',
        buttonAlternateBorder: '1px solid #0038A8',
        buttonAlternateHover: 'filter: brightness(0.9)',

        buttonTertiaryBackground: '',
        buttonTertiaryColor: '',
        buttonTertiaryBorder: '',
        buttonTertiaryHover: '',

        buttonDangerBackground: '#ffffff',
        buttonDangerColor: '#BD0101',
        buttonDangerBorder: '1px solid #BD0101',
        buttonDangerHover: 'filter: brightness(0.9)',

        textPrimary: '#000000',
        textSecondary: '',
        textError: '#FF380D',
        linkPrimary: '#0038A8',

        inputTextBase: '#000000',
        inputTextError: '#FF380D',

        inputLabelInactive: '#555555',
        inputLabelActive: '#292929',
        inputLabelFocus: '#353E5A',

        inputBackgroundInactive: '#F8F8F8',
        inputBackgroundActive: '#FFFFFF',

        inputBorderBase: '1px solid #D6D6D6',
        inputBorderFocus: '1px solid #353E5A',
        inputBorderError: '2px solid #FF380D',

        dividerPrimary: '1px solid #D6D6D6',
        underline: '#314CD4',

        backgroundPrimary: '#ffffff',
        pageBackground: '#E5E5E520',
        modalBackground: '#ffffff',

        creditCardTheme: 'dark',

        progressBarIncomplete: "#DDDEE0",
        progressBarComplete: "#FF38F8",// "#353E5A",

        bannerTextColor: "#ffffff",
        bannerBackground: "linear-gradient(90deg, #3A31A5 0%, #68239D 31.77%, #900A9A 65.23%, #9A1587 100%)",
    },
    effects: {
        shadowPrimary: '0px 4px 16px rgba(0, 0, 0, 0.15)',
        textShadow: 'none',
        backgroundBlur: 'none',
        linkDecoration: 'none',
        iconFilter: 'none',
    },
    fonts: {
        fontFamily: 'Poppins',
        primary: 'italic small-caps normal 32px/38px Poppins, serif',
        h1: '600 2rem/2.375rem Poppins, sans-serif',
        h2Regular: '400 1.5rem/1.75rem Poppins, sans-serif',
        h2Bold: '600 1.5rem/1.75rem Poppins, sans-serif',
        h3Light: '300 1.25rem/1.4375rem Poppins, sans-serif',
        h3Regular: '400 1.25rem/1.4375rem Poppins, sans-serif',
        h3Bold: '600 1.25rem/1.4375rem Poppins, sans-serif',
        h4Regular: '300 1.125rem/1.3125rem Poppins, sans-serif',
        h4Medium: '500 1.125rem/1.3125rem Poppins, sans-serif',
        h4Bold: '600 1.125rem/1.3125rem Poppins, sans-serif',
        body1Regular: '300 1rem/1.3125rem Poppins, sans-serif',
        body1Medium: '500 1rem/1.3125rem Poppins, sans-serif',
        body1Bold: '600 1rem/1.3125rem Poppins, sans-serif',
        body2Regular: '300 0.75rem/0.875rem Poppins, sans-serif',
        body2Medium: '500 0.75rem/0.875rem Poppins, sans-serif',
        body2Bold: '600 0.75rem/0.875rem Poppins, sans-serif',
        link: '300 1rem Poppins, sans-serif',
        bannerH1: "500 32px Poppins, sans-serif",
        bannerP: "normal 20px Poppins, sans-serif"
    },
    mediaQueries: {
        below1000: `only screen and (max-width: 1000px)`,
        below420: `only screen and (max-width: 420px)`
    },
    shapes: {
        borderRadiusPrimary: '6px',
        buttonPadding: '8px 24px'
    }
};
