import AdPreviewOverview from "Components/AdPreviewOverview/ad-preview-overview.component"
import PageLayout from "Components/PageLayout/page-layout.component"
import Tabs from "Components/Tabs/tabs.component"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Banner, BannerText } from "Styles/library/banner"
import { Button } from "Styles/library/button"
import { SectionTitle } from "Styles/library/titles"
import { URLs } from "Values/Constants"
import { AdSchema } from "@declarations"
import { searchAds } from "Api/Api/ads"
import { formatDate, formatTimestamp } from "Utils/dates"
import ShareAdModal from "Components/Modals/ShareAdModal/share-ad-modal.component"
import { generateEmbedLink } from "Utils/embedLink"

const AdPreviewPage = () => {

    let { id } = useParams<{ id: string }>();


    // const { getAccessTokenSilently } = useAuth0();

    const [ad, setAd] = useState<AdSchema>()

    useEffect(() => {

        const getAds = async () => {
            // GET AUTH0 TOKEN
            // const accessToken = await getAccessTokenSilently({
            //     audience: URLs.threedyAuth0Audience,
            //     scope: "read:current_user",
            // })

            const res = await searchAds(id)
            const ad = res.data.data[0] as AdSchema
            console.log("AD RES ", ad)
            if (ad) setAd(ad)
        }

        getAds()

    }, [id])


    const [tab, setTab] = useState<string>("Overview")

    const options: string[] = [
        "Overview",
        // "Ad Details",
        // "Ad Analytics"
    ]

    const handleTabSelection = (option: string) => {
        setTab(option)
    }

    const renderTabs = (tab: string) => {
        switch (tab) {
            case "Overview":
                return <AdPreviewOverview ad={ad} />
            // case "Ad Details":
            //     return <AdDetails ad={ad} />
            // case "Ad Analytics":
            //     return <Analytics ad={ad} />
            default:
                return <></>
        }
    }

    const [showShareModal, setShareModal] = useState<boolean>(false)

    const toggleShareModal = () => {
        setShareModal(!showShareModal)
    }

    const [showSharePreviewModal, setSharePreviewModal] = useState<boolean>(false)

    /*const toggleSharePreviewModal = () => {
        setSharePreviewModal(!showSharePreviewModal)
    }*/

    const [autoActivate, setAutoActivate] = useState<boolean>(false)

    const toggleAutoActivate = () => {
        setAutoActivate(!autoActivate)
    }

    return (
        <PageLayout preview={true}>
            <Banner>
                <BannerText>
                    {/* <Link to={Links.myAds}>
                        <BannerBackWrapper>
                            <img src={BackArrow} alt="back" />
                            <h6 >Back to All Ads</h6>
                        </BannerBackWrapper>
                    </Link> */}

                    <h1>{ad?.adName}</h1>
                    <p>Last modified: {ad?.updatedDate ? <>{formatTimestamp(new Date(ad.updatedDate))} {formatDate(new Date(ad.updatedDate))}</> : null}</p>
                </BannerText>
                <div style={{ display: 'flex' }}>
                    <Button variant="secondary" onClick={toggleShareModal} style={{ marginRight: '1rem' }}>
                        Embed Banner
                    </Button>
                    {/* <Button variant="secondary" onClick={toggleSharePreviewModal}>
                        Share Banner Preview
                    </Button> */}
                </div>
            </Banner>
            <br />
            <SectionTitle>
                <h1>Banner details</h1>
            </SectionTitle>
            <br />
            <Tabs
                options={options}
                select={handleTabSelection}
                selected={tab}
            />
            {renderTabs(tab)}
            {showShareModal ?
                <ShareAdModal
                    handleClose={() => setShareModal(false)}
                    callback={() => {
                        if (ad) {
                            const link = generateEmbedLink(ad)
                            navigator.clipboard.writeText(link)
                        }
                    }}
                    toggle={autoActivate}
                    sendToggle={toggleAutoActivate}
                    h2="Embed Banner"
                    h3="Get Embed Link"
                    p={ad ? generateEmbedLink(ad) : ''}
                /> : <></>}
            {showSharePreviewModal ?
                <ShareAdModal
                    handleClose={() => setSharePreviewModal(false)}
                    callback={() => {
                        if (ad) {
                            const link = `${URLs.url}/banner-preview/${ad._id}`
                            navigator.clipboard.writeText(link)
                        }
                    }}
                    h2="Share Banner Preview"
                    h3="Share Preview Link"
                    p={`${URLs.url}/banner-preview/${ad?._id}`}
                /> : <></>}
        </PageLayout >
    )
}

export default AdPreviewPage