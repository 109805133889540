import styled from "styled-components";

type FieldProps = {
    maxWidth?: string
}

export const FieldWrapper = styled.div<FieldProps>`
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth || "450px"};
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(186px, auto));
    grid-gap: 24px;
    position: relative;
`

// export const FieldWrapperWithTooltip = styled.div<FieldProps>`
//     width: 100%;
//     max-width: ${({ maxWidth }) => maxWidth || "450px"};
// `