import styled, { css } from 'styled-components';

type ButtonProps = {
    variant?: "primary" | "secondary" | "alternate" | "tertiary" | "danger" | "danger-fill" | "back"
    icon?: "left" | "right"
}

export const Button = styled.button<ButtonProps>`
    box-shadow: ${({ theme }) => theme.effects.shadowPrimary};
    border-radius: ${({ theme }) => theme.shapes.borderRadiusPrimary};
    color: ${({ theme }) => theme.colors.textPrimary};
    padding: ${({ theme }) => theme.shapes.buttonPadding};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover:enabled {
        cursor: pointer;
    }

    &:active:enabled {
        box-shadow: none;
        transform: translateY(1px);
    }

    &:disabled {
        ${({ theme }) => theme.colors.buttonDisabled};
        pointer-events: none;
    }

    img {
        width: 1rem;
        height: auto;
        ${({ icon }) => icon === "left" ? "margin-right: 1rem" : "margin-left: ƒ1rem"};
    }



    ${({ variant }) => {
        switch (variant) {
            case "primary":
                return css`
                    background: ${({ theme }) => theme.colors.buttonPrimaryBackground};
                    color: ${({ theme }) => theme.colors.buttonPrimaryColor};
                    border: ${({ theme }) => theme.colors.buttonPrimaryBorder};
                
                    &:hover:enabled {
                        ${({ theme }) => theme.colors.buttonPrimaryHover};
                    }
            `
            case "secondary":
                return css`
                background: ${({ theme }) => theme.colors.buttonSecondaryBackground};
                color: ${({ theme }) => theme.colors.buttonSecondaryColor};
                border: ${({ theme }) => theme.colors.buttonSecondaryBorder};
            
                &:hover:enabled {
                    ${({ theme }) => theme.colors.buttonSecondaryHover};
                }
            `
            case "alternate":
                return css`
                background: ${({ theme }) => theme.colors.buttonAlternateBackground};
                color: ${({ theme }) => theme.colors.buttonAlternateColor};
                border: ${({ theme }) => theme.colors.buttonAlternateBorder};
            
                &:hover:enabled {
                    ${({ theme }) => theme.colors.buttonAlternateHover};
                }
            `
            case "tertiary":
                return css`
                    background: ${({ theme }) => theme.colors.buttonTertiaryBackground};
                    color: ${({ theme }) => theme.colors.buttonTertiaryColor};
                    border: ${({ theme }) => theme.colors.buttonTertiaryBorder};
                    backdrop-filter: ${({ theme }) => theme.colors.buttonTertiaryBlur};
                
                    &:hover:enabled {
                        ${({ theme }) => theme.colors.buttonTertiaryHover};
                    }
                `
            case "danger":
                return css`
                        background: ${({ theme }) => theme.colors.buttonDangerBackground};
                        color: ${({ theme }) => theme.colors.buttonDangerColor};
                        border: ${({ theme }) => theme.colors.buttonDangerBorder};
                        backdrop-filter: ${({ theme }) => theme.colors.buttonDangerBlur};
                    
                        &:hover:enabled {
                            ${({ theme }) => theme.colors.buttonPrimaryHover};
                        }
                    `
            case "danger-fill":
                return css`
                background: ${({ theme }) => theme.colors.buttonDangerColor};
                color: ${({ theme }) => "white"};
                border: ${({ theme }) => theme.colors.buttonDangerBorder};
                backdrop-filter: ${({ theme }) => theme.colors.buttonDangerBlur};
            
                &:hover:enabled {
                    ${({ theme }) => theme.colors.buttonTertiaryHover};
                }
            `
            case "back":
                return css`
                background: none;
                box-shadow: none;
                border: none;
                margin: 0;
                padding: 0;
                cursor: pointer;
                color: #0038A8;
                border: ;none;

                &:hover:enabled {
                    filter: brightness(0.9);
                }
            `
            default:
                return ``
        }

    }};
`;

type CustomProps = {
    background?: string;
    border?: string;
    color?: string;
}
export const CustomButton = styled(Button) <CustomProps>`
    background: ${({ background }) => background};
    border: ${({ border }) => border};
    color: ${({ color }) => color};
`;