import { MomentRange } from "@declarations";
import moment from "moment";
import { getDefaultDates } from "Store/Reducers/analytics-dates";
import { store } from "Store/store";
import { Actions, makeAction } from "./ActionTypes";


export const updateDates = (dateRange: MomentRange) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

        const currentState = getState().analyticsDates

        const start = dateRange.startDate?.valueOf() || getDefaultDates().start
        let end = dateRange.endDate ? moment(dateRange.endDate).endOf('day').valueOf() : getDefaultDates().end

        const dates: typeof currentState = {
            ...currentState,
            dates: {
                start: start,
                end: end
            }
        }

        dispatch(makeAction(Actions.SET_ANALYTICS_DATES, dates))
    }