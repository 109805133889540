import styled from "styled-components";

export const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #353E5A;
    // padding: 32px;
    margin-bottom: 32px;

    h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        margin: 0;
        margin-right: 32px;
    }
`

export const FoldersSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    overflow-y: auto;
    max-height: 250px;
`;

export const FolderRow = styled.button`
    /* Background Colours/White */

    background: #FFFFFF;
    /* Neutral Colours/Lighter */

    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    /* Tiny */

    box-shadow: 0px 3px 12px rgba(185, 185, 185, 0.2);
    border-radius: 8px;

    width: 100%;
    margin: 8px 0;
    padding: 16px 26px;

    text-align: left;
`