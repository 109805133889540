import { TileBox } from "./dimension-tile.styles"

type Props = {
    img: string
    title: string
    width: number,
    height: number,
    handleSelectDimensions: (width: number, height: number) => void
    selectedDimensions: { width: number, height: number }
}

const DimensionTile = (props: Props) => {

    const { img, title, width, height, handleSelectDimensions, selectedDimensions } = props

    return (
        <TileBox
            selected={selectedDimensions.width === width && selectedDimensions.height === height}
            onClick={() => handleSelectDimensions(width, height)}
        >
            <img src={img} alt="layout" />
            <h5>{title}</h5>
            <p>{`${width} x ${height}`}</p>
        </TileBox>
    )
}

export default DimensionTile