import React, { ReactElement, useEffect, useState, useReducer } from 'react';
import {
    Container,
    DragOverlay,
    FileInput,
    FileInputDivider,
    FileLabel,
} from "./file-input.styles"
import { ErrorTag } from "../shared.styles";
import {
    Form
} from '@declarations';
import DropImageIcon from 'Assets/images/forms/drop-image.svg'
import { capitalizeFirstLetter } from 'Utils/strings';
import ImageViewer from 'Components/ImageViewer/image-viewer.component';

type Props = {
    id: string
    name: Form.Field
    placeholder: string;
    file: File | null
    fileUrl: string
    acceptedFileTypes: string[]
    sendFile: (file: File | null) => void
    width: number
    height: number
    validations?: Form.Validation[];
    onValidChange?: (valid: boolean) => void;
    containerStyle?: Record<string, unknown>;
    disabled?: boolean;
};

const ValidatedFileInput = (props: Props): ReactElement => {

    const { id, name, file, fileUrl, acceptedFileTypes, width, height, validations, onValidChange, placeholder, containerStyle, disabled, sendFile } = props;

    const [dirty, setDirty] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [valid, setValid] = useState<boolean>(false);
    const errorMsg = `${name ? `${capitalizeFirstLetter(name)} file` : "File"} is required.`

    const handleDirty = () => {
        setDirty(true)
    }

    const handleInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDirty(true)
        if (e.target.files?.length) {
            sendFile(e.target.files[0])
            setValid(true)
            setError("")
        }
    }

    useEffect(() => {
        if (file) {
            setValid(true)
            setError("")
        }
        else {
            setValid(false)
            if (dirty) setError(errorMsg)
        }
    }, [file, dirty, errorMsg])


    useEffect(() => {
        if (validations && validations.length && onValidChange) {
            onValidChange(valid);
        }
    }, [valid])

    // drag
    const reducer = (state: any, action: any) => {
        switch (action.type) {
            case 'SET_DROP_DEPTH':
                return { ...state, dropDepth: action.dropDepth }
            case 'SET_IN_DROP_ZONE':
                return { ...state, inDropZone: action.inDropZone };
            case 'ADD_FILE_TO_LIST':
                return { ...state, fileList: state.fileList.concat(action.files) };
            default:
                return state;
        }
    }

    const [dragData, dispatch] = useReducer(
        reducer, { dropDepth: 0, inDropZone: false }
    )

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({ type: 'SET_DROP_DEPTH', dropDepth: dragData.dropDepth + 1 });
    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({ type: 'SET_DROP_DEPTH', dropDepth: dragData.dropDepth - 1 });
        if (dragData.dropDepth - 1 <= 0) {
            dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
        }
    }

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        e.dataTransfer.dropEffect = 'copy';
        dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        let files = e.dataTransfer.files

        if (files && files.length > 0) {
            let file = files[0]

            const type = file.type
            console.log('TYPE ', type)
            if (acceptedFileTypes.includes(type)) {
                sendFile(file)
                setValid(true)
                setError("")
            } else {
                setTimeout(() => setError(`File must be of the following types: ${acceptedFileTypes.join(", ")}`), 0);
            }
            setDirty(true)
            e.dataTransfer.clearData();
            dispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 });
            dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
        }
    };

    const clearFile = () => {
        sendFile(null)
        URL.revokeObjectURL(fileUrl)
    }

    return (
        <Container
            style={{
                ...containerStyle,
                overflow: 'auto',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            width={width}
            height={height}
        >

            {fileUrl !== ""
                ? <ImageViewer
                    fileUrl={fileUrl}
                    width={width}
                    height={height}
                    clearFile={clearFile}
                    withInput={true}
                />
                : <>
                    <FileInput
                        id={id}
                        name={name}
                        onChange={handleInputFile}
                        onBlur={handleDirty}
                        disabled={disabled}
                        type="file"
                        accept={acceptedFileTypes.join(", ")}
                    />
                    <img src={DropImageIcon} alt="drop-backgroud" style={{
                        maxHeight: width > height ? '50%' : 'auto',
                        maxWidth: width < height ? '50%' : 'auto'
                    }} />
                    <FileInputDivider>
                        <hr />
                        <p>or</p>
                        <hr />
                    </FileInputDivider>
                    <FileLabel
                        value={""}
                        focus={false}
                        htmlFor={id}
                    >
                        {placeholder}
                    </FileLabel>
                </>
            }
            {dragData.inDropZone &&
                <DragOverlay />
            }
            {/* display error */}
            {validations && onValidChange && dirty && error ? <ErrorTag style={{ alignSelf: 'center', marginTop: '1rem', marginBottom: '-1rem' }}>{error}</ErrorTag> : null}

        </Container>
    )
}

export default ValidatedFileInput;