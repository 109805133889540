import styled from "styled-components";

export const SummaryRow = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(225px, auto));
    grid-template-rows: 1fr;
    grid-gap: 24px;
    align-items: center;
    min-width: 100%;
`
export const GraphBox = styled.div`
    background: #FFFFFF;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    header {
        padding: 24px 32px;
        border-bottom: 1px solid #DDDEE0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #030E31;
        margin: 0;
    }

    & > *:not(:first-child) {
        padding: 32px;
    }
`