import { Button } from "Styles/library/button";
import '../../Components/SupportForm/supportForm.components.css';

declare const zE: any;

const SupportForm = () => {

    const openHelpWidget = () => {
        zE.activate({hideOnClose: true});    
    }

    return (
        <div className="content">
                <div>
                    <div className="dC2Title">Don’t see the topic you need?</div>
                    <div className="dC2Text">
                        Click the button below to contact us
                    </div>
                </div>  
                <Button
                    style={{ color:"#0038A8" }}
                    className="dC2Button"
                    onClick={openHelpWidget}
                >
                    Contact Us
                </Button>
        </div>
    )
}

export default SupportForm