import { createGlobalStyle } from 'styled-components'
import { Theme } from '@declarations';
import Typography from 'Styles/typography';
import GenericCSS from './genericCSS';

const GlobalStyles = createGlobalStyle<{ theme: Theme }>`

  html {
    color: ${({ theme }) => theme.colors.textPrimary};
    font-family: ${({ theme }) => `${theme.fonts.fontFamily}, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`};
    height: 100%;
  }

  body {
    background: ${({ theme }) => theme.colors.pageBackground};
  }

  h1, h2, h3, h4 {
    text-shadow: ${({ theme }) => theme.effects.textShadow};
  }

  button {
    color: ${({ theme }) => theme.colors.textPrimary};
    background: transparent;
    border: none;
  }

  a {
    color: ${({ theme }) => theme.colors.linkPrimary};
    text-decoration: ${({ theme }) => theme.effects.linkDecoration};
  }

  ${Typography}
  ${GenericCSS}
`;

export default GlobalStyles;
