import styled from "styled-components";

export const Footer = styled.footer`
    grid-area: footer;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
`

export const Actions = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 108px;

    .normalButton {
        display: block;
    }
    .smallerButton {
        display: none;
    }
    @media screen and (max-width: 650px) {
        .normalButton {
            display: none;
        }
        .smallerButton {
            display: block;
        }
    }
`

export const ProgressPercentage = styled.div`
    background: ${({ theme }) => theme.colors.progressBarComplete};
    color: ${({ theme }) => theme.colors.buttonPrimaryColor};
    box-shadow: ${({ theme }) => theme.effects.shadowPrimary};
    border-radius: ${({ theme }) => theme.shapes.borderRadiusPrimary};
    padding: ${({ theme }) => theme.shapes.buttonPadding};
    display: flex;
    align-items: center;
    justify-content: center;
`

