import { ReviewRow } from "Components/AdForm/Shared/review-form.styles"
import ImageViewer from "Components/ImageViewer/image-viewer.component"
import { PreviewContainer } from "Components/ImageViewer/image-viewer.styles"
import { ContentBody } from "Components/PageLayout/page-layout.styles"
import { Container, Details, Preview } from "./ad-preview-overview.styles"
import { AdSchema } from "@declarations"

type Props = {
    ad?: AdSchema
}

const AdOverview = (props: Props) => {

    const { ad } = props

    //const { getAccessTokenSilently } = useAuth0();

    //const history = useHistory()

    //const [showArchiveModal, setArchiveModal] = useState<boolean>(false)

    // const archiveAd = async () => {

    //     try {
    //         if (!ad?._id) throw Error("no ad")
    //         // GET AUTH0 TOKEN
    //         const accessToken = await getAccessTokenSilently({
    //             audience: URLs.threedyAuth0Audience,
    //             scope: "read:current_user",
    //         })

    //         const update: Partial<Record<keyof AdSchema, any>> = {
    //             deleted: true
    //         }

    //         const res = await updateAd(ad._id, update, accessToken)
    //         console.log("RES from archiving ad", res)

    //         history.push(Links.myAds)
    //     }
    //     catch (e) {
    //         console.error("ERROR archiving ad", e)
    //     }
    // }

    return (
        <ContentBody>
            <Container>
                <Details>
                    <header>
                        <h3>Basic Details</h3>
                    </header>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '0 -12px 0 -12px' }}>
                        <ReviewRow>
                            <h3>Banner Title</h3>
                            <h4>{ad?.adName}</h4>
                            <div></div>
                        </ReviewRow>
                        <ReviewRow>
                            <h3>Click URL</h3>
                            <h4>{ad?.activeURL}</h4>
                            <div></div>
                        </ReviewRow>
                        <ReviewRow>
                            <h3>Banner Size</h3>
                            <h4>{ad?.adWidth && ad?.adHeight ? `${ad.adWidth}x${ad.adHeight}` : ""}</h4>
                            <div></div>
                        </ReviewRow>
                    </div>
                </Details>
                {/* <Settings>
                    <header>
                        <h3>Banner Settings</h3>
                    </header>
                    <SideFormRow>
                        <h4>Do you want to remove this banner?</h4>
                        <Button variant="danger" onClick={() => setArchiveModal(true)}>Archive Banner</Button>
                    </SideFormRow>
                </Settings> */}
                <Preview>
                    <header>
                        <h3>Banner Preview</h3>
                    </header>
                    <PreviewContainer contained={true}>
                        <ImageViewer
                            fileUrl={ad?.creativeBg || ""}
                            transparent={ad?.transparent}
                            width={ad?.adWidth || 300}
                            height={ad?.adHeight || 300}
                            model={{
                                xCoordinate: ad?.expleft || 0,
                                yCoordinate: ad?.exptop || 0,
                                width: ad?.expwidth || 0,
                                height: ad?.expheight || 0,
                                details: { job_id: ad?._id, models: { glb: { url: ad?.glbLoc } } },
                                zoom: !ad?.camDisableZoom,
                                autoRotate: ad?.autoRotate || false,
                                autoRotateSpeed: ad?.autoRotateSpeed,
                                autoRotateDirection: ad?.autoRotateDirection as "left" | "right",
                                shadowIntensity: ad?.shadowIntensity,
                                shadowEdgeSoftness: ad?.shadowSoftness,
                                cameraControls: true,
                                cameraDistance: ad?.camInitialDist,
                                cameraVerticalAngle: ad?.camHeightAngle,
                                cameraHorizontalAngle: ad?.paramInitialAngle
                            }}
                            showViewInArButton={ad?.arEnabled ? true : false}
                            viewInArButton={{
                                xCoordinate: ad?.arZoneLeft || 0,
                                yCoordinate: ad?.arZoneTop || 0,
                                type: "image"
                            }}
                        />
                        {/* <ExpandButton onClick={makeImageFullScreen}>
                            <div><img src={ExpandIcon} alt="expand" /></div>
                        </ExpandButton> */}
                    </PreviewContainer>
                </Preview>
            </Container>
            {/* {showArchiveModal &&
                <Modal
                    title="You’re about to delete this 3D banner.
                    Are you sure?"
                    text={`This 3D banner will be deleted. Any embeds will no longer be active, and all shared access will be lost.`}
                    callback={() => {
                        // archiveAd()
                        setArchiveModal(false)
                    }}
                    callbackText={"Yes, delete this banner"}
                    handleClose={() => setArchiveModal(false)}
                    buttonVariant="danger"
                    showGoBack={true}
                />
            } */}
        </ContentBody>
    )
}

export default AdOverview