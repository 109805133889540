import styled from "styled-components";
import "@fontsource/poppins";

type ButtonProps = {
    variant?: "primary" | "secondary" | "alternate" | "tertiary" | "danger" | "danger-fill"
    icon?: "left" | "right"
}

export const Body = styled.div`
    grid-area: body;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    padding: 4rem;
    padding-top: 5rem;

    h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
    }

    .topPageLink {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        color: #0038A8;
    }
`

export const DivContainer = styled.div`
    overflow: auto;
    
`

export const DivBox = styled.div`
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    .backgroundTitle {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        margin-top: 5px;
        text-align: center;
        color: #353E5A;
    }

    .bannerPreviewTitle {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #353E5A;
    }
`

export const ResetButton = styled.button<ButtonProps>`
    box-shadow: ${({ theme }) => theme.effects.shadowPrimary};
    border-radius: ${({ theme }) => theme.shapes.borderRadiusPrimary};
    padding: ${({ theme }) => theme.shapes.buttonPadding};

    &:active:enabled {
        box-shadow: none;
        transform: translateY(1px);
    }
    background: ${({ theme }) => theme.colors.buttonDangerBackground};
    color: ${({ theme }) => theme.colors.buttonDangerColor};
    border: ${({ theme }) => theme.colors.buttonDangerBorder};
    backdrop-filter: ${({ theme }) => theme.colors.buttonDangerBlur};

    &:hover:enabled {
        ${({ theme }) => theme.colors.buttonPrimaryHover};
    }

    margin: 25px auto 0;
    display: block;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
`