import { ReactElement, useState } from "react";
import { FAQ } from "@declarations";
import { FAQBar } from "./faqs.styles";
import ChevronBlueDown from "Assets/images/icons/chevron-blue-down.svg"

type Props = {
    faq: FAQ;
}

const Faq = (props: Props): ReactElement => {

    const { q, a } = props.faq;

    const [expanded, setExpanded] = useState<boolean>(false);

    const toggleExpanded = (): void => {
        setExpanded(!expanded)
        return;
    }

    return (
        <FAQBar expanded={expanded}>
            <button className="header" onClick={toggleExpanded}>
                <h4>{q}</h4>
                <img
                    src={ChevronBlueDown}
                    alt="Toggle Expand FAQ"
                    className="chevron"
                />
            </button>
            <div className="q-container">
                <div className="q-expander">
                    <p>{a}</p>
                </div>
            </div>
        </FAQBar>

    )
}

export default Faq