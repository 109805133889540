import styled from "styled-components";

type TileBoxProps = {
    selected?: boolean
}

export const TileBox = styled.button<TileBoxProps>`
    background: #FFFFFF;
    /* Neutral Colours/Light */

    border: 1px solid #BDBEC3;
    box-sizing: border-box;
    /* Tiny */

    box-shadow: 0px 3px 12px rgba(185, 185, 185, 0.2);
    border-radius: 8px;

    padding: 24px 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 192px;
    height: 151px;
    margin: 8px;

    img {
        width: 88px;
        height: auto;
    }

    h5 {
        font-weight: 400;
        font-size: 18px;
        margin: 10px 0 2px;
    }

    p {
        font-weight: 300;
        font-size: 16px;
        margin: 0;
    }

    &:hover {
        border: 1.5px solid #030E31;
    }

    ${({ selected }) => selected
        ? `
        background: #F2F2F3;
        border: 1.5px solid #030E31;
    `
        : `
        background: #FFFFFF;
        border: 1px solid #BDBEC3;
    `

    };
`

export const PresetWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%px;
    max-width: 50rem;
`