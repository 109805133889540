import AdDetails from "Components/AdDetails/ad-details.component"
import AdOverview from "Components/AdOverview/ad-overview.component"
import Analytics from "Components/Analytics/analytics.component"
import PageLayout from "Components/PageLayout/page-layout.component"
import Tabs from "Components/Tabs/tabs.component"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Banner, BannerBackWrapper, BannerText } from "Styles/library/banner"
import { Button } from "Styles/library/button"
import { SectionTitle } from "Styles/library/titles"
import { Links, URLs } from "Values/Constants"
import BackArrow from 'Assets/images/arrows/chevron-left-white.svg'
import { useAuth0 } from "@auth0/auth0-react"
import { AdSchema, AppState, DateRange, PageProps } from "@declarations"
import { searchAds } from "Api/Api/ads"
import { formatDate, formatTimestamp } from "Utils/dates"
import ShareAdModal from "Components/Modals/ShareAdModal/share-ad-modal.component"
import { generateEmbedLink } from "Utils/embedLink"
import { store } from "Store/store"
import { getImpressionData } from "Store/Actions/AnalyticsActions"
import { connect } from "react-redux"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> { }

const AdPage = (props: Props) => {
    const { dates, impressions, getImpressionData } = props

    let { id } = useParams<{ id: string }>();


    const { getAccessTokenSilently } = useAuth0();

    const [ad, setAd] = useState<AdSchema>()

    useEffect(() => {

        const getAds = async () => {
            // GET AUTH0 TOKEN
            // const accessToken = await getAccessTokenSilently({
            //     audience: URLs.threedyAuth0Audience,
            //     scope: "read:current_user",
            // })

            const res = await searchAds(id)
            const ad = res.data.data[0] as AdSchema
            console.log("BANNER RES ", ad)
            if (ad) setAd(ad)
        }

        getAds()

    }, [getAccessTokenSilently, id])


    //set the folder to go back to after viewing an add
    const [folder, setFolder] = useState<string>("")
    const [folderArr, setFolderArr] = useState<string[]>([])

    useEffect(() => {
        //set the folder to go back to after viewing an add
        let linksArray = ad?.folderPath?.split(",")
        let link = Links.myAds
        linksArray?.forEach((item, index) => { if (item) { link = link + "/" + item } })
        if (linksArray) {
            linksArray.pop()
            setFolderArr(linksArray)
        }
        setFolder(link)

        //get impression data
        if (ad && ad._id) {
            getImpressionData(ad._id, dates)
        }
    }, [ad])

    const [tab, setTab] = useState<string>("Overview")

    const options: string[] = [
        "Overview",
        "Banner Details",
        "Banner Analytics"
    ]

    const handleTabSelection = (option: string) => {
        setTab(option)
    }

    const renderTabs = (tab: string) => {
        switch (tab) {
            case "Overview":
                return <AdOverview ad={ad} />
            case "Banner Details":
                return <AdDetails ad={ad} impressions={impressions} />
            case "Banner Analytics":
                return <Analytics ad={ad} />
            default:
                return <></>
        }
    }

    const [showShareModal, setShareModal] = useState<boolean>(false)

    const toggleShareModal = () => {
        setShareModal(!showShareModal)
    }

    const [showSharePreviewModal, setSharePreviewModal] = useState<boolean>(false)

    const toggleSharePreviewModal = () => {
        setSharePreviewModal(!showSharePreviewModal)
    }

    const [autoRotate, setAutoRotate] = useState<boolean>(false)

    const toggleAutoRotate = () => {
        setAutoRotate(!autoRotate)
    }

    return (
        <PageLayout>
            <Banner>
                <BannerText>
                    <Link to={folder}>
                        <BannerBackWrapper>
                            <img src={BackArrow} alt="back" />
                            <h6 >Back to {folderArr.length ? folderArr[folderArr.length - 1] : "All Banners"}</h6>
                        </BannerBackWrapper>
                    </Link>

                    <h1>{ad?.adName}</h1>
                    <p>Last modified: {ad?.updatedDate ? <>{formatTimestamp(new Date(ad.updatedDate))} {formatDate(new Date(ad.updatedDate))}</> : null}</p>
                </BannerText>
                <div style={{ display: 'flex' }}>
                    <Button variant="secondary" onClick={toggleShareModal} style={{ marginRight: '1rem' }}>
                        Embed Banner
                    </Button>
                    <Button variant="secondary" onClick={toggleSharePreviewModal}>
                        Share Banner Preview
                    </Button>
                </div>
            </Banner>
            <br />
            <SectionTitle>
                <h1>Banner details</h1>
            </SectionTitle>
            <br />
            <Tabs
                options={options}
                select={handleTabSelection}
                selected={tab}
            />
            {renderTabs(tab)}
            {showShareModal ?
                <ShareAdModal
                    handleClose={() => setShareModal(false)}
                    callback={() => {
                        if (ad) {
                            const link = generateEmbedLink(ad, autoRotate)
                            navigator.clipboard.writeText(link)
                        }
                    }}
                    toggle={autoRotate}
                    sendToggle={toggleAutoRotate}
                    h2="Embed Banner"
                    h3="Get Embed Link"
                    p={ad ? generateEmbedLink(ad, autoRotate) : ''}
                /> : <></>}
            {showSharePreviewModal ?
                <ShareAdModal
                    handleClose={() => setSharePreviewModal(false)}
                    callback={() => {
                        if (ad) {
                            const link = `${URLs.url}/banner-preview/${ad._id}`
                            navigator.clipboard.writeText(link)
                        }
                    }}
                    h2="Share Banner Preview"
                    h3="Share Preview Link"
                    p={`${URLs.url}/banner-preview/${ad?._id}`}
                /> : <></>}
        </PageLayout >
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        dates: state.analyticsDates.dates,
        impressions: state.analytics.impressions,
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getImpressionData: (id: string, dateRange: DateRange) => {
            dispatch(getImpressionData(id, dateRange))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdPage)