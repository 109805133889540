import { DateRange, ReduxAction } from "@declarations";
import moment from "moment";
import { Actions } from "Store/Actions/ActionTypes";
import { getXDaysAgo } from "Utils/dates";

// default all dates to the last 28 days
export const getDefaultDates = (): DateRange => {
    return {
        start: getXDaysAgo(new Date(), 28).valueOf(),
        end: moment(new Date()).endOf('day').valueOf()
    }
}

const initialState: Record<string, DateRange> = {
    dates: getDefaultDates()
}

export const analyticsDates = (state: Record<string, DateRange> = initialState, { type, payload }: ReduxAction): Record<string, DateRange> => {
    switch (type) {
        case Actions.SET_ANALYTICS_DATES:
            return payload as Record<string, DateRange>
        default:
            return state
    }
}