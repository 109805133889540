import { GraphBox } from "Components/Analytics/analytics.styles";
import styled from "styled-components";

type BackgroundProps = {
    width: number
    height: number
}

export const PreviewImage = styled.img<BackgroundProps>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
`

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
`

export const DetailsSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 704px;
    margin-right: 24px;
    @media screen and (max-width: 1440px) {
        max-width: initial;
        margin-right: 0;
    }
`

export const Details = styled(GraphBox)`
    width: 100%;
    margin-bottom: 24px;
    overflow: auto;
`

export const Settings = styled(GraphBox)`
    width: 100%;
    margin-bottom: 24px;

`

export const Preview = styled(GraphBox)`
    width: 100%;
    max-width: 496px;

    @media screen and (max-width: 1440px) {
        max-width: initial;
    }
`