import { ReactElement } from "react"
import { Footer, Actions } from "./ad-form-footer.styles"

type Props = {
    children?: ReactElement | ReactElement[]
}

const AdFormFooter = (props: Props) => {

    const { children } = props
    return (
        <Footer>
            <div style={{ borderTop: 'solid #DDDEE0' }}></div>
            <Actions>
                {children}
            </Actions>
        </Footer>
    )
}

export default AdFormFooter