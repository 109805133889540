
import { Folder } from "@declarations";
import axios from "axios";

export const getAllFolders = (token: string) => {
    const path = "folders"
    return axios.get(path, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}
export const searchFoldersByName = (name: string, creatorClientId: number | null, token: string) => {
    const url = `folders/search?name=${name}&creatorClientId=${creatorClientId}`
    return axios.get(url, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}
export const searchFolderContents = (creatorClientId: number | null, path: string | null, token: string) => {
    const url = `folders/searchFolderContents?creatorClientId=${creatorClientId}&path=${path}`
    return axios.get(url, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const searchFolders = (name: string, path: string | null, creatorClientId: number | null, token: string) => {
    const url = `folders/searchByCreator?name=${name}&path=${path}&creatorClientId=${creatorClientId}`
    return axios.get(url, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export type FolderSubmission = Omit<Folder, "createdDate" | "updatedDate">

export const createFolder = (submission: FolderSubmission, token: string) => {
    console.log('SUBMISSION ', submission)
    const path = "folders"
    return axios.post(path, submission, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const updateFolder = (name: string, path: string | null, creatorClientId: number | null, data: Record<string, any>, token: string) => {
    const url = `folders/${name}/${path}/${creatorClientId}`
    return axios.patch(url, data, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}

export const updateFolderName = (name: string, path: string | null, creatorClientId: number | null, data: Record<string, any>, token: string) => {
    const url = `folders/name/${name}/${path}/${creatorClientId}`
    return axios.patch(url, data, {
        headers: {
            "Authorization": 'Bearer ' + token
        }
    })
}


export const deleteFolder = (name: string, path: string) => {
    const url = `folders/${name}/${path}`
    return axios.delete(url)
}