import { Link } from "react-router-dom";
import styled from "styled-components";

export const Tile = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #FFFFFF;
    /* Neutral Colours/Lighter */

    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    /* Small */

    box-shadow: 0px 4px 16px rgba(40, 41, 61, 0.12);
    border-radius: 8px;

    // max-width: 392px;
`

export const TileImage = styled.img`
    border-radius: 8px 8px 0 0;
`

export const TileBanner = styled.div`
    padding: 24px;

    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #353E5A;
    }

`