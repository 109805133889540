import { FAQ } from "@declarations";

export const faqs: FAQ[] = [
    {
        q: "How do I manage my banners in this platform?",
        a: "Make sure you follow a good naming convention. This may include the date, content, or product you are featuring. It may also help to include the name of the webpage you are featuring it on. Please note that character limits for input fields, including titles, are 255. ie. Nextech Home Appliances February 20 2022."
    },
    {
        q: "How many 3D models can I add to my banner?",
        a: "Only one (1) 3D model is supported per banner."
    },
    {
        q: "How do I embed a banner on a website?",
        a: "After creating a new banner, a pop-up modal window will prompt you to copy the embed. If you've missed this step, you can go to the Manage My Banners page, and use the three vertical dots button to open the drop-down menu on your banner and click on the 'embed' button. Copy the embed code and paste it on your website."
    },
    {
        q: "What do I do with the embed?",
        a: "The embed can be copied and pasted into your DSP as is. Do not remove any other parameters."
    },
    {
        q: "How do I view my banner in AR?",
        a: "AR must be enabled on your Swirl, which can be toggled on and off under its AR Experience Settings."
    },
    {
        q: "How do I know if my device is compatible to view a banner in AR?",
        a: "Our AR is compatible with all up-to-date iOS and Android devices. In some cases, in-app browsers like Facebook or LinkedIn on iPhones will not support the Augmented Reality feature but will show your 3D model in the embedded banner on a web page."
    },
    {
        q: "How do I recover a banner if it was archived accidentally?",
        a: "You can simply move the banner to the root or a folder outside of the archive."
    },
    {
        q: "My URL does not work, how do I fix it?",
        a: "If your URL does not work, you will need to ensure it is entered in the platform correctly. Next, test the URL in the preview. If that does not work, please contact support@nextechar.com and let them know the name of the banner that is having trouble."
    },
    {
        q: "Why doesn't my 3D model show up in my banner?",
        a: "ARitize 3D Swirl is supported on the last two major versions of all evergreen desktop and mobile browsers, plus the last two versions of Safari (on MacOS and iOS)."
    },
    {
        q: "My “View in AR” button isn’t showing up in my embedded Swirl.",
        a: "The “View in AR” button will only appear when viewing a Swirl on a mobile device using a browser that allows AR. In most mobile devices, and most browsers, this will work; however, in some cases it will not. One example is when Swirls are viewed from an in-app browser like Facebook or LinkedIn on an iPhone."
    },
]