import { Footer, FooterContainer, SiteBrand, SiteLinks, SiteMap, TermsSection } from "./page-footer.styles"
import NextechLogo from "Assets/images/logos/nextech-logo.svg"
import swirlLogo from 'Assets/images/logos/SwirlLogo.png'
import { Links } from "Values/Constants"
import { Link } from "react-router-dom"

type Props = {
    preview?: boolean
}

const PageFooter = (props: Props) => {

    const { preview } = props

    return (
        <FooterContainer>

            <SiteMap>
                {preview ? <div></div> :
                    <SiteLinks>
                        <h2>Site Map</h2>
                        <Link to={Links.home}>Home</Link>
                        <Link to={Links.myAds}>My Banners</Link>
                        <Link to={Links.support}>Support</Link>
                    </SiteLinks>
                }
                <SiteBrand>
                    <img src={swirlLogo} style={{width: "287px", height: "82px"}} alt="logo" />
                </SiteBrand>
            </SiteMap>
            <Footer>
                <img src={NextechLogo} alt="logo" />
                <TermsSection>
                    <a href="https://www.nextechar.com/ar-solutions-terms" target="_blank" rel="noreferrer">Terms & Conditions</a> | 
                    <a href="https://www.nextechar.com/nextech-ar-solutions-privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>

                    {/* <span>|</span> */}
                    {/* <a href="/" target="_blank" rel="noreferrer">Privacy Policy</a> */}
                    <p>Copyright © 2022 Nextech AR Solutions Inc.</p>
                </TermsSection>
                {/* <SocialMediaSection>
                    <a href="/" target="_blank" rel="noreferrer">
                        <img src={IgIcon} alt="instagram" />
                    </a>
                    <a href="/" target="_blank" rel="noreferrer">
                        <img src={FacebookIcon} alt="instagram" />
                    </a>
                    <a href="/" target="_blank" rel="noreferrer">
                        <img src={LiIcon} alt="instagram" />
                    </a>
                    <a href="/" target="_blank" rel="noreferrer">
                        <img src={TwIcon} alt="instagram" />
                    </a>
                </SocialMediaSection> */}
            </Footer>
        </FooterContainer>
    )
}

export default PageFooter